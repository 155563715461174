import {ReactElement, useState} from "react";
import {CTA_COPIED, CTA_COPY} from "../../../Constants/Labels";
import Button from "../Button/Button";
import {getLabel} from "../../../Helpers/Label";
import {getIcon} from "../../../Helpers/Icon";
import {backgroundColor, classes} from "../../../Theme/Theme";

interface CopyInterface {
    stringToCopy: string;
    copyLabel?: string|ReactElement|{
        id: string;
        variables: any;
    };
    copiedLabel?: string|ReactElement|{
        id: string;
        variables: any;
    };
    selected?: boolean;
    boldText?: boolean;
    style?: 'gray-primary'|'blue-primary';
    textSize?: 'small'|'medium'|'large';
    corners?: 'default'|'medium-rounded'|'large-rounded'|'full-rounded';
    size?: 'full';
}

function Copy({stringToCopy, copyLabel, copiedLabel, selected, boldText, style, textSize, corners, size}: CopyInterface) {
    const [copied, setCopied] = useState(false);

    if (copiedLabel === undefined) {
        copiedLabel = CTA_COPIED;
    }

    if (copyLabel === undefined) {
        copyLabel = CTA_COPY;
    }

    const copy = () => {
        navigator['clipboard'].writeText(stringToCopy);
        setCopied(() => true);
    };

    const copyIcon = 'CopyClipboard';
    const copiedIcon = 'CopiedClipboard';

    const buttonLabel = <>
        {getIcon(copied ? copiedIcon : copyIcon, 'small')} {copied ? getLabel(copiedLabel) : getLabel(copyLabel)}
    </>;

    return <Button
        onClick={copy}
        selected={copied ? true : selected}
        boldText={boldText}
        textSize={textSize}
        corners={corners}
        label={buttonLabel}
        size={size}
        className={classes(backgroundColor("primary"))}
    />
}

export default Copy;