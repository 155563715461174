import Translator from "../../../../Utils/Translator";
import Button from "../../../Common/Button/Button";
import Input from "../../../Common/Form/Input";
import {useEffect, useState} from "react";
import {ReactComponent as CircledWarning} from "../../../../Icons/CircledWarning.svg";
import {FormHook} from "../../../../Hooks/Form/FormHook";
import Card from "../../Ticket/Card/Card";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {TicketsLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {TicketsRunningLoader} from "../../../../Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {CreateTicketUseCase} from "../../../../UseCases/Ticket/CreateTicketUseCase";
import {TicketsInProgressInQueue} from "../../Ticket/Common/Label/TicketsInProgress";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import Label from "../../../Common/Label/Label";
import {
    CTA_CONFIRM,
    CTA_LOGIN_ADVANTAGES_LINK, CTA_QUEUE, LABEL_ERROR,
    LABEL_LOCATION_QUEUE_REQUEST_DESCRIPTION, LABEL_PLURAL_PEOPLE, LABEL_SINGLE_PEOPLE, LABEL_TICKET_UNLOGGED_USERNAME
} from "../../../../Constants/Labels";
import ToRegister from "../../User/Redirection/ToRegister";
import {
    backgroundColor, boldText,
    border,
    borderColor,
    classes, cursor,
    flex,
    margin,
    padding,
    round, textAlign, textSize,
    width
} from "../../../../Theme/Theme";
import {getIcon} from "../../../../Helpers/Icon";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";
import {sendTakeTicketEvent} from "../../../../GoogleAnalytics/Events";

interface TicketWidgetInterface {
    locationId: string;
}

function TicketWidget({locationId}: TicketWidgetInterface) {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {fetch: fetchLocations, locations} = LocationsLoader();
    const {fetch: fetchTickets, tickets} = TicketsLoader();
    const {fetch: fetchTicketsRunning, ticketsRunning} = TicketsRunningLoader();
    const takeTicketUseCase = CreateTicketUseCase();
    const [peopleNumber, setPeopleNumber] = useState(1);

    const [queueRequest, setQueueRequest] = useState(false);

    const {form, handleChange, handleFocus, handleBlur} = FormHook(['name']);
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        fetchLocations([locationId]);
        fetchTicketsRunning();
    }, []);

    useEffect(() => {
        const ticketsId = ticketsRunning?.data;
        if (undefined === ticketsId) {
            return;
        }

        fetchTickets([...ticketsId]);
    }, [ticketsRunning]);

    const location = locations[locationId]?.data;
    if (location === undefined || ticketsRunning?.data === undefined) {
        return null;
    }

    let locationTickets: string[] = [];
    for (const ticketId of ticketsRunning.data) {
        if (tickets[ticketId]?.data === undefined || tickets[ticketId].data?.locationId !== locationId) {
            continue;
        }

        // @ts-ignore
        locationTickets.push(tickets[ticketId].data.id);
    }

    const addPeople = () => {
        const newState = peopleNumber + 1;
        if (peopleNumber > 19) {
            return;
        }
        setPeopleNumber(() => newState);
    };

    const reducePeople = () => {
        const newState = peopleNumber - 1;
        if (peopleNumber < 2) {
            return;
        }
        setPeopleNumber(() => newState);
    };

    const handleTakeTicket = () => {
        sendTakeTicketEvent(getUserIdUseCase.execute(), locationId, peopleNumber);
        takeTicketUseCase.execute(locationId, form, peopleNumber);
    }

    return locationTickets.length > 0 ? <>
        {locationTickets.map((ticketId: string, index: number) => <Card key={`ticket-${index}`} ticketId={ticketId} showLocationName={false}/>)}
    </> :
        <div className={classes(backgroundColor('secondary'), border(), round(), margin('t', '10px'))}>            <div className={`p-[10px] border-b`}>
            <TicketsInProgressInQueue locationId={locationId}/>
        </div>
        {!queueRequest ? <div className={`p-[10px] flex justify-center`}>
            {location.isQueueOpened && location.queueEmergencyStop === 0 && <Button size={"full"} onClick={() => setQueueRequest(() => true)} label={Translator.translate(CTA_QUEUE)}/>}
            {(!location.isQueueOpened || location.queueEmergencyStop === 1) && <Label textColor={"error"} label={LABEL_ERROR(`queue_is_closed`)}/>}
        </div> : <div className={`p-[10px]`}>
            <div className={`pb-[10px]`}>
                <Label label={Translator.translate(LABEL_LOCATION_QUEUE_REQUEST_DESCRIPTION)}/>
            </div>
            <div className={classes(flex("center", "center", '10px'), margin('b', '10px'), padding('xy', '5px'), border(), round(), backgroundColor('primary'))}>
                <div className={classes(boldText())}><Label label={getIcon('Minus', 'small')} textColor={"error"} className={classes(cursor("pointer"))} onClick={reducePeople}/></div>
                <div className={classes(width('152px'), textAlign("center"))}><Label className={classes(textSize('20px'))} label={peopleNumber > 1 ? LABEL_PLURAL_PEOPLE(peopleNumber) : LABEL_SINGLE_PEOPLE}/></div>
                <div className={classes(boldText())}><Label label={getIcon('Plus', 'small')} textColor={"success"} className={classes(cursor("pointer"))} onClick={addPeople}/></div>
            </div>
            {!isLoggedInUseCase.execute() && <div className={`mb-[10px]`}>
                {/* @ts-ignore */}
                <Input className={classes(width('100%'), border(), backgroundColor('primary'), padding('xy', '10px'))} inputData={form.fields.name} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} name={'name'} placeholder={Translator.translate(LABEL_TICKET_UNLOGGED_USERNAME)}/>
            </div>}
            <div>
                <div className={`justify-center flex`}><Button style={'gray-secondary'} size={"full"} onClick={handleTakeTicket} label={Translator.translate(CTA_CONFIRM)}/></div>
            </div>
            {!isLoggedInUseCase.execute() && <ToRegister>
                <div className={`mt-[10px] flex justify-center gap-[10px] text-[12px] items-center`}>
                    <CircledWarning width={`20px`} className={`inline`}/> {Translator.translate(CTA_LOGIN_ADVANTAGES_LINK)}
                </div>
            </ToRegister>}
        </div>}
    </div>;
}

export default TicketWidget;