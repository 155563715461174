import Translator from "../../../../Utils/Translator";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {useEffect} from "react";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import AddressStyled from "../AddressStyled/AddressStyled";
import ContactEmail from "../Common/Data/ContactEmail";
import PhoneNumber from "../Common/Data/PhoneNumber";
import Label from "../../../Common/Label/Label";
import {
    LABEL_LOCATION_ADDRESS,
    LABEL_LOCATION_CONTACT,
    LABEL_LOCATION_QUEUE_SCHEDULE, LABEL_LOCATION_QUEUE_SCHEDULE_DAY_NOT_CONFIGURED
} from "../../../../Constants/Labels";
import {
    FRIDAY_DEFAULT,
    MONDAY_DEFAULT, SATURDAY_DEFAULT, SUNDAY_DEFAULT,
    THURSDAY_DEFAULT,
    TUESDAY_DEFAULT,
    TYPE_TICKET,
    WEDNESDAY_DEFAULT
} from "../Form/ScheduleForm";
import {backgroundColor, border, classes, margin, padding, round} from "../../../../Theme/Theme";

interface ContactInterface {
    locationId: string;
}

const translationPrefix = Translator.generateTranslationPrefix('libraries', 'domain_location_contact')

function Contact({locationId}: ContactInterface) {
    const {fetch: fetchLocations, locations} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = locations[locationId]?.data;
    if (location === undefined) {
        return null;
    }

    const locationOpenings = [
        {
            dateOpening: MONDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: TUESDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: WEDNESDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: THURSDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: FRIDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: SATURDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: SUNDAY_DEFAULT,
            time: [],
        },
    ];

    if (location.openings.length > 0) {
        for (const dayIndex in locationOpenings) {
            locationOpenings[dayIndex].time = location.openings.filter((schedule: any) =>
                schedule.dateOpening === locationOpenings[dayIndex].dateOpening
                && schedule.timeOpening.hour !== ''
                && schedule.timeOpening.minute !== ''
                && schedule.timeClosing.hour !== ''
                && schedule.timeClosing.minute !== ''
            );
        }
    }

    const dayTranslation = (dayName: string) => {
        let dayTranslated = undefined;
        switch(dayName) {
            case MONDAY_DEFAULT:
                dayTranslated = 'monday';
                break;
            case TUESDAY_DEFAULT:
                dayTranslated = 'tuesday';
                break;
            case WEDNESDAY_DEFAULT:
                dayTranslated = 'wednesday';
                break;
            case THURSDAY_DEFAULT:
                dayTranslated = 'thursday';
                break;
            case FRIDAY_DEFAULT:
                dayTranslated = 'friday';
                break;
            case SATURDAY_DEFAULT:
                dayTranslated = 'saturday';
                break;
            case SUNDAY_DEFAULT:
                dayTranslated = 'sunday';
                break;
        }

        if (dayTranslated !== undefined) {
            return Translator.translate(Translator.generateTranslationData(translationPrefix, 'label', `day_name_${dayTranslated}`));
        }

        return dayName;
    };

    const getTime = (time: any) => {
        return <span>
            <span className={`font-bold`}>{time.timeOpening.hour}:{time.timeOpening.minute}</span>
            &nbsp;<span>{Translator.translate(Translator.generateTranslationData(translationPrefix, 'label', 'schedule_separator'))}</span>&nbsp;
            <span className={`font-bold`}>{time.timeClosing.hour}:{time.timeClosing.minute}</span>
        </span>;
    };

    return <div className={classes(backgroundColor('secondary'), border(), margin('t', '10px'), round(), padding('xy', '10px'))}>
        <div className={`font-bold underline mb-[10px]`}>
            <Label label={Translator.translate(LABEL_LOCATION_ADDRESS)}/>
        </div>
        <div>
            <AddressStyled locationId={locationId}/>
        </div>
        <div className={`font-bold underline mb-[10px] mt-[10px]`}>
            <Label label={Translator.translate(LABEL_LOCATION_CONTACT)}/>
        </div>
        <div>
            <div>
                <LabelNewLine label={<ContactEmail locationId={locationId}/>}/>
                <LabelNewLine label={<PhoneNumber locationId={locationId}/>}/>
            </div>
        </div>
        {location.openings.length > 0 && <div className={`mt-[10px]`}>
            <div className={`font-bold underline mb-[10px] mt-[10px]`}>
                <Label label={Translator.translate(LABEL_LOCATION_QUEUE_SCHEDULE)}/>
            </div>
            {locationOpenings.map((day: any, locationopeningIndex) => <div key={`location-opening-${locationopeningIndex}`} className={`flex justify-between items-start`}>
                <div className={`underline`}>
                    {dayTranslation(day.dateOpening)}
                </div>
                <div>
                    {day.time.length === 0 ? Translator.translate(LABEL_LOCATION_QUEUE_SCHEDULE_DAY_NOT_CONFIGURED)
                    : day.time.map((time: any, index: number) => <div key={`${day.dateOpening}-time-${index}`}>
                        {getTime(time)}
                    </div>)}
                </div>
            </div>)}
        </div>}
    </div>;
}

export default Contact;