import IsGoogleAnalyticsEventEnabledUseCase from "./IsGoogleAnalyticsEventEnabledUseCase";
import InitGoogleAnalyticsUseCase from "./InitGoogleAnalyticsUseCase";
import FormatToStringGoogleAnalyticsParamsUseCase from "./FormatToStringGoogleAnalyticsParamsUseCase";
import {FEATURES} from "../../../Constants/Server";
import {APP_NAME} from "../../../Constants/Constants";
import ReactGA from "react-ga";

function SendGoogleAnalyticsEventUseCase() {
    const isGoogleAnalyticsEventEnabledUseCase = IsGoogleAnalyticsEventEnabledUseCase();
    const initGoogleAnalyticsUseCase = InitGoogleAnalyticsUseCase();
    const formatToStringGoogleAnalyticsParamsUseCase = FormatToStringGoogleAnalyticsParamsUseCase();

    const execute = (userId: undefined|string, category: string, action: string, params?: any) => {
        if (!isGoogleAnalyticsEventEnabledUseCase.execute()) {
            return;
        }
        initGoogleAnalyticsUseCase.execute();

        const GAData = formatToStringGoogleAnalyticsParamsUseCase.execute(userId, params);
        if (FEATURES[APP_NAME].debugGoogleAnalyticsEvents) {
            console.warn('SendGoogleAnalyticsEventUseCase', category, action, GAData);
        }

        try {
            ReactGA.event({
                category,
                action,
                label: JSON.stringify(GAData)
            });
        } catch (e: any) {
            console.error('Error while trying to fire Google Analytics event:', category, action, GAData, e);
        }
    }

    return {execute};
}


export default SendGoogleAnalyticsEventUseCase;