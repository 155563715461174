import {useEffect} from "react";
import Translator from "../../../../Utils/Translator";
import TicketsCounterTile from "../../Ticket/TicketsCounterTile/TicketsCounterTile";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import Loading from "../../../Common/Loading/Loading";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {LocationsSearchesLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsSearchesLoader";
import {SearchLocationsUseCase} from "../../../../UseCases/Location/SearchLocationsUseCase";
import {MediumFirstImage, SmallFirstImage} from "../Common/Image/FirstImage";
import AddressOneLine from "../Common/Data/AddressOneLine";
import Label from "../../../Common/Label/Label";
import Name from "../Common/Data/Name";
import {FRONT__COMMON__LOCATION_URL} from "../../../../Constants/FrontUrls";
import {
    backgroundColor,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textAlign,
    textColor
} from "../../../../Theme/Theme";
import SpinningCircle from "../../Ticket/RunningIcon/SpinningCircle";
import {GetTicketsByLocationIdUseCase} from "../../../../UseCases/Ticket/GetTicketsByLocationIdUseCase";

const TRANSLATION_PREFIX = 'libraries__components_domain_location_search_results__';

function SearchResults() {
    const {fetch: fetchLocations} = LocationsLoader();
    const {locationsSearches, isLoading} = LocationsSearchesLoader();
    const {keyword} = SearchLocationsUseCase();

    useEffect(() => {
        if (keyword !== undefined && locationsSearches[keyword]?.data !== undefined) {
            fetchLocations(locationsSearches[keyword]?.data);
        }
    }, [locationsSearches])

    if (keyword === undefined || keyword.length === 0) {
        return <LabelNewLine className={classes(textColor('ternary'), textAlign("center"), margin('t', '10px'))} italic={true} label={Translator.getTranslation(`${TRANSLATION_PREFIX}label__type_to_search`)}/>;
    }

    if (isLoading) {
        return <div className={`flex justify-center`}>
            <Loading/>
        </div>;
    }

    return <div className={classes(`max-h-[calc(100vh-180px)] max-h-[500px] overflow-y-auto custom-scrollbar`)}>
        {locationsSearches[keyword ?? '']?.data?.map((locationId: string, index: number) => <SearchResult key={`search-result-${index}`} locationId={locationId}/>)}
    </div>;
}

interface SearchResultInterface {
    locationId: string;
}

export function SearchResult({locationId}: SearchResultInterface) {
    const {fetch, locations} = LocationsLoader();
    const getTicketsByLocationIdUseCase = GetTicketsByLocationIdUseCase();

    useEffect(() => {
        fetch([locationId]);
    }, []);

    const location = locations[locationId]?.data;

    return location === undefined ? null : <a href={FRONT__COMMON__LOCATION_URL(locationId)}><div className={classes(backgroundColor("secondary"), round(), border(), margin('t', '10px'), padding('xy', '10px'), flex('top', 'left', '10px'))}>
        <div className={`w-[100px] h-[100px] max-w-[100px] max-h-[100px] flex items-center justify-center w-full`}>
            <SmallFirstImage locationId={locationId}/>
        </div>
        <div className={`ml-[10px] h-full max-w-[50%]`}>
            <div>
                <div className={`flex gap-[5px] items-center`}>
                    <TicketsCounterTile locationId={locationId}/>
                    <Label label={<Name locationId={locationId}/>} boldText={true}/>
                    <SpinningCircle ticketIds={getTicketsByLocationIdUseCase.execute(locationId)}/>
                </div>
                <Label label={<AddressOneLine locationId={locationId}/>}/>
                <LabelNewLine italic={true} label={Translator.getTranslation(`${TRANSLATION_PREFIX}location_tickets_counter`, {counter: location.ticketsToValidate})}/>
            </div>
        </div>
    </div></a>;
}

export default SearchResults;