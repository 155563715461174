import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";
import Address from "./Address";
import ZipCodeCityOneLine from "./ZipCodeCityOneLine";

interface AddressInterface {
    locationId: string;
}

const AddressOneLine = ({locationId}: AddressInterface) => {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location || location.address.length === 0 || location.zipCode.length === 0 || location.city.length === 0) {
        return null;
    }

    return <><Address locationId={locationId}/>, <ZipCodeCityOneLine locationId={locationId}/></>;
}

export default AddressOneLine;