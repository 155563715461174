export function getCorners(corners?: 'default'|'medium-rounded'|'large-rounded'|'full-rounded'|'straight') {
    if (undefined === corners) {
        corners = 'default';
    }

    if (corners === "straight") {
        return '';
    }

    if (corners === 'default') {
        return 'rounded';
    }

    if (corners === 'medium-rounded') {
        return 'rounded-xl';
    }

    if (corners === 'large-rounded') {
        return 'rounded-2xl';
    }

    if (corners === 'full-rounded') {
        return 'rounded-full';
    }

    return '';
}