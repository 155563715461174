import Button from "../../Button/Button";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import {State} from "../../../../../Layout/Menu/State";
import Link from "../../Link/Link";
import {CTA_REGISTER_LINK, LABEL_LOG_IN, LABEL_LOGIN} from "../../../../Constants/Labels";
import {backgroundColor, classes, flex, padding, textColor} from "../../../../Theme/Theme";

function MenuUnlogged() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {toggle} = State();

    if (isLoggedInUseCase.execute()) {
        return null;
    }

    return <div className={classes(flex("top", "left", '10px'), padding("y", '20px'), padding('x', '10px'), backgroundColor("ternary"), textColor('ternary'))}>
        <Link to={`/register`} className={`w-full`}>
            <Button
                size={"full"}
                label={CTA_REGISTER_LINK}
                onClick={toggle}
                color={'ternary'}
            />
        </Link>
        <Link to={`/login`} className={`w-full`}>
            <Button
                size={"full"}
                label={LABEL_LOG_IN}
                onClick={toggle}
                color={'ternary'}
            />
        </Link>
    </div>;
}

export default MenuUnlogged;