import {CreateLocationCommentUseCase} from "../../../../UseCases/Comment/CreateLocationCommentUseCase";
import TextareaContent from "../Common/Form/Textarea/TextareaContent";
import InputTitle from "../Common/Form/Input/InputTitle";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import {LABEL_ERROR, LABEL_SUCCESS} from "../../../../Constants/Labels";
import {backgroundColor, border, classes, flex, margin, round} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";
import {sendCreateCommentEvent} from "../../../../GoogleAnalytics/Events";

interface CommentFormInterface {
    locationId: string;
}

function Create({locationId}: CommentFormInterface) {
    const {handleFocus, handleChange, handleBlur, form} = CreateLocationCommentUseCase();
    const sendLocationCommentUseCase = CreateLocationCommentUseCase();
    const getUserIdUseCase = GetUserIdUseCase();

    const handleSubmitComment = (e: any) => {
        sendCreateCommentEvent(getUserIdUseCase.execute(), locationId);
        sendLocationCommentUseCase.execute(e, locationId)
    }

    return <form onSubmit={handleSubmitComment}>
        <div><InputTitle className={classes(backgroundColor("primary"), border(), round())} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/></div>
        <div><TextareaContent className={classes(backgroundColor("primary"), border(), round(), margin('t', '10px'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/></div>
        <div className={classes(margin('t', '10px'))}>
            {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
            {form.success !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.success) === 'object' ? form.error : LABEL_SUCCESS(form.success)} textColor={"success"}/>}
        </div>
        <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
            <ButtonSubmit/>
        </div>
    </form>;
}

export default Create;