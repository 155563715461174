import Title from "../Common/Data/Title";
import Content from "../Common/Data/Content";
import Username from "../Common/Data/Username";
import Datetime from "../Common/Data/Datetime";
import {backgroundColor, border, classes, margin, round} from "../../../../Theme/Theme";

interface CardInterface {
    commentId: string;
}

function Card({commentId}: CardInterface) {
    return <div className={classes(backgroundColor("secondary"), border(), round(), margin('t', '10px'))}>
        <div className={`p-[10px]`}>
            <div className={`font-bold`}>
                <Title commentId={commentId}/>
            </div>
            <div>
                <Content commentId={commentId}/>
            </div>
        </div>
        <div className={`border-b`}/>
        <div className={`flex justify-end italic py-[3px] px-[10px] gap-[10px]`}>
            <div>
                <Username commentId={commentId}/>
            </div>
            <div>
                -
            </div>
            <div>
                <Datetime commentId={commentId}/>
            </div>
        </div>
    </div>;
}

export default Card;