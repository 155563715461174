import QrCodeModal from "../QrCodeModal/QrCodeModal";
import {useEffect} from "react";
import {
    LocationsLoader
} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {FavoriteLocationsLoader} from "../../../../Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import {FullFirstImage} from "../Common/Image/FirstImage";
import Name from "../Common/Data/Name";
import Label from "../../../Common/Label/Label";
import Translator from "../../../../Utils/Translator";
import {LABEL_COMMENT_COUNTER} from "../../../../Constants/Labels";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import FavoriteToggler from "../FavoriteToggler/FavoriteToggler";
import QrCodeToggler from "../QrCodeToggler/QrCodeToggler";
import {
    backgroundColor,
    border,
    classes, cursor,
    flex,
    height,
    margin,
    padding,
    position,
    round, textSize,
    width
} from "../../../../Theme/Theme";


interface HeaderInterface {
    locationId: string;
}

function Header({locationId}: HeaderInterface) {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {fetch: fetchLocations, locations} = LocationsLoader();
    const {fetch: fetchFavoriteLocations, favoriteLocations} = FavoriteLocationsLoader();

    useEffect(() => {
        fetchFavoriteLocations();
    }, []);

    useEffect(() => {
        fetchLocations([locationId])
    }, []);

    const location = locations[locationId]?.data;
    if (location === undefined) {
        return null;
    }

    return <div className={classes(backgroundColor("secondary"), round(), border())}>
        <QrCodeModal locationId={locationId}/>
        <div className={classes(flex("center", "left"), border('b'), padding('xy', '10px'))}>
            <div>
                <div>
                    <Label className={classes(textSize('20px'))} label={<Name locationId={locationId}/>} boldText={true}/>
                </div>
                <div>
                    <Label label={Translator.translate(LABEL_COMMENT_COUNTER(location.commentsCounter))} italic={true}/>
                </div>
            </div>
        </div>
        <div className={classes(position("absolute"), margin('l', '10px'))}>
            {isLoggedInUseCase.execute() && <div className={classes(width('30px'), margin('t', '10px'), height('30px'), flex("center", "center"), round("full"), border(), cursor("pointer"), backgroundColor("primary", true))}>
                <FavoriteToggler locationId={locationId}/>
            </div>}
            <div className={classes(margin('t', '10px'), width('30px'), height('30px'), flex("center", "center"), round("full"), border(), cursor("pointer"), backgroundColor("primary", true))}>
                <QrCodeToggler/>
            </div>
        </div>
        <div className={`flex justify-center items-center w-full min-h-[100px] h-[20%] z-[0] p-[10px]`} style={{backgroundColor: location.coverImageBackground}}>
            <FullFirstImage locationId={locationId}/>
        </div>
    </div>;
}

export default Header;