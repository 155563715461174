import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import {LocationsSearchesLoader} from "../../Api/Loaders/IndexedLoaders/LocationsSearchesLoader";
import sendGoogleAnalyticsEventUseCase from "../GoogleAnalytics/SendGoogleAnalyticsEventUseCase";
import SendGoogleAnalyticsEventUseCase from "../GoogleAnalytics/SendGoogleAnalyticsEventUseCase";
import {GetUserDataUseCase} from "../User/GetUserDataUseCase";

function SearchLocations() {
    const {fetch: fetchLocationsSearches} = LocationsSearchesLoader();
    const sendGoogleAnalyticsEventUseCase = SendGoogleAnalyticsEventUseCase();
    const getUserDataUseCase = GetUserDataUseCase();

    const [keyword, setKeyword] = useState<undefined|string>();
    const [keywordDebounce, setKeywordDebounce] = useState<undefined|string>();

    useEffect(() => {
        if (undefined !== keywordDebounce) {
            const timer = setTimeout(() => setKeyword(keywordDebounce), 500);
            return () => clearTimeout(timer);
        }
    }, [keywordDebounce]);

    useEffect(() => {
        if (keyword !== undefined) {
            sendGoogleAnalyticsEventUseCase.execute(getUserDataUseCase.execute(), 'searchLocations', 'debounced', {pageName: 'all', keyword});
            fetchLocationsSearches(keyword);
        }
    }, [keyword]);

    const execute = (e: any) => setKeywordDebounce(e.target.value);

    return {execute, keywordDebounce, keyword, setKeywordDebounce};
}

export const SearchLocationsUseCase = () => useBetween(SearchLocations);