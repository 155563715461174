import MenuLink from "../MenuLink";
import Translator from "../../../../../Utils/Translator";
import {State} from "../../../../../../Layout/Menu/State";
import {CTA_FAVORITES} from "../../../../../Constants/Labels";

const TRANSLATION_PREFIX = 'libraries__components_common_menu_menu_link_links_menu_link_favorite__';

function MenuLinkFavorites() {
    const {toggle} = State();

    return <MenuLink
        link={'/favorites'}
        label={CTA_FAVORITES}
        onClick={toggle}
    />
}

export default MenuLinkFavorites;