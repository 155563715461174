import {useEffect} from "react";
import Translator from "../../../../Utils/Translator";
import {LocationsCommentsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsCommentsLoader";
import Card from "../../Comment/Card/Card";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import Create from "../../Comment/Form/Create";
import {LABEL_COMMENT_FORM_TITLE} from "../../../../Constants/Labels";
import Label from "../../../Common/Label/Label";
import {
    backgroundColor,
    boldText,
    border,
    classes, margin,
    padding,
    round,
    textSize,
    underline,
    width
} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

interface CommentsInterface {
    locationId: string;
}

function Comments({locationId}: CommentsInterface) {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {fetch: fetchLocationsComments, locationsComments} = LocationsCommentsLoader();

    useEffect(() => {
        fetchLocationsComments(locationId);
    }, []);

    const comments = locationsComments[locationId]?.data;

    if (undefined === comments) {
        return null;
    }

    return <div className={classes(width('100%'))}>
        {isLoggedInUseCase.execute() && <div className={classes(padding('xy', '10px'), border(), round(), backgroundColor("secondary"))}>
            <LabelNewLine className={classes(boldText(), underline(), textSize('20px'), margin('b', '10px'))} label={Translator.translate(LABEL_COMMENT_FORM_TITLE)}/>
            <Create locationId={locationId}/>
        </div>}
        {comments.map((commentId: string, index: number) => <Card key={`comment-${index}`} commentId={commentId}/>)}
    </div>;
}

export default Comments;