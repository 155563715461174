import React, {ReactElement} from "react";
import Translator from "../Utils/Translator";
import {isReactElement} from "./React";

export function getLabel(label?: string|{id: string, variables: any}|ReactElement|number) {
    if (label === undefined) {
        label = '';
    }

    if (isReactElement(label)) {
        return label;
    }

    if (typeof label !== 'string') {
        // @ts-ignore
        return Translator.translate(label);
    }

    return label;
}