import {TicketsLoader} from "../../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {useEffect} from "react";
import Label from "../../../../Common/Label/Label";
import Translator from "../../../../../Utils/Translator";

interface TicketsBeforeInterface {
    ticketId: string;
}

const TRANSLATION_PREFIX = 'libraries__components_domain_location_common_label_tickets_before__';

export function TicketsBefore({ticketId}: TicketsBeforeInterface) {
    const {fetch: fetchTickets, getTicketById} = TicketsLoader();

    useEffect(() => {
        fetchTickets([ticketId]);
    }, []);

    const ticket = getTicketById(ticketId);

    if (undefined === ticket) {
        return null;
    }

    if (ticket.ticketsCountBefore === 0) {
        return <Label label={Translator.getTranslation(`${TRANSLATION_PREFIX}label__you_are_next`)}/>
    }

    return <Label label={Translator.getTranslation(`${TRANSLATION_PREFIX}label__tickets_left`, {counter: ticket.ticketsCountBefore})}/>
}

export function TicketsBeforeTurn({ticketId}: TicketsBeforeInterface) {
    const {fetch: fetchTickets, getTicketById} = TicketsLoader();

    useEffect(() => {
        fetchTickets([ticketId]);
    }, []);

    const ticket = getTicketById(ticketId);

    if (undefined === ticket) {
        return null;
    }

    if (ticket.ticketsCountBefore === 0) {
        return <Label label={Translator.getTranslation(`${TRANSLATION_PREFIX}label__you_are_next_turn`)}/>
    }

    return <Label label={Translator.getTranslation(`${TRANSLATION_PREFIX}label__tickets_before_turn`, {counter: ticket.ticketsCountBefore})}/>
}