import {Link} from "react-router-dom";
import {useEffect} from "react";
import {TicketsLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {TicketsRunningLoader} from "../../../../Api/Loaders/ValueLoaders/TicketsRunningLoader";
import SpinningCircle from "../RunningIcon/SpinningCircle";
import {TicketsBeforeTurn} from "../Common/Label/TicketsBefore";
import Name from "../../Location/Common/Data/Name";
import Label from "../../../Common/Label/Label";
import {backgroundColor, border, classes, padding, round} from "../../../../Theme/Theme";

interface TileInterface {
    ticketId: string;
}

function Tile({ticketId}: TileInterface) {
    const {fetch: fetchTicket, tickets} = TicketsLoader();
    const {ticketsRunning} = TicketsRunningLoader();

    useEffect(() => {
        fetchTicket([ticketId]);
    }, [ticketsRunning]);

    const ticket = tickets[ticketId]?.data;
    if (ticket === undefined) {
        return null;
    }

    return <Link to={`/locations/${ticket.locationId}`} className={classes(border(), backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <div className={`flex gap-[5px] items-center`}>
            <SpinningCircle ticketIds={[ticketId]}/>
            <Label label={<Name locationId={ticket.locationId}/>} boldText={true}/>
            <TicketsBeforeTurn ticketId={ticketId}/>
        </div>
    </Link>;
}

export default Tile;