export const API_URL = 'https://queube-api.zapto.org';
export const CLIENT_URL = 'https://dev-client.queube.fr';
export const QUEUBE_URL = 'https://dev-back-office.queube.fr';
export const ADMIN_URL = 'https://dev-admin.queube.fr';
export const STORYBOOK_URL = 'https://dev-storybook.queube.fr';
export const ENVIRONMENT = 'DEV';
export const FEATURES = {
    client: {
        googleAnalyticsTrackingId: 'UA-273192187-8',
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'owner-admin': {
        googleAnalyticsTrackingId: 'UA-273192187-4',
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'queube-admin': {
        googleAnalyticsTrackingId: undefined,
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'client-front-maintenance': {
        googleAnalyticsTrackingId: 'UA-273192187-3',
        sendGoogleAnalyticsEvents: true,
        sendGoogleAnalyticsPageViews: true,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
    'storybook': {
        googleAnalyticsTrackingId: undefined,
        sendGoogleAnalyticsEvents: false,
        sendGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsPageViews: false,
        debugGoogleAnalyticsEvents: false,
    },
};