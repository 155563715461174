export function getHeight(height?: 'auto'|'small'|'medium'|'large'|'full') {
    if (height === undefined) {
        height = 'auto';
    }

    if (height === 'small') {
        return 'h-[100px]';
    }

    if (height === 'medium') {
        return 'h-[200px]';
    }

    if (height === "large") {
        return 'h-[300px]';
    }

    if (height === 'full') {
        return 'h-full';
    }

    return '';
}