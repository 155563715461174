import Translator from "../../../../../../Utils/Translator";
import {LABEL_PASSWORD} from "../../../../../../Constants/Labels";
import InputPwd from "../../../../../Common/Form/InputPwd";

interface InputPasswordInterface {
    form: any;
    handleChange: any;
    handleBlur?: any;
    handleFocus?: any;
    className?: string;
}

function InputPassword({form, handleChange, handleBlur, handleFocus, className}: InputPasswordInterface) {
    let handles = {};
    if (handleBlur !== undefined) {
        handles = { ...handles, handleBlur };
    }
    if (handleFocus !== undefined) {
        handles = { ...handles, handleFocus };
    }

    return <InputPwd
        inputData={form.fields.password}
        name={'password'}
        onChange={handleChange}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_PASSWORD)}
        {...handles}
        className={className ?? ''}
    />;
}

export default InputPassword;