import {useBetween} from "use-between";
import {TicketCallNotificationCache} from "../../Caches/Domain/Ticket/TicketCallNotificationsCache";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {useEffect} from "react";
import {TicketsRunningLoader} from "../../Api/Loaders/ValueLoaders/TicketsRunningLoader";

function AutoCreateTicketCallNotifications() {
    const {ticketCallNotifications, save} = TicketCallNotificationCache();
    const {ticketsRunning} = TicketsRunningLoader();
    const {fetch: fetchTickets, tickets} = TicketsLoader();

    useEffect(() => {
        if (ticketsRunning?.data !== undefined) {
            fetchTickets(ticketsRunning.data);
        }
    }, [ticketsRunning]);

    useEffect(() => {
        if (ticketsRunning?.data !== undefined) {
            const newState = {...ticketCallNotifications};
            for (const ticketId of ticketsRunning?.data) {
                if (tickets[ticketId]?.data !== undefined) {
                    let closeStatus = newState[ticketId] !== undefined ? newState[ticketId].closed : false;
                    if (newState[ticketId] !== undefined && tickets[ticketId]?.data.waitingCount > newState[ticketId].ticket.waitingCount) {
                        closeStatus = false;
                    }

                    newState[ticketId] = {
                        ticket: tickets[ticketId]?.data,
                        closed: closeStatus,
                    };
                }
            }
            save(newState);
        }
    }, [tickets]);
}

export const AutoCreateTicketCallNotificationsUseCase = () => useBetween(AutoCreateTicketCallNotifications);