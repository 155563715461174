import Translator from "../../Libraries/Utils/Translator";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {useEffect} from "react";
import Card from "../../Libraries/Components/Domain/Ticket/Card/Card";
import {TicketsRunningLoader} from "../../Libraries/Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {OldTicketsLoader} from "../../Libraries/Api/Loaders/ValueLoaders/OldTicketsLoader";
import LabelNewLine from "../../Libraries/Components/Common/Label/LabelNewLine";
import {LABEL_CURRENT_TICKETS, LABEL_TICKETS_HISTORY} from "../../Libraries/Constants/Labels";
import {classes, margin, textSize, underline, width} from "../../Libraries/Theme/Theme";
import OldCard from "../../Libraries/Components/Domain/Ticket/OldCard/OldCard";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendTicketsPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function Tickets() {
    const {fetch: fetchTicketsRunning, ticketsRunning} = TicketsRunningLoader();
    const {fetch: fetchOldTickets, oldTickets} = OldTicketsLoader()
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendTicketsPageView(getUserIdUseCase.execute());
        fetchTicketsRunning();
        fetchOldTickets();
    }, []);

    let allTickets: any = [];
    if (ticketsRunning?.data !== undefined) {
        allTickets = [...allTickets, ...ticketsRunning.data];
    }
    if (oldTickets?.data !== undefined) {
        allTickets = {...allTickets, ...oldTickets.data};
    }

    return allTickets.length === 0 ? <PageTemplate centeredContent={true}>
        <div className={`text-center w-full italic`}>{Translator.getTranslation('client__tickets_page__label__no_ticket')}</div>
    </PageTemplate> : <PageTemplate>
        <div className={classes(width('100%'))}>
            {ticketsRunning?.data !== undefined && ticketsRunning.data.length > 0 && <>
                <LabelNewLine className={classes(textSize('30px'), underline())} label={LABEL_CURRENT_TICKETS}/>
                <div className={`flex justify-center gap-[10px] flex-wrap`}>
                    {ticketsRunning.data.map((ticketId: string, index: number) => <Card key={`ticket-${index}`} ticketId={ticketId}/>)}
                </div>
            </>}
            {oldTickets?.data !== undefined && oldTickets.data.length > 0 && <>
                <LabelNewLine className={classes(textSize('30px'), underline(), margin('t', '20px'))} label={LABEL_TICKETS_HISTORY}/>
                <div className={`flex justify-center gap-[10px] flex-wrap`}>
                    {oldTickets.data.map((ticketId: string, index: number) => <OldCard key={`ticket-${index}`} ticketId={ticketId}/>)}
                </div>
            </>}
        </div>
    </PageTemplate>;
}

export default Tickets;