import Translator from "../../../../Utils/Translator";
import Loading from "../../../Common/Loading/Loading";
import {useEffect} from "react";
import InputFirstname from "../Common/Form/Input/InputFirstname";
import InputLastname from "../Common/Form/Input/InputLastname";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import {
    LABEL_CONFIGURATION_TITLE,
    LABEL_ERROR,
    LABEL_SUCCESS,
    LABEL_SYSTEM_TITLE
} from "../../../../Constants/Labels";
import {UsersLoader} from "../../../../Api/Loaders/IndexedLoaders/UsersLoader";
import {UpdateUserUseCase} from "../../../../UseCases/User/UpdateUserUseCase";
import Label from "../../../Common/Label/Label";
import {backgroundColor, border, classes, flex, margin, padding, round, textSize, width} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import CheckboxQueueEmergencyStop from "../../Location/Common/Form/Checkbox/CheckboxQueueEmergencyStop";

const TRANSLATION_PREFIX = 'queube__components_form_update_owner_update_owner_form__';

interface UpdateOwnerFormInterface {
    userId: string;
}

function UpdateForm({userId}: UpdateOwnerFormInterface) {
    const {fetch: fetchUsers, getUserById} = UsersLoader();
    const {form, handleChange, handleFocus, handleBlur, setUserId} = UpdateUserUseCase();
    const updateUserUseCase = UpdateUserUseCase();

    useEffect(() => {
        setUserId(userId);
        fetchUsers([userId]);
    }, []);

    const user = getUserById(userId);
    if (user === undefined) {
        return null;
    }

    return <div className={classes(width('100%'))}>
        <div className={classes(padding('xy', '10px'), backgroundColor('secondary'), border(), round(), flex("center", "left", '10px'))}>
            <div className={classes(textSize('30px'))}>{form.isLoading ? <Loading/> : user?.login}</div>
        </div>
        <div className={classes(backgroundColor("secondary"), padding('xy', '10px'), round(), width('100%'), border(), margin('t', '10px'))}>
            <LabelNewLine className={classes(textSize('20px'))} label={LABEL_SYSTEM_TITLE}/>
            <div className={classes(flex('top', "between", '10px'))}>
                <input type={'text'} value={user.timezone} className={classes(padding('xy', '10px'), border(), backgroundColor("primary"), round(), margin('t', '10px'))} disabled/>
            </div>
        </div>
        {/*@ts-ignore*/}
        <form onSubmit={(e: any) => updateUserUseCase.execute(e, user.id)}>
            <div className={classes(backgroundColor("secondary"), padding('xy', '10px'), round(), width('100%'), border(), margin('t', '10px'))}>
                <LabelNewLine className={classes(textSize('20px'))} label={LABEL_CONFIGURATION_TITLE}/>
                <div className={`mt-[10px]`}>
                    <input type={'text'} value={user.login} className={classes(width('100%'), backgroundColor("primary"), border(), padding('xy', '10px'), round())} disabled/>
                    <input type={'text'} value={user.email} className={classes(width('100%'), backgroundColor("primary"), border(), padding('xy', '10px'), round(), margin('t', '10px'))} disabled/>
                    <InputFirstname className={classes(width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/>
                    <InputLastname className={classes(width('100%'), backgroundColor("primary"), border(), margin('t', '10px'))} form={form} handleBlur={handleBlur} handleChange={handleChange} handleFocus={handleFocus}/>
                </div>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
                {form.success !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.success) === 'object' ? form.error : LABEL_SUCCESS(form.success)} textColor={"success"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default UpdateForm;