import Translator from "../../../../../../Utils/Translator";
import {LABEL_PASSWORD, LABEL_PASSWORD_CONFIRMATION} from "../../../../../../Constants/Labels";
import InputPwd from "../../../../../Common/Form/InputPwd";

interface InputPasswordConfirmationInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    isSamePassword: any;
    className?: string;
}

function InputPasswordConfirmation({form, handleChange, handleBlur, handleFocus, isSamePassword, className}: InputPasswordConfirmationInterface) {
    return <InputPwd
        inputData={form.fields.cpassword}
        name={'cpassword'}
        onChange={(e: any) => {handleChange(e); isSamePassword(e.target.value)}}
        placeholder={Translator.translate(LABEL_PASSWORD_CONFIRMATION)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        displayBorders={true}
        className={className ?? ''}
    />;
}

export default InputPasswordConfirmation;