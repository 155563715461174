import {IsLoggedInUseCase} from "../User/IsLoggedInUseCase";
import {GetUserDataUseCase} from "../User/GetUserDataUseCase";
import {stringify} from "querystring";
import FormatGoogleAnalyticsParamsUseCase from "./FormatGoogleAnalyticsParamsUseCase";
import {UserAccountStorage} from "../../Caches/User/UserAccountCache";

function FormatToStringGoogleAnalyticsParamsUseCase() {
    const formatGoogleAnalyticsParamsUseCase = FormatGoogleAnalyticsParamsUseCase();

    const execute = (userId: undefined|string, params?: any) => {
        const currentParams = formatGoogleAnalyticsParamsUseCase.execute(userId, params);

        return JSON.stringify(currentParams);
    };

    return {execute};
}

export default FormatToStringGoogleAnalyticsParamsUseCase;