import {ReactComponent as TicketIcon} from "../../../../Icons/Ticket.svg";
import {Link} from "react-router-dom";
import {ReactElement, useEffect} from "react";
import {TicketsLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {GetColorUseCase} from "../../../../UseCases/Ticket/GetColorUseCase";
import {TicketsRunningLoader} from "../../../../Api/Loaders/ValueLoaders/TicketsRunningLoader";

interface RunningIconInterface {
    ticketsId: string[];
    href?: string;
}

function SpinningIcon({ticketsId, href}: RunningIconInterface) {
    const {fetch, tickets} = TicketsLoader();
    const getColorUseCase = GetColorUseCase();
    const {ticketsRunning} = TicketsRunningLoader();

    useEffect(() => {
        fetch(ticketsId);
    }, [ticketsRunning]);

    const loadedTicketsRunning = ticketsId.filter((ticketId: string) => tickets[ticketId]?.data !== undefined && tickets[ticketId]?.data?.ticketsCountBefore !== undefined);
    let counters = loadedTicketsRunning.map((ticketId: string) => tickets[ticketId].data?.ticketsCountBefore);
    counters = counters.filter((counter: number|undefined) => counter !== undefined);

    if (counters.length === 0) {
        return null;
    }

    const component = <>
        <div className={`border-${getColorUseCase.execute(ticketsId)} w-[40px] h-[40px] border-dashed border-[2px] p-[5px] rounded-full spinning-circle`}></div>
        <div className={`translate-x-[8px] translate-y-[-32px]`}>
            <TicketIcon className={`w-[25px]`}/>
        </div>
    </>;

    if (undefined !== href) {
        return <RunningIconWithRedirection href={href}>
            {component}
        </RunningIconWithRedirection>;
    }

    return component;

}

interface RunningIconWithRedirectionInterface {
    children: ReactElement;
    href: string;
}

function RunningIconWithRedirection({children, href}: RunningIconWithRedirectionInterface) {
    return <Link to={href}>
        {children}
    </Link>
}

export default SpinningIcon;