import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";
import LocationImageEntity from "../../../../../Entities/LocationImageEntity";

interface ImageInterface {
    locationId: string;
    customClass?: string;
}

const getFirstImageUrl = (images: LocationImageEntity[]|any) => {
    return Object.keys(images).length > 0 ? images[Object.keys(images)[0]].url : 'https://s3.eu-west-3.amazonaws.com/static.queube.fr/default_shop.png';
};

export function SmallFirstImage({locationId, customClass}: ImageInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    customClass = customClass ?? '';

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location) {
        return null;
    }

    return <img className={`${customClass} w-[100px] max-w-[100px] h-[100px] max-h-[100px]`} src={getFirstImageUrl(location.images)} alt={`Image of ${location.name}`}/>
}

export function MediumFirstImage({locationId, customClass}: ImageInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    customClass = customClass ?? '';

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location) {
        return null;
    }

    return <img className={`${customClass} w-[150px] max-w-[150px] h-[150px] max-h-[150px]`} src={getFirstImageUrl(location.images)} alt={`Image of ${location.name}`}/>
}

export function FullFirstImage({locationId, customClass}: ImageInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    customClass = customClass ?? '';

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location) {
        return null;
    }

    return <img className={`${customClass} w-full max-w-full`} src={getFirstImageUrl(location.images)} alt={`Image of ${location.name}`}/>
}