import {IsFavoriteLocationUseCase} from "../../../../UseCases/Location/IsFavoriteLocationUseCase";
import {ToggleFavoriteLocationUseCase} from "../../../../UseCases/Location/ToggleFavoriteLocationUseCase";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import {ReactComponent as HeartIcon} from "../../../../Icons/Heart.svg";
import {APP_NAME} from "../../../../../Constants/Constants";
import {classes, cursor} from "../../../../Theme/Theme";
import {sendToggleFavoriteLocationEvent} from "../../../../GoogleAnalytics/Events";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";

interface FavoriteTogglerInterface {
    locationId: string;
    customClass?: string;
    alwaysShow?: boolean;
}

function FavoriteToggler({locationId, customClass, alwaysShow}: FavoriteTogglerInterface) {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const toggleFavoriteLocationUseCase = ToggleFavoriteLocationUseCase();
    const isFavoriteLocationUseCase = IsFavoriteLocationUseCase();
    const getUserIdUseCase = GetUserIdUseCase();

    if (alwaysShow === undefined) {
        alwaysShow = false;
    }
    // @ts-ignore
    if (!alwaysShow && (APP_NAME !== 'client' || !isLoggedInUseCase.execute())) {
        return null;
    }

    customClass = customClass ?? '';

    const handleToggleFavoriteLocation = () => {
        sendToggleFavoriteLocationEvent(getUserIdUseCase.execute(), locationId);
        toggleFavoriteLocationUseCase.execute(locationId);
    }

    return <div onClick={handleToggleFavoriteLocation} className={classes(cursor("pointer"), customClass ?? '')}>
        <HeartIcon width={`20px`} className={`${isFavoriteLocationUseCase.execute(locationId) ? 'fill-[red]' : ''}`}/>
    </div>;
}

export default FavoriteToggler;