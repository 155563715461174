import AbstractApi from "./AbstractApi";
import {ApiManager} from "../../Utils/ApiManager";
import {
    CREATE_LOCATION_FORM_VALIDATOR_URL,
    FAST_LOCATIONS_URL, LOCATION_IMAGES_URL,
    LOCATION_URL, LOCATIONS_GO_AGAIN_URL,
    LOCATIONS_URL,
    PUBLIC_LOCATION_URL,
    PUBLIC_LOCATIONS_LATEST_URL, PUBLIC_LOCATIONS_TOP_URL,
    PUBLIC_LOCATIONS_URL
} from "../../Constants/ApiUrls";

class LocationApi {
    static async fetchLocation(locationId: string, isLoggedIn: boolean = false) {
        try {
            const response = await ApiManager.get(!isLoggedIn ? PUBLIC_LOCATION_URL(locationId) : LOCATION_URL(locationId));
            return AbstractApi.returnSuccessResponse(response);
        } catch(e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLocation', {locationId, isLoggedIn});
        }
    }

    static async fetchLocations(keyword?: string, isLoggedIn?: boolean) {
        isLoggedIn = isLoggedIn ?? false;
        try {
            const response = await ApiManager.get(!isLoggedIn ? PUBLIC_LOCATIONS_URL(keyword) : LOCATIONS_URL(keyword));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLocations', {keyword, isLoggedIn});
        }
    }

    static async fetchLatestLocations() {
        try {
            const response = await ApiManager.get(PUBLIC_LOCATIONS_LATEST_URL);

            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLatestLocations');
        }
    }

    static async fetchTopLocations() {
        try {
            const response = await ApiManager.get(PUBLIC_LOCATIONS_TOP_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchTopLocations');
        }
    };

    static async fetchToGoAgainLocations() {
        try {
            const response = await ApiManager.get(LOCATIONS_GO_AGAIN_URL);

            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchToGoAgainLocation');
        }
    };

    static async fetchFastLocations(keyword: string) {
        try {
            const response = await ApiManager.get(`${FAST_LOCATIONS_URL}${keyword === undefined ? '' : `?keyword=${keyword}`}`);
            return AbstractApi.returnSuccessResponse(response);
        } catch(e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchFastLocations', {keyword});
        }
    }

    static async updateLocation(locationId: string, form: any) {
        try {
            const response = await ApiManager.put(LOCATION_URL(locationId), {
                // @ts-ignore
                name: form.fields.name.value,
                // @ts-ignore
                address: form.fields.address.value,
                // @ts-ignore
                additionalAddress: form.fields.additionalAddress.value,
                // @ts-ignore
                city: form.fields.city.value,
                // @ts-ignore
                zipCode: form.fields.zipCode.value,
                // @ts-ignore
                phoneNumber: form.fields.phoneNumber.value,
                // @ts-ignore
                contactEmail: form.fields.contactEmail.value,
                // @ts-ignore
                shortDescription: form.fields.shortDescription.value,
                // @ts-ignore
                longDescription: form.fields.longDescription.value,
                // @ts-ignore
                allowComments: !form.fields.allowComments.value ? 0 : form.fields.allowComments.value,
                // @ts-ignore
                showComments: !form.fields.showComments.value ? 0 : form.fields.showComments.value,
                queueEmergencyStop: !form.fields.queueEmergencyStop.value ? 0 : form.fields.queueEmergencyStop.value,
                // @ts-ignore
                coverImageBackground: form.fields.coverImageBackground.value,
                // @ts-ignore
                shortcutBackground: form.fields.shortcutBackground.value,
                // @ts-ignore
                shortcutText: form.fields.shortcutText.value,
                // @ts-ignore
                ownerId: form.fields.ownerId.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'updateLocation', {locationId, form});
        }
    }

    static async createLocation(form: any) {
        try {
            const response = await ApiManager.post(LOCATIONS_URL(), {name: form.fields.name.value});
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'createLocation', {form});
        }
    }

    static async fetchCreateLocationFormValidator() {
        try {
            const response = await ApiManager.get(CREATE_LOCATION_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchCreateLocationFormValidator');
        }
    }

    static async fetchImages(locationId: string) {
        try {
            const response = await ApiManager.get(LOCATION_IMAGES_URL(locationId));
            return AbstractApi.returnSuccessResponse(response);
        } catch(e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchImages', {locationId});
        }
    }

    static async uploadImage(locationId: string, form: any) {
        try {
            const response = await ApiManager.postImage(LOCATION_IMAGES_URL(locationId), form);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e) {
            return AbstractApi.returnErrorResponse(e, 'uploadImage', {locationId, form});
        }
    }
}

export default LocationApi;