import Translator from "../../../../Utils/Translator";
import {LongDescription} from "../Common/Data/Description";
import Label from "../../../Common/Label/Label";
import {LABEL_LOCATION_DESCRIPTION_TITLE} from "../../../../Constants/Labels";
import {backgroundColor, border, classes, padding, round} from "../../../../Theme/Theme";

interface DetailsInterface {
    locationId: string;
}

function Details({locationId}: DetailsInterface) {
    return <div className={classes(backgroundColor("secondary"), padding('xy', '10px'), border(), round())}>
        <div className={`font-bold underline mb-[10px]`}>
            <Label label={Translator.translate(LABEL_LOCATION_DESCRIPTION_TITLE)}/>
        </div>
        <div>
            <Label label={<LongDescription locationId={locationId}/>}/>
        </div>
    </div>;
}

export default Details;