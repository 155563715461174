import {getIcon} from "../../../Helpers/Icon";
import LabelNewLine from "../Label/LabelNewLine";
import {LABEL_LOADING} from "../../../Constants/Labels";

function Loading() {
    return <div className={`flex items-center gap-[10px] font-normal`}>
        {getIcon('ArrowPath', 'small', 'fast')}
        <LabelNewLine label={LABEL_LOADING}/>
    </div>;
}

export default Loading;