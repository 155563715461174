import {ApiManager} from "../../Utils/ApiManager";
import {
    CONFIGURE_PASSWORD_FORM_VALIDATOR_URL,
    CONFIGURE_PASSWORD_URL,
    LOGIN_URL, UPDATE_USER_FORM_VALIDATOR_URL,
    USER_URL
} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class UserApi {
    static async fetchConfigurePasswordValidator() {
        try {
            const response = await ApiManager.get(CONFIGURE_PASSWORD_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchConfigurePasswordValidator');
        }
    }

    static async configurePassword(form: any) {
        try {
            const response = await ApiManager.post(CONFIGURE_PASSWORD_URL, {
                // @ts-ignore
                email: form.fields.email.value,
                // @ts-ignore
                password: form.fields.password.value,
                // @ts-ignore
                temporaryPassword: form.fields.temporaryPassword.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'configurePassword', form);
        }
    }

    static async logIn(form: any) {
        try {
            const response = await ApiManager.post(LOGIN_URL, {
                // @ts-ignore
                loginOrEmail: form.fields.loginOrEmail.value,
                // @ts-ignore
                password: form.fields.password.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'logIn', form);
        }
    }

    static async fetchUser(userId: string) {
        try {
            const response = await ApiManager.get(USER_URL(userId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchUser', {userId});
        }
    };

    static async fetchUpdateUserFormValidator() {
        try {
            const response = await ApiManager.get(UPDATE_USER_FORM_VALIDATOR_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchUpdateUserFormValidator');
        }
    }

    static async updateUser(userId: string, form: any) {
        try {
            const response = await ApiManager.put(USER_URL(userId), {
                // @ts-ignore
                firstname: form.fields.firstname.value,
                // @ts-ignore
                lastname: form.fields.lastname.value,
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'apiUpdateUser', {userId, form});
        }
    }
}

export default UserApi;