import {IsLoggedInUseCase} from "../UseCases/User/IsLoggedInUseCase";

function AccountLogged({children}) {
  const isLoggedInUseCase = IsLoggedInUseCase();

  if (isLoggedInUseCase.execute()) {
    return children;
  }

  return <></>;
}

function AccountUnlogged({children}) {
  const isLoggedInUseCase = IsLoggedInUseCase();

  if (!isLoggedInUseCase.execute()) {
    return children;
  }

  return <></>;
}

export {AccountLogged, AccountUnlogged};