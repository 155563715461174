export class LocalStorage {
    itemIndex = '';

    constructor(itemIndex: string) {
        this.itemIndex = itemIndex;
    }

    save(value: any): void {
        localStorage.setItem(this.itemIndex, JSON.stringify(value));
    }

    get(): any {
        const cache = localStorage.getItem(this.itemIndex);


        return cache === null ? null : JSON.parse(cache);
    }

    remove(): void {
        localStorage.removeItem(this.itemIndex);
    }

    isset(): boolean {
        return localStorage.getItem(this.itemIndex) !== null;
    }
}