import {GetColorUseCase} from "../../../../UseCases/Ticket/GetColorUseCase";

interface SpinningIconInterface {
    ticketIds: string[];
}

function SpinningCircle({ticketIds}: SpinningIconInterface) {
    const getColorUseCase = GetColorUseCase();

    if (ticketIds.length === 0) {
        return null;
    }

    return <div className={`border-${getColorUseCase.execute(ticketIds)} w-[15px] h-[15px] border-dashed border-[2px] p-[5px] rounded-full spinning-circle`}></div>;
}

export default SpinningCircle;