import {API_URL} from "../../Constants/Server";
import {APP_URL} from "../../Constants/Urls";

export const PUBLIC_URL = `${API_URL}/api/public`;
export const TOKEN_REFRESH_URL = `${API_URL}/api/token/refresh`;
export const TRANSLATION_URL = `${API_URL}/api/translations`
export const PUBLIC_LOCATIONS_URL = (keyword?: string) => `${PUBLIC_URL}/locations${keyword === undefined || keyword.length === 0 ? '' : `?keyword=${keyword}`}`
export const LOCATION_COMMENTS_URL = (locationId: string) => `${APP_URL}/locations/${locationId}/comments`
export const PUBLIC_LOCATION_TICKETS_URL = (locationId: string, deviceId: string) => `${PUBLIC_URL}/locations/${locationId}/tickets?deviceId=${deviceId}`;
export const PUBLIC_LOCATION_TICKETS_RUNNING_URL = (locationId: string, deviceId: string) => `${PUBLIC_URL}/locations/${locationId}/tickets/running?deviceId=${deviceId}`;
export const PUBLIC_LOCATION_TICKET_URL = (locationId: string, ticketId: string, deviceId: string) => `${PUBLIC_URL}/locations/${locationId}/tickets/${ticketId}?deviceId=${deviceId}`;
export const PUBLIC_TICKET_URL = (ticketId: string, deviceId: string) => `${PUBLIC_URL}/tickets/${ticketId}?deviceId=${deviceId}`;
export const PUBLIC_TICKET_RUNNING_URL = (ticketId: string, deviceId: string) => `${PUBLIC_URL}/tickets/${ticketId}/running?deviceId=${deviceId}`;
export const TICKET_URL = (ticketId: string, deviceId: string) => `${APP_URL}/tickets/${ticketId}?deviceId=${deviceId}`;
export const LOCATION_TICKETS_URL = (locationId: string, deviceId?: string) => `${APP_URL}/locations/${locationId}/tickets${undefined === deviceId ? '' : `?deviceId=${deviceId}`}`;
export const LOCATION_TICKETS_RUNNING_URL = (locationId: string, deviceId: string) => `${APP_URL}/locations/${locationId}/tickets/running?deviceId=${deviceId}`;
export const LOCATION_TICKET_URL = (locationId: string, ticketId: string, deviceId?: string) => `${APP_URL}/locations/${locationId}/tickets/${ticketId}${undefined === deviceId ? '' : `?deviceId=${deviceId}`}`;
export const PUBLIC_TICKETS_URL = (deviceId: string) => `${PUBLIC_URL}/tickets?deviceId=${deviceId}`;
export const TICKETS_URL = (deviceId: string) => `${APP_URL}/tickets?deviceId=${deviceId}`;
export const TICKETS_RUNNING_URL = (deviceId: string) => `${APP_URL}/tickets/running?deviceId=${deviceId}`;
export const TICKETS_OLD_URL = (deviceId: string) => `${APP_URL}/tickets/old?deviceId=${deviceId}`;
export const PUBLIC_LOCATION_URL = (locationId: string) => `${PUBLIC_URL}/locations/${locationId}`;
export const PUBLIC_LOCATION_COMMENTS_URL = (locationId: string) => `${PUBLIC_URL}/locations/${locationId}/comments`;
export const PUBLIC_COMMENT_URL = (commentId: string) => `${PUBLIC_URL}/comments/${commentId}`;
export const LOCATION_FAVORITES_URL = (locationId: string) => `${APP_URL}/locations/${locationId}/favorites`;
export const FAVORITES_URL = `${APP_URL}/favorites`;
export const PUBLIC_LOCATIONS_LATEST_URL = `${PUBLIC_URL}/locations/latest`;
export const PUBLIC_LOCATIONS_SPECIALTIES_URL = `${PUBLIC_URL}/locations/specialties`;
export const LOCATIONS_GO_AGAIN_URL = `${APP_URL}/locations/go-again`;
export const PUBLIC_LOCATIONS_TOP_URL = `${PUBLIC_URL}/locations/top`;
export const PUBLIC_TICKETS_RUNNING_URL = (deviceId: string) => `${PUBLIC_URL}/tickets/running?deviceId=${deviceId}`;
export const PUBLIC_TICKETS_OLD_URL = (deviceId: string) => `${PUBLIC_URL}/tickets/old?deviceId=${deviceId}`;
export const LOGIN_URL = `${APP_URL}/login`;
export const LOGIN_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/login`;
export const REGISTER_URL = `${APP_URL}/register`;
export const REGISTER_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/register`;
export const LOCATION_URL = (locationId: string) => `${APP_URL}/locations/${locationId}`;
export const LOCATION_OPENINGS_URL = (locationId: string) => `${APP_URL}/locations/${locationId}/openings`;
export const DELETE_LOCATION_OPENING_URL = (locationId: string, locationOpeningId: string) => `${APP_URL}/locations/${locationId}/openings/${locationOpeningId}`;
export const LOCATIONS_URL = (keyword?: string) => `${APP_URL}/locations${keyword === undefined || keyword.length === 0 ? '' : `?keyword=${keyword}`}`;
export const UPDATE_LOCATION_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/update-location`;
export const CREATE_LOCATION_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/create-location`;
export const FAST_LOCATIONS_URL = `${APP_URL}/fast-locations`;
export const LOCATION_IMAGES_URL = (locationId: string) => `${APP_URL}/locations/${locationId}/images`;
export const CONFIGURE_PASSWORD_URL = `${APP_URL}/configure-password`;
export const CONFIGURE_PASSWORD_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/configure-password`;
export const ADMINS_URL = (keyword?: string) => `${APP_URL}/admins${keyword === undefined || keyword.length === 0 ? '' : `?keyword=${keyword}`}`;
export const ADMINS_TO_CONFIGURE_URL = `${APP_URL}/admins/to-configure`;
export const ADMIN_URL = (adminId: string) => `${APP_URL}/admins/${adminId}`;
export const USER_URL = (userId: string) => `${APP_URL}/users/${userId}`;
export const RESET_ALL_URL = `${APP_URL}/reset`;
export const RESET_ALL_ACCESS_URL = `${APP_URL}/access-reset`;
export const CREATE_ADMIN_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/create-admin`;
export const CREATE_COMMENT_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/create-comment`;
export const UPDATE_ADMIN_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/update-admin`;
export const CREATE_OWNER_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/create-owner`;
export const CREATE_TEMPORARY_OWNER_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/temporary-create-owner`;
export const UPDATE_OWNER_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/update-owner`;
export const UPDATE_USER_FORM_VALIDATOR_URL = `${PUBLIC_URL}/form-validators/update-user`;
export const OWNERS_URL = (keyword?: string) => `${APP_URL}/owners${keyword === undefined || keyword.length === 0 ? '' : `?keyword=${keyword}`}`;
export const TEMPORARY_OWNERS_URL = (keyword?: string) => `${APP_URL}/temporary-owners${keyword === undefined || keyword.length === 0 ? '' : `?keyword=${keyword}`}`;
export const OWNER_URL = (ownerId: string) => `${APP_URL}/owners/${ownerId}`;
export const TRANSLATIONS_SAVE_URL = `${APP_URL}/translations`;
