import AbstractApi from "./AbstractApi";
import {ApiManager} from "../../Utils/ApiManager";
import {FAVORITES_URL, LOCATION_FAVORITES_URL} from "../../Constants/ApiUrls";
import {APP_NAME} from "../../../Constants/Constants";

class FavoriteApi{
    static async fetchFavoriteLocations() {
        // @ts-ignore
        if (APP_NAME !== 'client') {
            return AbstractApi.returnSuccessResponse({data: []});
        }

        try {
            const response = await ApiManager.get(FAVORITES_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchFavoriteLocations');
        }
    };

    static async createFavoriteLocation(locationId: string) {
        try {
            const response = await ApiManager.post(LOCATION_FAVORITES_URL(locationId), {});
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'addFavoriteLocation');
        }
    };

    static async deleteFavoriteLocation(locationId: string) {
        try {
            const response = await ApiManager.delete(LOCATION_FAVORITES_URL(locationId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'deleteFavoriteLocation');
        }
    };
}

export default FavoriteApi;