import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";
import Label from "../../../../Common/Label/Label";

interface DescriptionInterface {
    locationId: string;
}

export function LongDescription({locationId}: DescriptionInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location) {
        return null;
    }

    return location.longDescription;
}