import Address from "../Common/Data/Address";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import AdditionalAddress from "../Common/Data/AdditionalAddress";
import ZipCodeCityOneLine from "../Common/Data/ZipCodeCityOneLine";

interface AddressStyledInterface {
    locationId: string;
}

function AddressStyled({locationId}: AddressStyledInterface) {
    return <div>
        <LabelNewLine label={<Address locationId={locationId}/>}/>
        <LabelNewLine label={<AdditionalAddress locationId={locationId}/>}/>
        <LabelNewLine label={<ZipCodeCityOneLine locationId={locationId}/>}/>
    </div>;
}

export default AddressStyled;