import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import LoginForm from "../../Libraries/Components/Domain/User/Form/LoginForm";
import {useEffect} from "react";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendLoginPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function Login() {
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendLoginPageView(getUserIdUseCase.execute());
    }, []);

    return <PageTemplate centeredContent={true}>
        <LoginForm/>
    </PageTemplate>;
}

export default Login;