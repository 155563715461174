import {CURRENT_URL} from "../../Constants/Urls";
import {ADMIN_URL, CLIENT_URL, QUEUBE_URL} from "../../Constants/Server";

export const FRONT__QUEUBE__CONFIGURE_PASSWORD_URL = (email: string, temporaryPassword: string) => `${QUEUBE_URL}/configure-password?email=${email}&temporaryPassword=${temporaryPassword}`;
export const FRONT__OWNER__CONFIGURE_PASSWORD_URL = (email: string, temporaryPassword: string) => `${ADMIN_URL}/configure-password?email=${email}&temporaryPassword=${temporaryPassword}`;
export const FRONT__COMMON__LOCATION_URL = (locationId: string) => `${CURRENT_URL}/locations/${locationId}`;
export const FRONT__COMMON_ADMIN_URL = (adminId: string) => `${CURRENT_URL}/admins/${adminId}`;
export const FRONT__CLIENT_LOCATION_SCAN_URL = (locationId: string) => `${CLIENT_URL}/locations/${locationId}/scanned`;
export const FRONT__CLIENT__REGISTER_URL = `${CLIENT_URL}/register`;
export const FRONT__COMMON__LOGIN_URL = `${CURRENT_URL}/login`;
export const FRONT__COMMON__OWNER_URL = (ownerId: string) => `${CURRENT_URL}/owners/${ownerId}`;