import {APP_NAME} from "../../../../Constants/Constants";
import {Link as ReactRouterLink} from "react-router-dom";
import {ReactElement} from "react";
import {classes, cursor} from "../../../Theme/Theme";

interface LinkInterface {
    to: string;
    className?: string;
    useRefreshMode?: boolean;
    children: number|string|ReactElement|ReactElement[];
}

function Link({to, className, useRefreshMode, children}: LinkInterface) {
    if (useRefreshMode === undefined) {
        useRefreshMode = false;
    }

    if (className === undefined) {
        className = '';
    }

    className += ` ${classes(cursor("pointer"))}`;

    // @ts-ignore
    if (APP_NAME === 'storybook' || useRefreshMode) {
        return <a href={to} className={className}>
            {children}
        </a>;
    }

    return <ReactRouterLink to={to} className={className}>
        {children}
    </ReactRouterLink>;
}

export default Link;