import {createContext, useState} from "react";
import LocaleCache from "../Caches/System/LocaleCache";

export const UPDATE_LOCALE = 'UPDATE_LOCALE';

const defaultContext = {
    locale: new LocaleCache().get(),
    dispatchLocale: (actionType: string, payload?: string): void => {}
};

export interface LocaleContextInterface {
    locale: string;
    dispatchLocale: (actionType: string, payload?: string) => void;
}

export const LocaleContext = createContext<LocaleContextInterface>(defaultContext);
export const LocaleContextProvider = LocaleContext.Provider;

export const LocaleContextHook = () => {
    const cache = new LocaleCache();
    const cacheData = cache.get();

    const [locale, setLocale] = useState(cacheData);

    const dispatchLocale = (actionType: string, payload?: string): void => {
        switch (actionType) {
            case UPDATE_LOCALE:
                if (payload !== undefined) {
                    cache?.save(payload);
                    setLocale(payload);
                }
                break;
        }
    }

    return [locale, dispatchLocale];
}
