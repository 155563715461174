export function getSize(size?: 'auto'|'small'|'medium'|'large'|'full') {
    if (size === undefined) {
        size = 'auto';
    }

    if (size === 'small') {
        return `w-[100px]`;
    }

    if (size === 'medium') {
        return `w-[200px]`;
    }

    if (size === 'large') {
        return `w-[300px]`;
    }

    if (size === 'full') {
        return `w-full`;
    }

    return '';
}

export function getIconSize(size?: 'extra-small'|'small'|'medium'|'large') {
    if (size === undefined) {
        size = 'small';
    }

    if (size === "extra-small") {
        return 'w-[25px]';
    }

    if (size === "small") {
        return 'w-[30px]';
    }

    if (size === 'medium') {
        return 'w-[40px]';
    }

    if (size === 'large') {
        return 'w-[60px]';
    }
}