import {State} from "./State";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    display, flex,
    height, margin,
    position,
    width, zIndex
} from "../../Libraries/Theme/Theme";
import LocaleButton from "../../Libraries/Components/Common/Button/LocaleButton";
import ThemeButton from "../../Libraries/Components/Common/Button/ThemeButton";
import MenuSeparator from "../../Libraries/Components/Common/Menu/MenuSeparator/MenuSeparator";
import MenuLogged from "../../Libraries/Components/Common/Menu/MenuLogged/MenuLogged";
import MenuLinkHome from "../../Libraries/Components/Common/Menu/MenuLink/Links/MenuLinkHome";
import MenuUnlogged from "../../Libraries/Components/Common/Menu/MenuUnlogged/MenuUnlogged";
import MenuLinkFavorites from "../../Libraries/Components/Common/Menu/MenuLink/Links/MenuLinkFavorites";
import MenuLinkTickets from "../../Libraries/Components/Common/Menu/MenuLink/Links/MenuLinkTickets";

function Menu() {
    const {isOpened, toggle} = State();

    return !isOpened ? null : <>
        <div className={classes(position('fixed'), position('relative', '', '', "md"), display('block'), display('none', 'md'), width('100%'), height('100vh'), backgroundColor('modal'), zIndex('500'))} onClick={toggle}></div>
        <div className={classes(border('r'), borderColor(), width('300px'), position('fixed', '0', '0'), height('100vh'), backgroundColor('secondary'), zIndex('550'))}>
            <MenuUnlogged/>
            <MenuLogged/>
            <MenuSeparator/>
            <MenuLinkHome/>
            <MenuLinkFavorites/>
            <MenuLinkTickets/>
            <MenuSeparator/>
            <div className={classes(flex("center", "center", '10px'), margin('t', '10px'))}>
                <LocaleButton alwaysShow={true}/>
                <ThemeButton alwaysShow={true}/>
            </div>
        </div>
    </>;
}

export default Menu;