import {State} from "../../../../../Layout/Menu/State";
import {getIcon} from "../../../../Helpers/Icon";
import {border, borderColor, classes, cursor, round} from "../../../../Theme/Theme";

function MenuOpener() {
    const {toggle} = State();

    return <div className={classes(borderColor(), border(), round(), cursor("pointer"))} onClick={toggle}>
        {getIcon('Bars3', 'medium')}
    </div>;
}

export default MenuOpener;