import {ApiManager} from "./ApiManager";
import {UPDATE_TRANSLATION} from "../Contexts/TranslationContext";
import LocaleCache from "../Caches/System/LocaleCache";
import TranslationCache from "../Caches/System/TranslationCache";
import {ReactElement} from "react";
import {TRANSLATION_URL} from "../Constants/ApiUrls";
import {LABEL_ERROR} from "../Constants/Labels";

interface TranslationDataInterface {
  id: string;
  variables?: any;
}

export default class Translator {
  static translationsLoaded = false;

  // DEPRECATED
  static getTranslation(id: string, variables: any = {}, locale: string|undefined = undefined) {
    const localeToUse = locale === undefined ? new LocaleCache().get() : locale;
    let messages = new TranslationCache().get();
    messages = messages === null || messages === 'null' ? {} : messages;

    if (undefined === messages[id] || undefined === messages[id][localeToUse] || messages[id][localeToUse] === '') {
      console.warn(id);
      return id;
    }

    let currentMessage = messages[id][localeToUse];
    Object.keys(variables).forEach((variableName) => {
      if (typeof variables[variableName] === 'object') {
        currentMessage.message = Translator.replaceJSX(currentMessage.message, `{${variableName}}`, variables[variableName]);
      } else {
        currentMessage.message = currentMessage.message.replace(`{${variableName}}`, variables[variableName]);
      }
    });

    return currentMessage.message;
  }

  static generateTranslationPrefix = (app: string, topic: string) => `${app}__${topic}`;
  static generateTranslationData = (prefix: string, type: string, description: string, variables?: any) => {
    return {
      id: `${prefix}__${type}__${description}`,
      variables,
    };
  }

  static translate({id, variables}: TranslationDataInterface, locale: string|undefined = undefined) {
    variables = variables ?? {};

    const localeToUse = locale === undefined ? new LocaleCache().get() : locale;
    let messages = new TranslationCache().get();
    messages = messages === null || messages === 'null' ? {} : messages;

    if (undefined === messages[id] || undefined === messages[id][localeToUse] || messages[id][localeToUse] === '') {
      console.warn(id);
      return id;
    }

    let currentMessage = messages[id][localeToUse];
    Object.keys(variables).forEach((variableName) => {
      if (typeof variables[variableName] === 'object') {
        currentMessage.message = Translator.replaceJSX(currentMessage.message, `{${variableName}}`, variables[variableName]);
      } else {
        currentMessage.message = currentMessage.message.replace(`{${variableName}}`, variables[variableName]);
      }
    });

    return currentMessage.message;
  }

  static replaceJSX(message: string, variable: string, value: ReactElement){
    let str = message.split(variable).flatMap((item) => [item, value]);
    str.pop();

    return str;
  }

  static loadTranslation(dispatch: any) {
    try {
      if (Translator.translationsLoaded === false) {
        (async function() {
          const response = await ApiManager.get(TRANSLATION_URL);
          dispatch(UPDATE_TRANSLATION, response.data);
        })();
      }
      Translator.translationsLoaded = true;
    } catch(e) {
      console.error('Error on loading translations', e);
    }
  }

  static getApiErrorResponseTranslation(translationPrefix: string, e: any) {
    if (typeof e === 'object') {
      return 'libraries__constants__label__generic';
    }

    return `libraries__constants__label__${e}`;
  }
}