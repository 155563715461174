import {useContext, useEffect} from "react";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import Translator from "../../Libraries/Utils/Translator";
import TileLabel from "../../Libraries/Components/Common/Label/TileLabel";
import FavoriteCircle from "../../Libraries/Components/Domain/Location/Favorite/FavoriteCircle";
import Card from "../../Libraries/Components/Domain/Location/Card/Card";
import Tile from "../../Libraries/Components/Domain/Ticket/Tile/Tile";
import {TopSpecialtiesLoader} from "../../Libraries/Api/Loaders/ValueLoaders/TopSpecialtiesLoader";
import {TopLocationsLoader} from "../../Libraries/Api/Loaders/ValueLoaders/TopLocationsLoader";
import {ToGoAgainLocationsLoader} from "../../Libraries/Api/Loaders/ValueLoaders/ToGoAgainLocationsLoader";
import {LatestLocationsLoader} from "../../Libraries/Api/Loaders/ValueLoaders/LatestLocationsLoader";
import {TicketsRunningLoader} from "../../Libraries/Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {FavoriteLocationsLoader} from "../../Libraries/Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import {classes, width} from "../../Libraries/Theme/Theme";
import {LocaleContext, UPDATE_LOCALE} from "../../Libraries/Contexts/LocaleContext";
import UseQuery from "../../Libraries/Hooks/Query/UseQuery";
import {locales} from "../../Libraries/Constants/Constants";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendHomepagePageView} from "../../Libraries/GoogleAnalytics/PageViews";

const TRANSLATION_PREFIX = 'client__homepage__';

function Homepage() {
    const {fetch: fetchFavoriteLocations, favoriteLocations} = FavoriteLocationsLoader();
    const {fetch: fetchTopSpecialties, topSpecialties} = TopSpecialtiesLoader();
    const {fetch: fetchTopLocations, topLocations} = TopLocationsLoader();
    const {fetch: fetchToGoAgainLocations, toGoAgainLocations} = ToGoAgainLocationsLoader();
    const {fetch: fetchLatestLocations, latestLocations} = LatestLocationsLoader();
    const {fetch: fetchTicketsRunning, ticketsRunning} = TicketsRunningLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        fetchFavoriteLocations();
        fetchTopLocations();
        fetchToGoAgainLocations();
        fetchLatestLocations();
        fetchTopSpecialties();
        fetchTicketsRunning();

        sendHomepagePageView(getUserIdUseCase.execute())
    }, []);

    const localeContext = useContext(LocaleContext);

    const lang = UseQuery().get('lang');
    if (lang !== null) {
        if (Object.keys(locales).includes(lang)) {
            localeContext.dispatchLocale(UPDATE_LOCALE, locales[lang].value);
        }
    }

    return <PageTemplate>
        <div className={classes(width('100%'))}>
            {topSpecialties?.data.length === 0 ? null : <>
                <div className={`flex gap-[10px] flex-column overflow-x-auto whitespace-nowrap py-[10px] custom-scrollbar`}>
                    {topSpecialties?.data.map((specialtyId: string, index: number) => <TileLabel link={`/specialties?keyword=${encodeURI('lala')}`} key={`specialty-${index}`} label={'lala'}/>)}
                </div>
            </>}
            {favoriteLocations !== undefined && favoriteLocations.data.length > 0 && <>
                <div className={`mt-[20px] mb-[20px] text-[20px] font-bold`}>
                    {Translator.getTranslation(`${TRANSLATION_PREFIX}label__favorites`)}
                </div>
                <div className={`flex gap-[10px] flex-column overflow-x-auto whitespace-nowrap custom-scrollbar pb-[10px]`}>
                    {favoriteLocations.data.map((locationId: string, index: number) => <FavoriteCircle key={`shortcut-${index}`} locationId={locationId}/>)}
                </div>
            </>}
            {ticketsRunning?.data && ticketsRunning.data.length > 0 ? <>
                <div className={`mt-[20px] mb-[20px] text-[20px] font-bold`}>
                    {Translator.getTranslation(`${TRANSLATION_PREFIX}label__tickets_in_progress`)}
                </div>
                <div className={`flex gap-[10px] flex-column overflow-x-auto whitespace-nowrap custom-scrollbar pb-[10px]`}>
                    {ticketsRunning?.data.map((ticketId: string, index: number) => <Tile key={`ticket-${index}`} ticketId={ticketId}/>)}
                </div>
            </> : null}
            {toGoAgainLocations !== undefined && toGoAgainLocations.data.length > 0 && <>
                <div className={`mt-[20px] mb-[20px] text-[20px] font-bold`}>
                    {Translator.getTranslation(`${TRANSLATION_PREFIX}label__go_again`)}
                </div>
                <div className={`flex gap-[10px] flex-column overflow-x-auto whitespace-nowrap custom-scrollbar pb-[10px]`}>
                    {toGoAgainLocations?.data.map((locationId: string, index: number) => <Card key={`location-to-go-again-${index}`} locationId={locationId}/>)}
                </div>
            </>}
            {topLocations !== undefined && topLocations.data.length > 0 && <>
                <div className={`mt-[20px] mb-[20px] text-[20px] font-bold`}>
                    {Translator.getTranslation(`${TRANSLATION_PREFIX}top_restaurants`)}
                </div>
                <div className={`flex gap-[10px] flex-column overflow-x-auto whitespace-nowrap custom-scrollbar pb-[10px]`}>
                    {topLocations?.data.map((locationId: string, index: number) => <Card locationId={locationId} key={`top-location-card-${index}`}/>)}
                </div>
            </>}
            {latestLocations !== undefined && latestLocations.data.length > 0 &&  <>
                <div className={`mt-[20px] mb-[20px] text-[20px] font-bold`}>
                    {Translator.getTranslation(`${TRANSLATION_PREFIX}latest_selection`)}
                </div>
                <div className={`flex gap-[10px] flex-column overflow-x-auto whitespace-nowrap custom-scrollbar pb-[10px]`}>
                    {latestLocations?.data.map((locationId: string, index: number) => <Card locationId={locationId} key={`latest-selection-location-card-${index}`}/>)}
                </div>
            </>}
        </div>
    </PageTemplate>;
}

export default Homepage;