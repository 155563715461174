import {useEffect, useState} from "react";
import AbstractValueLoader, {
    ValueLoaderObjectInterface,
} from "./AbstractValueLoader";
import {useBetween} from "use-between";
import {CACHE_TIME_THREE_SECONDS} from "../../../Constants";
import {GetDeviceIdUseCase} from "../../../UseCases/System/GetDeviceIdUseCase";
import {IsLoggedInUseCase} from "../../../UseCases/User/IsLoggedInUseCase";
import TicketApi from "../../Calls/TicketApi";

const cache = new AbstractValueLoader(CACHE_TIME_THREE_SECONDS);

function OldTickets() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const getDeviceIdUseCase = GetDeviceIdUseCase();

    const [oldTickets, setOldTickets] = useState<ValueLoaderObjectInterface|undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);
    const [refreshData, setRefreshData] = useState<any|undefined>(undefined);
    const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);

    const apiCall = async () => {
        return TicketApi.fetchOldTickets(getDeviceIdUseCase.execute(), isLoggedInUseCase.execute());
    };

    const fetch = (forceRefresh: boolean = false) => {
        cache.load(apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
        setRefreshData(() => ({
            timer: 5000,
        }));
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                // @ts-ignore
                setOldTickets(() => ({...cache.get()}));
                setIsLoading(false);

                const cachedData = cache.get()?.data;
                if (cachedData !== undefined && cachedData.length > 0) {
                    setShouldRefresh(() => true);
                }
            }
        }
    }, [isLoading]);

    useEffect(() => {
        if (shouldRefresh) {
            setShouldRefresh(() => false);
            const timer = setTimeout(() => {
                fetch(true);
            }, refreshData.timer);
            return () => clearTimeout(timer);
        }
    }, [shouldRefresh]);

    return {fetch, oldTickets, setRefreshData, setShouldRefresh};
}

export const OldTicketsLoader = () => useBetween(OldTickets);