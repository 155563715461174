import {State as QrCodeModalState} from "../QrCodeModal/State";
import {ReactComponent as QrCodeIcon} from "../../../../Icons/QrCode.svg";

function QrCodeToggler() {
    const {toggle: toggleQrCode} = QrCodeModalState();

    return <div onClick={toggleQrCode}>
        <QrCodeIcon width={`20px`}/>
    </div>;
}

export default QrCodeToggler;