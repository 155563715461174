import Modal from "./Modal";
import LocaleModalState from "./LocaleModalState";
import {boldText, border, classes, flex, margin, padding, textAlign, textSize} from "../../../Theme/Theme";
import LabelNewLine from "../Label/LabelNewLine";
import {
    LABEL_CHOOSE_LANGUAGE,
    LABEL_LOCALE_MODAL_CHOOSE_THEME,
    LABEL_LOCALE_MODAL_WELCOME_TITLE
} from "../../../Constants/Labels";
import {locales} from "../../../Constants/Constants";
import Button from "../Button/Button";
import {LocaleContext, UPDATE_LOCALE} from "../../../Contexts/LocaleContext";
import {useContext, useEffect, useState} from "react";
import LocaleCache from "../../../Caches/System/LocaleCache";
import useQuery from "../../../Hooks/Query/UseQuery";
import {ThemeDarkButton, ThemeLightButton} from "../Button/ThemeButton";
import {GetUserIdUseCase} from "../../../UseCases/User/GetUserIdUseCase";
import {sendSwitchLocaleEvent} from "../../../GoogleAnalytics/Events";

function LocaleModal() {
    const {opened, toggle, open} = LocaleModalState();
    const localeContext = useContext(LocaleContext);
    const localeCache = new LocaleCache();
    const [isWelcome, setIsWelcome] = useState(!localeCache.isset())
    const getUserIdUseCase = GetUserIdUseCase();
    const locale = useQuery().get('lang');

    let onClickModalBackground = !isWelcome ? toggle : undefined;
    let onClickCloseModalButton = isWelcome ? () => {selectDefaultLocale(); toggle(); setIsWelcome(() => false);} : undefined;

    useEffect(() => {
        if (!localeCache.isset()) {
            open();
        }
    }, []);

    const selectDefaultLocale = (locale?: string, refresh: boolean = false) => {
        if (locale === undefined && !localeCache.isset() || locale !== undefined) {
            localeContext.dispatchLocale(UPDATE_LOCALE, locales[locale ?? 'en'].value);
        }

        if (refresh) {
            // @ts-ignore
            sendSwitchLocaleEvent(getUserIdUseCase.execute(), 'auto', locale);
            setTimeout(() => {
                document.location.href = '/';
            }, 100);
        } else {
            // @ts-ignore
            sendSwitchLocaleEvent(getUserIdUseCase.execute(), 'click', locale);
        }
    }

    if (locale !== null) {
        selectDefaultLocale(locale, true);
    }

    return <Modal opened={opened} onClickModalBackground={onClickModalBackground} onClickCloseModalButton={onClickCloseModalButton}>
        <>
            {isWelcome && <div className={classes(border('b'), padding('b', '10px'))}>
                <LabelNewLine className={classes(margin('l', '40px'), boldText(), textAlign("center"), textSize('30px'))} label={'Queube'}/>
                <LabelNewLine className={classes(boldText(), textAlign("center"))} label={LABEL_LOCALE_MODAL_WELCOME_TITLE}/>
            </div>}
            <div className={classes(padding('t', '10px'), padding('x', '10px'))}>
                <LabelNewLine className={classes(boldText())} label={LABEL_CHOOSE_LANGUAGE}/>
                <div className={classes(margin('b', '10px'), margin('t', '10px'))}>
                    {Object.keys(locales).map((locale: string, index: number) => <Button key={`locale-${locale}`} color={'primary'} size={"full"} onClick={() => selectDefaultLocale(locale)} label={locales[locale].label} selected={localeContext.locale === locales[locale].value} className={classes(margin('t', index > 0 ? '10px' : '0'))}/>)}
                </div>
            </div>
            {isWelcome && <div className={classes(padding('y', '10px'), margin('b', '10px'), border('t'), margin('t', '20px'))}>
                <LabelNewLine className={classes(boldText(), padding('l', '10px'))} label={LABEL_LOCALE_MODAL_CHOOSE_THEME}/>
                <div className={classes(flex("center", "center", '10px'), margin('t', '10px'))}>
                    <ThemeLightButton/>
                    <ThemeDarkButton/>
                </div>
            </div>}
        </>
    </Modal>;
}

export default LocaleModal;