import {useState} from "react";
import {validateInput} from "../../Utils/FormUtils";

const TRANSLATION_PREFIX = 'libraries__hooks_form_form_hook__';

interface FormInterface {
    isLoading: boolean;
    error: string;
    success: string;
    fields: {
        [name: string]: {
            focus: boolean;
            value: string|boolean|number|null;
            constrains: FormFieldConstrains;
            errors: string[];
            success: string[];
        };
    };
    displayBorders: boolean;
}

interface FormValuesInterface {
    [name: string]: string|number|boolean|null;
}

interface FormFieldConstrains {
    [fieldname: string]: {
        [constrainName: string]: string;
    };
}

interface FormInputConstrainsList {
    [inputName: string]: FormFieldConstrains;
}

interface FormHookInterface {
    form: FormInterface;
    setForm: (newState: any) => void;
    handleChange: (e: any) => void;
    handleChangeCheckbox: (e: any) => void;
    handleFocus: (e: any) => void;
    handleBlur: (e: any) => void;
    shouldSubmit: () => boolean;
    setConstrains: (constrains: FormInputConstrainsList, validateInputs?: boolean) => void;
    releaseFormLoading: (newState: {error?: string|undefined, success?: string|undefined}) => void;
    setValues: (values: FormValuesInterface) => void;
    resetSuccessError: () => void;
}

export const FormHook = (fields: string[], values: FormValuesInterface = {}): FormHookInterface => {
    const defaultState: FormInterface = {
        isLoading: false,
        error: '',
        success: '',
        fields: {},
        displayBorders: false
    };

    fields.forEach((fieldName: string) => {
        defaultState.fields[fieldName] = {
            value: values[fieldName] ?? '',
            constrains: {},
            errors: [],
            success: [],
            focus: false
        }
    });

    const [form, setForm] = useState<FormInterface>(defaultState);

    const handleChange = (e: any) => {
        const {errors, success} = validateInput(e.target.value, form.fields[e.target.name].constrains);

        let newFormState = {...form};
        newFormState.fields[e.target.name].value = e.target.value;
        newFormState.fields[e.target.name].errors = errors;
        newFormState.fields[e.target.name].success = success;
        newFormState.success = '';
        newFormState.error = '';

        setForm(newFormState);
    };

    const handleChangeCheckbox = (e: any) => {
        let newFormState = {...form};
        newFormState.fields[e.target.name].value = !newFormState.fields[e.target.name].value;
        newFormState.fields[e.target.name].errors = [];
        newFormState.fields[e.target.name].success = [];
        newFormState.success = '';
        newFormState.error = '';

        setForm(newFormState);
    };

    const handleFocus = (e: any) => {
        let newFormState = {...form};
        newFormState.fields[e.target.name].focus = true;

        setForm(newFormState);
    }

    const handleBlur = (e: any) => {
        let newFormState = {...form};
        newFormState.fields[e.target.name].focus = false;

        setForm(newFormState);
    }

    const shouldSubmit = () => {
        if (form.isLoading) {
            setForm(() => {
                return {
                    ...form,
                    success: '',
                    error: ''
                };
            });
            console.warn('ici');
            return false;
        }

        let hasFormError = false;
        let newFormState = {...form};

        Object.keys(form.fields).map((fieldName: string) => {
            const {errors, success} = validateInput(form.fields[fieldName].value, form.fields[fieldName].constrains);
            newFormState.fields[fieldName].errors = errors;
            newFormState.fields[fieldName].success = success;
            if (errors.length > 0) {
                hasFormError = true;
            }
        });

        if (hasFormError) {
            setForm(() => {
                return {
                    ...newFormState,
                    success: '',
                    error: `check_inputs`
                };
            });
            console.warn('ici2');
            return false;
        }

        setForm(() => {
            return {
                ...newFormState,
                isLoading: true,
                error: '',
                success: ''
            }
        });

        return true;
    };

    const setConstrains = (constrains: FormInputConstrainsList, validateInputs: boolean|null = false) => {
        const newState: FormInterface = {...form};

        Object.keys(constrains).map((fieldName: string) => {
            if (form.fields[fieldName] !== undefined) {
                const fieldConstrains: FormFieldConstrains = constrains[fieldName];

                if (validateInputs) {
                    const {errors, success} = validateInput(form.fields[fieldName].value, fieldConstrains);
                    newState.fields[fieldName].errors = errors;
                    newState.fields[fieldName].success = success;
                }

                newState.fields[fieldName].constrains = fieldConstrains;
            }
        });

        setForm(() => newState);
    };

    const setValues = (values: FormValuesInterface) => {
        const newState: FormInterface = {...form};

        Object.keys(values).map((fieldName: string) => {
            if (form.fields[fieldName] !== undefined) {
                const fieldValue = values[fieldName];

                if (values) {
                    newState.fields[fieldName].value = fieldValue;
                    newState.fields[fieldName].errors = [];
                    newState.fields[fieldName].success = [];
                }
            }
        });

        setForm(() => newState);
    };

    const releaseFormLoading = ({error, success}: any) => {
        setForm(() => {
            return {
                ...form,
                isLoading: false,
                error: error ?? '',
                success: success ?? ''
            }
        });
    };

    const resetSuccessError = () => {
        setForm(() => ({
            ...form,
            success: '',
            error: '',
        }));
    }

    return {resetSuccessError, form, setForm, handleChange, handleFocus, handleBlur, shouldSubmit, setConstrains, releaseFormLoading, setValues, handleChangeCheckbox};
};
