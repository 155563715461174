import {useBetween} from "use-between";
import {FormHook} from "../../Hooks/Form/FormHook";
import {useEffect, useState} from "react";
import {UsersLoader} from "../../Api/Loaders/IndexedLoaders/UsersLoader";
import UserApi from "../../Api/Calls/UserApi";
import AbstractApi from "../../Api/Calls/AbstractApi";

const TRANSLATION_PREFIX = 'libraries__use_cases_owner_update_owner_use_case__';

function UpdateUser() {
    const [userId, setUserId] = useState<string|undefined>();
    const {form, handleChange, handleBlur, handleFocus, shouldSubmit, setForm, setValues, setConstrains} = FormHook(['firstname', 'lastname']);
    const {fetch: fetchUsers, users, getUserById} = UsersLoader();

    useEffect(() => {
        (async function() {
            const response = await UserApi.fetchUpdateUserFormValidator();
            if (AbstractApi.isSuccess(response)) {
                setConstrains(response.data);
            }
        })();
    }, []);

    useEffect(() => {
        if (undefined !== userId) {
            fetchUsers([userId]);
        }
    }, [userId]);

    useEffect(() => {
        if (userId !== undefined) {
            const user = getUserById(userId);
            if (user !== undefined) {
                setValues({
                    ...user
                });
            }
        }
    }, [users]);

    const execute = async (e: any, id: string) => {
        e.preventDefault();
        if (!shouldSubmit()) {
            return;
        }

        setForm({
            ...form,
            isLoading: true,
            error: '',
            success: '',
        })

        const response = await UserApi.updateUser(id, form);
        if (AbstractApi.isSuccess(response)) {
            setForm(() => ({
                ...form,
                isLoading: false,
                success: `${TRANSLATION_PREFIX}label__success`,
                error: ``
            }));
            if (userId !== undefined) {
                fetchUsers([userId], true);
            }
        } else {setForm(() => ({
                ...form,
                isLoading: false,
                success: ``,
                error: `${TRANSLATION_PREFIX}label__error`
            }));
        }
    };

    return {setUserId, form, handleChange, handleBlur, handleFocus, execute, setValues, setForm, setConstrains};
}

export const UpdateUserUseCase = () => useBetween(UpdateUser);