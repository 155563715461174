import {useEffect, useState} from "react";
import AbstractValueLoader, {
    ValueLoaderObjectInterface,
} from "./AbstractValueLoader";
import {useBetween} from "use-between";
import SpecialtiesApi from "../../Calls/SpecialtiesApi";

const cache = new AbstractValueLoader();

function TopSpecialties() {
    const [topSpecialties, setTopSpecialties] = useState<ValueLoaderObjectInterface|undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = async () => {
        return SpecialtiesApi.fetchSpecialties();
    };

    const fetch = () => {
        cache.load(apiCall);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                // @ts-ignore
                const cachedData = {...cache.get()};
                // @ts-ignore
                setTopSpecialties(() => ({
                    ...cachedData,
                    // @ts-ignore
                    data: cachedData.data.filter((specialtyId: string, index: number) => cachedData.data.indexOf(specialtyId) === index)
                }));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    return {fetch, topSpecialties};
}

export const TopSpecialtiesLoader = () => useBetween(TopSpecialties);