import {LocalStorage} from "../LocalStorage";
import {useState} from "react";
import {useBetween} from "use-between";

export default class ThemeStorage extends LocalStorage {
    constructor() {
        super('theme');
    }

    save(value: string): void {super.save(value)};
    get(): 'light'|'dark' {const theme = super.get(); return theme === null ? 'light' : theme;};
}

function ThemeState() {
    const cachedData = new ThemeStorage().get();
    const [theme, setTheme] = useState<'light'|'dark'>(cachedData === null || cachedData === undefined ? 'light' : cachedData);

    const save = (value: 'light'|'dark') => {
        setTheme(() => value);
        new ThemeStorage().save(value);
    }

    const remove = () => {
        setTheme(() => 'light');
        new ThemeStorage().remove();
    }

    return {theme, save, remove};
}

export const ThemeCache = () => useBetween(ThemeState);