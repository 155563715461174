import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_EMAIL} from "../../../../../../Constants/Labels";

interface InputEmailInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function InputEmail({form, handleChange, handleBlur, handleFocus, className}: InputEmailInterface) {
    return <Input inputData={form.fields.email}
        name={'email'}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        displayBorders={true}
        placeholder={Translator.translate(LABEL_EMAIL)}
        className={className ?? ''}
    />;
}

export default InputEmail;