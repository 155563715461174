import {ReactElement} from "react";
import {
    backgroundColor, border,
    classes,
    cursor,
    display,
    float,
    height,
    position,
    round, textColor, top,
    width,
    zIndex
} from "../../../Theme/Theme";
import {getIcon} from "../../../Helpers/Icon";

interface props {
    children: ReactElement|string;
    opened: boolean;
    onClickModalBackground?: () => void;
    onClickCloseModalButton?: () => void;
}

function Modal({children, opened, onClickModalBackground, onClickCloseModalButton}: props) {
    if (onClickModalBackground === undefined) {
        onClickModalBackground = () => {};
    }

    let showCloseModalButton = true;
    if (onClickCloseModalButton === undefined) {
        onClickCloseModalButton = () => {};
        showCloseModalButton = false;
    }

    return opened ? <>
        <div className={classes(position('fixed', '0', '0'), display('block'), width('100%'), height('100vh'), backgroundColor('modal'), zIndex('1000'))} onClick={onClickModalBackground}></div>
        <div className={classes(position('fixed', 'center', 'center'), display('block'), width('300px', 'min'), width('100%', "max"), width('80%', 'max', 'sm'), height('100px', 'min'), height('80%', 'max'), backgroundColor('secondary'), round(), zIndex('1050'), border())}>
            {showCloseModalButton && <div onClick={onClickCloseModalButton} className={classes(cursor('pointer'), float('r'), textColor())}>{getIcon('XMark', 'medium')}</div>}
            {children}
        </div>
    </> : null;
}

export default Modal;