export function getTextSize(textSize?: 'auto'|'extra-small'|'small'|'medium'|'large'|'extra-large'|'huge') {
    if (textSize === undefined) {
        textSize = 'auto';
    }

    if (textSize === 'extra-small') {
        return `text-[8px]`;
    }

    if (textSize === 'small') {
        return `text-[10px]`;
    }

    if (textSize === 'medium' || textSize === 'auto') {
        return `text-[15px]`;
    }

    if (textSize === 'large') {
        return `text-[20px]`;
    }

    if (textSize === 'extra-large') {
        return `text-[30px]`;
    }

    if (textSize === 'huge') {
        return `text-[50px]`;
    }
}

export function getBoldText(boldtext?: boolean) {
    if (boldtext === undefined) {
        boldtext = false;
    }

    if (boldtext) {
        return 'font-bold';
    }

    return '';
}

export function getTextCenter(textCenter?: 'default'|'x'|'y'|'xy') {
    if (textCenter === 'x') {
        return 'flex justify-center';
    }

    if (textCenter === 'y') {
        return 'flex items-center';
    }

    if (textCenter === 'xy') {
        return 'flex justify-center items-center';
    }

    return '';
}

export function getTextUnderline(underline?: boolean) {
    if (underline === undefined || !underline) {
        return '';
    }

    return 'underline';
}

export function getTextItalic(italic?: boolean) {
    if (italic === undefined || !italic) {
        return '';
    }

    return 'italic';
}

export function getTextPosition(textPosition?: 'r'|'default'|'l'|'auto') {
    if (textPosition === undefined) {
        textPosition = 'default';
    }

    if (textPosition === 'l' || textPosition === 'default') {
        return 'text-left';
    }

    if (textPosition === 'r') {
        return 'text-right';
    }

    if (textPosition === 'auto') {
        return 'text-center';
    }

    return '';
}