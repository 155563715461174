class Dark {
    static defaultTextColor = 'text-white';
    static ternaryTextColor = 'text-gray-900';

    static defaultBorderColor = 'border-white';
    static ternaryBorderColor = 'border-gray-900';

    static defaultBackgroundColor = 'bg-gray-900';
    static secondaryBackgroundColor = 'bg-gray-700';
    static ternaryBackgroundColor = 'bg-yellow-500';
    static modalBackgroundColor = 'bg-slate-200 backdrop-opacity-5 opacity-50';

    static defaultSelectedBackgroundColor = 'bg-gray-700';
    static secondarySelectedBackgroundColor = 'bg-gray-800';
    static ternarySelectedBackgroundColor = 'bg-yellow-700';

    static defaultHoverBackgroundColor = 'hover:bg-gray-700';
    static secondaryHoverBackgroundColor = 'hover:bg-gray-800';
    static ternaryHoverBackgroundColor = 'hover:bg-yellow-700';
}

class Light {
    static defaultTextColor = 'text-black';
    static ternaryTextColor = 'text-slate-100';

    static defaultBorderColor = 'border-black';
    static ternaryBorderColor = 'border-slate-100';

    static defaultBackgroundColor = 'bg-slate-100';
    static secondaryBackgroundColor = 'bg-white';
    static ternaryBackgroundColor = 'bg-slate-700';
    static modalBackgroundColor = 'bg-slate-200 backdrop-opacity-5 opacity-50';

    static defaultSelectedBackgroundColor = 'bg-slate-300';
    static secondarySelectedBackgroundColor = 'bg-slate-100';
    static ternarySelectedBackgroundColor = 'bg-slate-900';

    static defaultHoverBackgroundColor = 'hover:bg-slate-300';
    static secondaryHoverBackgroundColor = 'hover:bg-slate-100';
    static ternaryHoverBackgroundColor = 'hover:bg-slate-900';
}

class Theme {
    static theme = Light;
}

export function syncTheme(theme: 'light'|'dark') {
    switch(theme) {
        case 'light':
            Theme.theme = Light;
            break;
        case 'dark':
            Theme.theme = Dark;
            break;
    }

    return Theme;
}

export function textColor(color: 'primary'|'secondary'|'ternary'|'black' = 'primary') {
    switch (color) {
        case 'ternary': return Theme.theme.ternaryTextColor;
        case 'black': return 'text-black';
    }

    return Theme.theme.defaultTextColor;
}

export function textSize(size: '10px'|'15px'|'18px'|'20px'|'30px'|'50px' = '15px') {
    //normal 15
    //large 20
    //xlarge 30
    //xxlarge 50
    const classes: {
        [size: string] : string;
    } = {
        '10px': 'text-[10px]',
        '15px': 'text-[15px]',
        '18px': 'text-[18px]',
        '20px': 'text-[20px]',
        '30px': 'text-[30px]',
        '50px': 'text-[50px]',
    }

    return classes[size] ?? '';
}

export function textAlign(align: 'center'|'right'|'left' = 'left') {
    switch (align) {
        case 'center': return 'text-center';
        case 'left': return 'text-left';
        case 'right': return 'text-right';
    }

    return '';
}

export function borderColor(color: 'primary'|'secondary'|'ternary'|'error' = 'primary') {
    switch (color) {
        case 'ternary': return Theme.theme.ternaryBorderColor;
        case 'error': return 'border-red-500'
    }

    return Theme.theme.defaultBorderColor;
}

export function backgroundColor(type: 'primary'|'secondary'|'ternary'|'modal' = 'primary', selectable: boolean = false, selected: boolean = false) {
    let style = [];

    if (!selected) {
        switch (type) {
            case 'primary': style.push(Theme.theme.defaultBackgroundColor); break;
            case 'secondary': style.push(Theme.theme.secondaryBackgroundColor); break;
            case 'ternary': style.push(Theme.theme.ternaryBackgroundColor); break;
        }
    } else {
        switch (type) {
            case 'primary': style.push(Theme.theme.defaultSelectedBackgroundColor); break;
            case 'secondary': style.push(Theme.theme.secondarySelectedBackgroundColor); break;
            case 'ternary': style.push(Theme.theme.ternarySelectedBackgroundColor); break;
        }
    }

    if (selectable) {
        switch (type) {
            case 'primary': style.push(Theme.theme.defaultHoverBackgroundColor, cursor('pointer')); break;
            case 'secondary': style.push(Theme.theme.secondaryHoverBackgroundColor, cursor('pointer')); break;
            case 'ternary': style.push(Theme.theme.ternaryHoverBackgroundColor, cursor('pointer')); break;
        }
    }

    if (type === 'modal') {
        style.push(Theme.theme.modalBackgroundColor);
    }

    return style.join(' ');
}

export function border(direction: 'b'|'r'|'t'|'xy'|'l' = 'xy', size: '1px' = '1px') {
    const classes: {
        [direction: string]: {
            [size: string]: string,
        }
    } = {
        b: {
            '1px': 'border-b-[1px]',
        },
        r: {
            '1px': 'border-r-[1px]',
        },
        t: {
            '1px': 'border-t-[1px]',
        },
        l: {
            '1px': 'border-l-[1px]',
        },
        xy: {
            '1px': 'border-[1px]',
        },
    };

    return classes[direction][size] ?? '';
}

export function overflow(type: 'hidden'|'' = '') {
    const classes: {
        [type: string]: string;
    } = {
        '': '',
        hidden: 'overflow-hidden',
    }

    return classes[type] ?? '';
}

export function padding(direction: 'xy'|'l'|'x'|'y'|'t'|'b'|'r' = 'xy', pixels: '10px'|'8px'|'5px'|'0'|'20px'|'30px'|'100px' = '0', device: '' = '', important: boolean = false) {
    const classes: {
        [direction: string]: {
            [pixels: string]: {
                [device: string]: string;
            }
        }
    } = {
        xy: {
            '0': {
                '': important ? '!p-0' : 'p-0',
            },
            '5px': {
                '': important ? '!p-[5px]' : 'p-[5px]',
            },
            '8px': {
                '': important ? '!p-[8px]' : 'p-[8px]',
            },
            '10px': {
                '': important ? '!p-[10px]' : 'p-[10px]',
            },
            '20px': {
                '': important ? '!p-[20px]' : 'p-[20px]',
            },
            '30px': {
                '': important ? '!p-[30px]' : 'p-[30px]',
            },
            '100px': {
                '': important ? '!p-[100px]' : 'p-[100px]',
            },
        },
        x: {
            '0': {
                '': important ? '!px-0' : 'px-0',
            },
            '5px': {
                '': important ? '!px-[5px]' : 'px-[5px]',
            },
            '8px': {
                '': important ? '!px-[8px]' : 'px-[8px]',
            },
            '10px': {
                '': important ? '!px-[10px]' : 'px-[10px]',
            },
            '20px': {
                '': important ? '!px-[20px]' : 'px-[20px]',
            },
            '30px': {
                '': important ? '!px-[30px]' : 'px-[30px]',
            },
            '100px': {
                '': important ? '!px-[100px]' : 'px-[100px]',
            },
        },
        y: {
            '0': {
                '': important ? '!py-0' : 'py-0',
            },
            '5px': {
                '': important ? '!py-[5px]' : 'py-[5px]',
            },
            '8px': {
                '': important ? '!py-[8px]' : 'py-[8px]',
            },
            '10px': {
                '': important ? '!py-[10px]' : 'py-[10px]',
            },
            '20px': {
                '': important ? '!py-[20px]' : 'py-[20px]',
            },
            '30px': {
                '': important ? '!py-[30px]' : 'py-[30px]',
            },
            '100px': {
                '': important ? '!py-[100px]' : 'py-[100px]',
            },
        },
        t: {
            '0': {
                '': important ? '!pt-0' : 'pt-0',
            },
            '5px': {
                '': important ? '!pt-[5px]' : 'pt-[5px]',
            },
            '8px': {
                '': important ? '!pt-[8px]' : 'pt-[8px]',
            },
            '10px': {
                '': important ? '!pt-[10px]' : 'pt-[10px]',
            },
            '20px': {
                '': important ? '!pt-[20px]' : 'pt-[20px]',
            },
            '30px': {
                '': important ? '!pt-[30px]' : 'pt-[30px]',
            },
            '100px': {
                '': important ? '!pt-[100px]' : 'pt-[100px]',
            },
        },
        r: {
            '0': {
                '': important ? '!pr-0' : 'pr-0',
            },
            '5px': {
                '': important ? '!pr-[5px]' : 'pr-[5px]',
            },
            '8px': {
                '': important ? '!pr-[8px]' : 'pr-[8px]',
            },
            '10px': {
                '': important ? '!pr-[10px]' : 'pr-[10px]',
            },
            '20px': {
                '': important ? '!pr-[20px]' : 'pr-[20px]',
            },
            '30px': {
                '': important ? '!pr-[30px]' : 'pr-[30px]',
            },
            '100px': {
                '': important ? '!pr-[100px]' : 'pr-[100px]',
            },
        },
        b: {
            '0': {
                '': important ? '!pb-0' : 'pb-0',
            },
            '5px': {
                '': important ? '!pb-[5px]' : 'pb-[5px]',
            },
            '8px': {
                '': important ? '!pb-[8px]' : 'pb-[8px]',
            },
            '10px': {
                '': important ? '!pb-[10px]' : 'pb-[10px]',
            },
            '20px': {
                '': important ? '!pb-[20px]' : 'pb-[20px]',
            },
            '30px': {
                '': important ? '!pb-[30px]' : 'pb-[30px]',
            },
            '100px': {
                '': important ? '!pb-[100px]' : 'pb-[100px]',
            },
        },
        l: {
            '0': {
                '': important ? '!pl-0' : 'pl-0',
            },
            '5px': {
                '': important ? '!pl-[5px]' : 'pl-[5px]',
            },
            '8px': {
                '': important ? '!pl-[8px]' : 'pl-[8px]',
            },
            '10px': {
                '': important ? '!pl-[10px]' : 'pl-[10px]',
            },
            '20px': {
                '': important ? '!pl-[20px]' : 'pl-[20px]',
            },
            '30px': {
                '': important ? '!pl-[30px]' : 'pl-[30px]',
            },
            '100px': {
                '': important ? '!pl-[100px]' : 'pl-[100px]',
            },
        },
    };

    return classes[direction][pixels][device] ?? '';
}

export function margin(direction: 't'|'y'|'xy'|'x'|'r'|'l'|'b' = "xy", pixels: '-10px'|'-100px'|'-120px'|'0'|'5px'|'10px'|'20px'|'30px'|'40px'|'300px'|'auto' = '0', device: 'sm'|'md'|'' = '') {
    const classes: {
        [direction: string]: {
            [pixels: string]: {
                [device: string]: string;
            }
        }
    } = {
        xy: {
            'auto': {
                '': 'm-auto',
                sm: 'sm:m-auto',
                md: 'md:m-auto',
            },
            '-100px': {
                '': 'm-[-100px]',
                sm: 'sm:m-[-100px]',
                md: 'md:m-[-100px]',
            },
            '-120px': {
                '': 'm-[-120px]',
                sm: 'sm:m-[-120px]',
                md: 'md:m-[-120px]',
            },
            '-10px': {
                '': 'm-[-10px]',
                sm: 'sm:m-[-10px]',
                md: 'md:m-[-10px]',
            },
            '0': {
                '': 'm-0',
                sm: 'sm:m-0',
                md: 'md:m-0',
            },
            '5px': {
                '': 'm-[5px]',
                sm: 'sm:m-[5px]',
                md: 'md:m-[5px]',
            },
            '10px': {
                '': 'm-[10px]',
                sm: 'sm:m-[10px]',
                md: 'md:m-[10px]',
            },
            '20px': {
                '': 'm-[20px]',
                sm: 'sm:m-[20px]',
                md: 'md:m-[20px]',
            },
            '30px': {
                '': 'm-[30px]',
                sm: 'sm:m-[30px]',
                md: 'md:m-[30px]',
            },
            '40px': {
                '': 'm-[40px]',
                sm: 'sm:m-[40px]',
                md: 'md:m-[40px]',
            },
            '300px': {
                '': 'm-[300px]',
                sm: 'sm:m-[300px]',
                md: 'md:m-[300px]',
            },
        },
        x: {
            'auto': {
                '': 'mx-auto',
                sm: 'sm:mx-auto',
                md: 'md:mx-auto',
            },
            '-10px': {
                '': 'mx-[-10px]',
                sm: 'sm:mx-[-10px]',
                md: 'md:mx-[-10px]',
            },
            '-100px': {
                '': 'mx-[-100px]',
                sm: 'sm:mx-[-100px]',
                md: 'md:mx-[-100px]',
            },
            '-120px': {
                '': 'mx-[-120px]',
                sm: 'sm:mx-[-120px]',
                md: 'md:mx-[-120px]',
            },
            '0': {
                '': 'mx-0',
                sm: 'sm:mx-0',
                md: 'md:mx-0',
            },
            '5px': {
                '': 'mx-[5px]',
                sm: 'sm:mx-[5px]',
                md: 'md:mx-[5px]',
            },
            '10px': {
                '': 'mx-[10px]',
                sm: 'sm:mx-[10px]',
                md: 'md:mx-[10px]',
            },
            '20px': {
                '': 'mx-[20px]',
                sm: 'sm:mx-[20px]',
                md: 'md:mx-[20px]',
            },
            '30px': {
                '': 'mx-[30px]',
                sm: 'sm:mx-[30px]',
                md: 'md:mx-[30px]',
            },
            '40px': {
                '': 'mx-[40px]',
                sm: 'sm:mx-[40px]',
                md: 'md:mx-[40px]',
            },
            '300px': {
                '': 'mx-[300px]',
                sm: 'sm:mx-[300px]',
                md: 'md:mx-[300px]',
            },
        },
        y: {
            'auto': {
                '': 'my-auto',
                sm: 'sm:my-auto',
                md: 'md:my-auto',
            },
            '-100px': {
                '': 'my-[-100px]',
                sm: 'sm:my-[-100px]',
                md: 'md:my-[-100px]',
            },
            '-120px': {
                '': 'my-[-120px]',
                sm: 'sm:my-[-120px]',
                md: 'md:my-[-120px]',
            },
            '-10px': {
                '': 'my-[-10px]',
                sm: 'sm:my-[-10px]',
                md: 'md:my-[-10px]',
            },
            '0': {
                '': 'my-0',
                sm: 'sm:my-0',
                md: 'md:my-0',
            },
            '5px': {
                '': 'my-[5px]',
                sm: 'sm:my-[5px]',
                md: 'md:my-[5px]',
            },
            '10px': {
                '': 'my-[10px]',
                sm: 'sm:my-[10px]',
                md: 'md:my-[10px]',
            },
            '20px': {
                '': 'my-[20px]',
                sm: 'sm:my-[20px]',
                md: 'md:my-[20px]',
            },
            '30px': {
                '': 'my-[30px]',
                sm: 'sm:my-[30px]',
                md: 'md:my-[30px]',
            },
            '300px': {
                '': 'my-[300px]',
                sm: 'sm:my-[300px]',
                md: 'md:my-[300px]',
            },
        },
        t: {
            'auto': {
                '': 'mt-auto',
                sm: 'sm:mt-auto',
                md: 'md:mt-auto',
            },
            '-100px': {
                '': 'mt-[-100px]',
                sm: 'sm:mt-[-100px]',
                md: 'md:mt-[-100px]',
            },
            '-120px': {
                '': 'mt-[-120px]',
                sm: 'sm:mt-[-120px]',
                md: 'md:mt-[-120px]',
            },
            '-10px': {
                '': 'mt-[-10px]',
                sm: 'sm:mt-[-10px]',
                md: 'md:mt-[-10px]',
            },
            '0': {
                '': 'mt-0',
                sm: 'sm:mt-0',
                md: 'md:mt-0',
            },
            '5px': {
                '': 'mt-[5px]',
                sm: 'sm:mt-[5px]',
                md: 'md:mt-[5px]',
            },
            '10px': {
                '': 'mt-[10px]',
                sm: 'sm:mt-[10px]',
                md: 'md:mt-[10px]',
            },
            '20px': {
                '': 'mt-[20px]',
                sm: 'sm:mt-[20px]',
                md: 'md:mt-[20px]',
            },
            '30px': {
                '': 'mt-[30px]',
                sm: 'sm:mt-[30px]',
                md: 'md:mt-[30px]',
            },
            '40px': {
                '': 'mt-[40px]',
                sm: 'sm:mt-[40px]',
                md: 'md:mt-[40px]',
            },
            '300px': {
                '': 'mt-[300px]',
                sm: 'sm:mt-[300px]',
                md: 'md:mt-[300px]',
            },
        },
        r: {
            'auto': {
                '': 'mr-auto',
                sm: 'sm:mr-auto',
                md: 'md:mr-auto',
            },
            '-100px': {
                '': 'mr-[-100px]',
                sm: 'sm:mr-[-100px]',
                md: 'md:mr-[-100px]',
            },
            '-120px': {
                '': 'mr-[-120px]',
                sm: 'sm:mr-[-120px]',
                md: 'md:mr-[-120px]',
            },
            '-10px': {
                '': 'mr-[-10px]',
                sm: 'sm:mr-[-10px]',
                md: 'md:mr-[-10px]',
            },
            '0': {
                '': 'mr-0',
                sm: 'sm:mr-0',
                md: 'md:mr-0',
            },
            '5px': {
                '': 'mr-[5px]',
                sm: 'sm:mr-[5px]',
                md: 'md:mr-[5px]',
            },
            '10px': {
                '': 'mr-[10px]',
                sm: 'sm:mr-[10px]',
                md: 'md:mr-[10px]',
            },
            '20px': {
                '': 'mr-[20px]',
                sm: 'sm:mr-[20px]',
                md: 'md:mr-[20px]',
            },
            '30px': {
                '': 'mr-[30px]',
                sm: 'sm:mr-[30px]',
                md: 'md:mr-[30px]',
            },
            '40px': {
                '': 'mr-[40px]',
                sm: 'sm:mr-[40px]',
                md: 'md:mr-[40px]',
            },
            '300px': {
                '': 'mr-[300px]',
                sm: 'sm:mr-[300px]',
                md: 'md:mr-[300px]',
            },
        },
        b: {
            'auto': {
                '': 'mb-auto',
                sm: 'sm:mb-auto',
                md: 'md:mb-auto',
            },
            '-100px': {
                '': 'mb-[-100px]',
                sm: 'sm:mb-[-100px]',
                md: 'md:mb-[-100px]',
            },
            '-120px': {
                '': 'mb-[-120px]',
                sm: 'sm:mb-[-120px]',
                md: 'md:mb-[-120px]',
            },
            '-10px': {
                '': 'mb-[-10px]',
                sm: 'sm:mb-[-10px]',
                md: 'md:mb-[-10px]',
            },
            '0': {
                '': 'mb-0',
                sm: 'sm:mb-0',
                md: 'md:mb-0',
            },
            '5px': {
                '': 'mb-[5px]',
                sm: 'sm:mb-[5px]',
                md: 'md:mb-[5px]',
            },
            '10px': {
                '': 'mb-[10px]',
                sm: 'sm:mb-[10px]',
                md: 'md:mb-[10px]',
            },
            '20px': {
                '': 'mb-[20px]',
                sm: 'sm:mb-[20px]',
                md: 'md:mb-[20px]',
            },
            '30px': {
                '': 'mb-[30px]',
                sm: 'sm:mb-[30px]',
                md: 'md:mb-[30px]',
            },
            '40px': {
                '': 'mb-[40px]',
                sm: 'sm:mb-[40px]',
                md: 'md:mb-[40px]',
            },
            '300px': {
                '': 'mb-[300px]',
                sm: 'sm:mb-[300px]',
                md: 'md:mb-[300px]',
            },
        },
        l: {
            'auto': {
                '': 'ml-auto',
                sm: 'sm:ml-auto',
                md: 'md:ml-auto',
            },
            '-100px': {
                '': 'ml-[-100px]',
                sm: 'sm:ml-[-100px]',
                md: 'md:ml-[-100px]',
            },
            '-120px': {
                '': 'ml-[-120px]',
                sm: 'sm:ml-[-120px]',
                md: 'md:ml-[-120px]',
            },
            '-10px': {
                '': 'ml-[-10px]',
                sm: 'sm:ml-[-10px]',
                md: 'md:ml-[-10px]',
            },
            '0': {
                '': 'ml-0',
                sm: 'sm:ml-0',
                md: 'md:ml-0',
            },
            '5px': {
                '': 'ml-[5px]',
                sm: 'sm:ml-[5px]',
                md: 'md:ml-[5px]',
            },
            '10px': {
                '': 'ml-[10px]',
                sm: 'sm:ml-[10px]',
                md: 'md:ml-[10px]',
            },
            '20px': {
                '': 'ml-[20px]',
                sm: 'sm:ml-[20px]',
                md: 'md:ml-[20px]',
            },
            '30px': {
                '': 'ml-[30px]',
                sm: 'sm:ml-[30px]',
                md: 'md:ml-[30px]',
            },
            '40px': {
                '': 'ml-[40px]',
                sm: 'sm:ml-[40px]',
                md: 'md:ml-[40px]',
            },
            '300px': {
                '': 'ml-[300px]',
                sm: 'sm:ml-[300px]',
                md: 'md:ml-[300px]',
            },
        },
    };

    return classes[direction][pixels][device] ?? '';
}

export function height(pixels: 'auto'|'100vh-90px'|'100vh-100px'|'20px'|'30px'|'40px'|'50px'|'70px'|'60px'|'100px'|'180px'|'200px'|'250px'|'300px'|'80%'|'full'|'80vh'|'100vh' = 'auto', layout: 'min'|'max'|'' = '', device: '' = '') {
    const classes: {
        [pixels: string]: {
            [layout: string]: {
                [device: string]: string;
            }
        }
    } = {
        auto: {
            '': {
                '': '',
            },
            min: {
                '': '',
            },
            max: {
                '': '',
            },
        },
        '100vh-90px': {
            '': {
                '': 'h-[calc(100vh-90px)]',
            },
            min: {
                '': 'min-h-[calc(100vh-90px)]',
            },
            max: {
                '': 'max-h-[calc(100vh-90px)]',
            },
        },
        '100vh-100px': {
            '': {
                '': 'h-[calc(100vh-100px)]',
            },
            min: {
                '': 'min-h-[calc(100vh-100px)]',
            },
            max: {
                '': 'max-h-[calc(100vh-100px)]',
            },
        },
        '20px': {
            '': {
                '': 'h-[20px]',
            },
            min: {
                '': 'min-h-[20px]',
            },
            max: {
                '': 'max-h-[20px]',
            },
        },
        '30px': {
            '': {
                '': 'h-[30px]',
            },
            min: {
                '': 'min-h-[30px]',
            },
            max: {
                '': 'max-h-[30px]',
            },
        },
        '40px': {
            '': {
                '': 'h-[40px]',
            },
            min: {
                '': 'min-h-[40px]',
            },
            max: {
                '': 'max-h-[40px]',
            },
        },
        '50px': {
            '': {
                '': 'h-[50px]',
            },
            min: {
                '': 'min-h-[50px]',
            },
            max: {
                '': 'max-h-[50px]',
            },
        },
        '60px': {
            '': {
                '': 'h-[60px]',
            },
            min: {
                '': 'min-h-[60px]',
            },
            max: {
                '': 'max-h-[60px]',
            },
        },
        '70px': {
            '': {
                '': 'h-[70px]',
            },
            min: {
                '': 'min-h-[70px]',
            },
            max: {
                '': 'max-h-[70px]',
            },
        },
        '100px': {
            '': {
                '': 'h-[100px]',
            },
            min: {
                '': 'min-h-[100px]',
            },
            max: {
                '': 'max-h-[100px]',
            },
        },
        '180px': {
            '': {
                '': 'h-[180px]',
            },
            min: {
                '': 'min-h-[180px]',
            },
            max: {
                '': 'max-h-[180px]',
            },
        },
        '200px': {
            '': {
                '': 'h-[200px]',
            },
            min: {
                '': 'min-h-[200px]',
            },
            max: {
                '': 'max-h-[200px]',
            },
        },
        '250px': {
            '': {
                '': 'h-[250px]',
            },
            min: {
                '': 'min-h-[250px]',
            },
            max: {
                '': 'max-h-[250px]',
            },
        },
        '300px': {
            '': {
                '': 'h-[300px]',
            },
            min: {
                '': 'min-h-[300px]',
            },
            max: {
                '': 'max-h-[300px]',
            },
        },
        '80%': {
            '': {
                '': 'h-[80%]',
            },
            min: {
                '': 'min-h-[80%]',
            },
            max: {
                '': 'max-h-[80%]',
            },
        },
        'full': {
            '': {
                '': 'h-full',
            },
            min: {
                '': 'min-h-full',
            },
            max: {
                '': 'max-h-full',
            },
        },
        '100vh': {
            '': {
                '': 'h-[100vh]',
            },
            min: {
                '': 'min-h-[100vh]',
            },
            max: {
                '': 'max-h-[100vh]',
            },
        },
        '80vh': {
            '': {
                '': 'h-[80vh]',
            },
            min: {
                '': 'min-h-[80vh]',
            },
            max: {
                '': 'max-h-[80vh]',
            },
        },
    };

    return classes[pixels][layout][device] ?? '';
}

export function flex(
    valign: 'center'|'top'|'stretch' = 'top',
    halign: 'center'|'left'|'right'|'between' = 'left',
    gap: '5px'|'10px'|'20px'|'0' = '0',
    fitContent: boolean = true, // @deprecated
    device: 'sm'|'md'|'' = '',
) {
    const classesFlex: {
        [device: string]: string;
    } = {
        '': 'flex',
        sm: 'sm:flex',
        md: 'md:flex',
    };

    const classesVerticalAlign: {
        [valign: string]: {
            [device: string]: string;
        }
    } = {
        center: {
            '': 'items-center',
            sm: 'sm:items-center',
            md: 'md:items-center',
        },
        top: {
            '': 'items-start',
            sm: 'sm:items-start',
            md: 'md:items-start',
        },
        stretch: {
            '': 'items-stretch',
            sm: 'sm:items-stretch',
            md: 'md:items-stretch',
        },
    };

    const classesHorizontalAlign: {
        [halign: string]: {
            [device: string] : string;
        }
    } = {
        center: {
            '': 'justify-center',
            sm: 'sm:justify-center',
            md: 'md:justify-center',
        },
        left: {
            '': 'justify-start',
            sm: 'sm:justify-start',
            md: 'md:justify-start',
        },
        right: {
            '': 'justify-end',
            sm: 'sm:justify-end',
            md: 'md:justify-end',
        },
        between: {
            '': 'justify-between',
            sm: 'sm:justify-between',
            md: 'md:justify-between',
        },
    };

    const classesGap: {
        [gap: string]: {
            [device: string]: string;
        }
    } = {
        '0': {
            '': '',
            sm: '',
            md: '',
        },
        '5px': {
            '': 'gap-[5px]',
            sm: 'sm:gap-[5px]',
            md: 'md:gap-[5px]',
        },
        '10px': {
            '': 'gap-[10px]',
            sm: 'sm:gap-[10px]',
            md: 'md:gap-[10px]',
        },
        '20px': {
            '': 'gap-[20px]',
            sm: 'sm:gap-[20px]',
            md: 'md:gap-[20px]',
        },
    };

    return [
        classesFlex[device] ?? '',
        classesVerticalAlign[valign][device] ?? '',
        classesHorizontalAlign[halign][device] ?? '',
        classesGap[gap][device] ?? '',
        'flex-wrap',
    ].filter((style: string) => style !== '').join(' ');
}

export function width(pixels: 'auto'|'30px'|'40px'|'100px'|'152px'|'180px'|'200px'|'250px'|'300px'|'396px'|'500px'|'1280px'|'48%'|'50%'|'80%'|'100%'|'100%-300px' = 'auto', layout: 'min'|'max'|'' = '', device: 'sm'|'md'|'' = '') {
    const classes: {
        [pixels: string]: {
            [layout: string]: {
                [device: string]: string,
            }
        }
    } = {
        'auto': {
            '': {
                '': 'w-auto',
                'sm': 'sm:w-auto',
                'md': 'md:w-auto',
            },
            'min': {
                '': 'min-w-auto',
                'sm': 'sm:min-w-auto',
                'md': 'md:min-w-auto',
            },
            'max': {
                '': 'max-w-auto',
                'sm': 'sm:max-w-auto',
                'md': 'md:max-w-auto',
            }
        },
        '30px': {
            '': {
                '': 'w-[30px]',
                'sm': 'sm:w-[30px]',
                'md': 'md:w-[30px]',
            },
            'min': {
                '': 'min-w-[30px]',
                'sm': 'sm:min-w-[30px]',
                'md': 'md:min-w-[30px]',
            },
            'max': {
                '': 'max-w-[30px]',
                'sm': 'sm:max-w-[30px]',
                'md': 'md:max-w-[30px]',
            }
        },
        '40px': {
            '': {
                '': 'w-[40px]',
                'sm': 'sm:w-[40px]',
                'md': 'md:w-[40px]',
            },
            'min': {
                '': 'min-w-[40px]',
                'sm': 'sm:min-w-[40px]',
                'md': 'md:min-w-[40px]',
            },
            'max': {
                '': 'max-w-[40px]',
                'sm': 'sm:max-w-[40px]',
                'md': 'md:max-w-[40px]',
            }
        },
        '100px': {
            '': {
                '': 'w-[100px]',
                'sm': 'sm:w-[100px]',
                'md': 'md:w-[100px]',
            },
            'min': {
                '': 'min-w-[100px]',
                'sm': 'sm:min-w-[100px]',
                'md': 'md:min-w-[100px]',
            },
            'max': {
                '': 'max-w-[100px]',
                'sm': 'sm:max-w-[100px]',
                'md': 'md:max-w-[100px]',
            }
        },
        '152px': {
            '': {
                '': 'w-[152px]',
                'sm': 'sm:w-[152px]',
                'md': 'md:w-[152px]',
            },
            'min': {
                '': 'min-w-[152px]',
                'sm': 'sm:min-w-[152px]',
                'md': 'md:min-w-[152px]',
            },
            'max': {
                '': 'max-w-[152px]',
                'sm': 'sm:max-w-[152px]',
                'md': 'md:max-w-[152px]',
            }
        },
        '180px': {
            '': {
                '': 'w-[180px]',
                'sm': 'sm:w-[180px]',
                'md': 'md:w-[180px]',
            },
            'min': {
                '': 'min-w-[180px]',
                'sm': 'sm:min-w-[180px]',
                'md': 'md:min-w-[180px]',
            },
            'max': {
                '': 'max-w-[180px]',
                'sm': 'sm:max-w-[180px]',
                'md': 'md:max-w-[180px]',
            }
        },
        '200px': {
            '': {
                '': 'w-[200px]',
                'sm': 'sm:w-[200px]',
                'md': 'md:w-[200px]',
            },
            'min': {
                '': 'min-w-[200px]',
                'sm': 'sm:min-w-[200px]',
                'md': 'md:min-w-[200px]',
            },
            'max': {
                '': 'max-w-[200px]',
                'sm': 'sm:max-w-[200px]',
                'md': 'md:max-w-[200px]',
            }
        },
        '250px': {
            '': {
                '': 'w-[250px]',
                'sm': 'sm:w-[250px]',
                'md': 'md:w-[250px]',
            },
            'min': {
                '': 'min-w-[250px]',
                'sm': 'sm:min-w-[250px]',
                'md': 'md:min-w-[250px]',
            },
            'max': {
                '': 'max-w-[250px]',
                'sm': 'sm:max-w-[250px]',
                'md': 'md:max-w-[250px]',
            }
        },
        '300px': {
            '': {
                '': 'w-[300px]',
                'sm': 'sm:w-[300px]',
                'md': 'md:w-[300px]',
            },
            'min': {
                '': 'min-w-[300px]',
                'sm': 'sm:min-w-[300px]',
                'md': 'md:min-w-[300px]',
            },
            'max': {
                '': 'max-w-[300px]',
                'sm': 'sm:max-w-[300px]',
                'md': 'md:max-w-[300px]',
            }
        },
        '500px': {
            '': {
                '': 'w-[400px]',
                'sm': 'sm:w-[400px]',
                'md': 'md:w-[400px]',
            },
            'min': {
                '': 'min-w-[400px]',
                'sm': 'sm:min-w-[400px]',
                'md': 'md:min-w-[400px]',
            },
            'max': {
                '': 'max-w-[400px]',
                'sm': 'sm:max-w-[400px]',
                'md': 'md:max-w-[400px]',
            }
        },
        '1280px': {
            '': {
                '': 'w-[1280px]',
                'sm': 'sm:w-[1280px]',
                'md': 'md:w-[1280px]',
            },
            'min': {
                '': 'min-w-[1280px]',
                'sm': 'sm:min-w-[1280px]',
                'md': 'md:min-w-[1280px]',
            },
            'max': {
                '': 'max-w-[1280px]',
                'sm': 'sm:max-w-[1280px]',
                'md': 'md:max-w-[1280px]',
            }
        },
        '100%-300px': {
            '': {
                '': 'w-[calc(100%-300px)]',
                'sm': 'sm:w-[calc(100%-300px)]',
                'md': 'md:w-[calc(100%-300px)]',
            },
            'min': {
                '': 'min-w-[calc(100%-300px)]',
                'sm': 'sm:min-w-[calc(100%-300px)]',
                'md': 'md:min-w-[calc(100%-300px)]',
            },
            'max': {
                '': 'max-w-[calc(100%-300px)]',
                'sm': 'sm:max-w-[calc(100%-300px)]',
                'md': 'md:max-w-[calc(100%-300px)]',
            }
        },
        '48%': {
            '': {
                '': 'w-[48%]',
                'sm': 'sm:w-[48%]',
                'md': 'md:w-[48%]',
            },
            'min': {
                '': 'min-w-[48%]',
                'sm': 'sm:min-w-[48%]',
                'md': 'md:min-w-[48%]',
            },
            'max': {
                '': 'max-w-[48%]',
                'sm': 'sm:max-w-[48%]',
                'md': 'md:max-w-[48%]',
            }
        },
        '50%': {
            '': {
                '': 'w-[50%]',
                'sm': 'sm:w-[50%]',
                'md': 'md:w-[50%]',
            },
            'min': {
                '': 'min-w-[50%]',
                'sm': 'sm:min-w-[50%]',
                'md': 'md:min-w-[50%]',
            },
            'max': {
                '': 'max-w-[50%]',
                'sm': 'sm:max-w-[50%]',
                'md': 'md:max-w-[50%]',
            }
        },
        '80%': {
            '': {
                '': 'w-[80%]',
                'sm': 'sm:w-[80%]',
                'md': 'md:w-[80%]',
            },
            'min': {
                '': 'min-w-[80%]',
                'sm': 'sm:min-w-[80%]',
                'md': 'md:min-w-[80%]',
            },
            'max': {
                '': 'max-w-[80%]',
                'sm': 'sm:max-w-[80%]',
                'md': 'md:max-w-[80%]',
            }
        },
        '100%': {
            '': {
                '': 'w-full',
                'sm': 'sm:w-full',
                'md': 'md:w-full',
            },
            'min': {
                '': 'min-w-full',
                'sm': 'sm:min-w-full',
                'md': 'md:min-w-full',
            },
            'max': {
                '': 'max-w-full',
                'sm': 'sm:max-w-full',
                'md': 'md:max-w-full',
            }
        },
    }

    return classes[pixels][layout][device] ?? '';
}

export function round(corner: 'default'|'full' = "default") {
    if (corner === 'full') {
        return 'rounded-full';
    }

    return 'rounded';
}

export function cursor(type: 'pointer'|'normal' = 'normal') {
    switch (type) {
        case "pointer": return 'cursor-pointer';
    }

    return '';
}

export function display(type: 'block'|'none' = 'block', device: 'sm'|'md'|'' = '') {
    switch (type) {
        case "block":
            if (device === 'sm') return 'sm:block';
            if (device === 'md') return 'md:block';
            return 'block';
        case 'none':
            if (device === 'sm') return 'sm:hidden';
            if (device === 'md') return 'md:hidden';
            return 'hidden';
    }

    return '';
}

export function position(type: 'relative'|'fixed'|'absolute' = 'relative', topPixels: '0'|'center'|'' = '', leftPixels: '0'|'center'|'' = '', device: 'sm'|'md'|'' = '') {
    const classesPosition: {
        [type: string]: {
            [device: string]: string;
        }
    } = {
        relative: {
            '': 'relative',
            sm: 'sm:relative',
            md: 'md:relative',
        },
        fixed: {
            '': 'fixed',
            sm: 'sm:fixed',
            md: 'md:fixed',
        },
        absolute: {
            '': 'absolute',
            sm: 'sm:absolute',
            md: 'md:absolute',
        },
    };

    const classesTopPixels: {
        [topPixels: string]: {
            [device: string]: string;
        }
    } = {
        '': {
            '': '',
            sm: '',
            md: '',
        },
        '0': {
            '': 'top-0',
            sm: 'sm:top-0',
            md: 'md:top-0',
        },
        center: {
            '': 'top-[50%] translate-y-[-50%]',
            sm: 'sm:top-[50%] sm:translate-y-[-50%]',
            md: 'md:top-[50%] md:translate-y-[-50%]',
        },
    };

    const classesLeftPixels: {
        [leftPixels: string]: {
            [device: string]: string;
        }
    } = {
        '': {
            '': '',
            sm: '',
            md: '',
        },
        '0': {
            '': 'left-0',
            sm: 'sm:left-0',
            md: 'md:left-0',
        },
        center: {
            '': 'left-[50%] translate-x-[-50%]',
            sm: 'sm:left-[50%] sm:translate-x-[-50%]',
            md: 'md:left-[50%] md:translate-x-[-50%]',
        },
    };

    return [
        classesPosition[type][device] ?? '',
        classesTopPixels[topPixels][device] ?? '',
        classesLeftPixels[leftPixels][device] ?? ''
    ].filter((style: string) => style !== '').join(' ');
}

export function float(direction: 'r') {
    if (direction === 'r') {
        return' float-right';
    }

    return '';
}

export function spin(spining: 'fast'|'' = '') {
    if (spining === 'fast') {
        return 'spinning-circle';
    }

    return '';
}

export function boldText() {
    return 'font-bold';
}

export function zIndex(position: '300'|'500'|'550'|'1000'|'1050'|'1060'|'0' = '0') {
    const classes: {
        [position: string]: string,
    } = {
        '300': 'z-[300]',
        '500': 'z-[500]',
        '550': 'z-[550]',
        '1000': 'z-[1000]',
        '1050': 'z-[1050]',
        '1060': 'z-[1060]',
    };

    return classes[position] ?? '';
}

export function italic() {
    return 'italic';
}

export function top(pixels: '0'|'') {
    const classes = {
        '0': 'top-0',
        '': ''
    };

    return classes[pixels] ?? '';
}

export function bottom(pixels: '0'|'') {
    const classes = {
        '0': 'bottom-0',
        '': ''
    };

    return classes[pixels] ?? '';
}

export function underline(underlined: boolean = false) {
    return 'underline';
}

export function borderSize(size: '1px'|'2px'|'5px'|'0' = '0') {
    const classes: {
        [size: string]: string,
    } = {
        '0': 'border-0',
        '1px': 'border-[1px]',
        '2px': 'border-[2px]',
        '5px': 'border-[5px]',
    }

    return classes[size] ?? '';
}

export function borderStyle(style: 'dashed'|'' = '') {
    if (style === 'dashed') {
        return 'border-dashed';
    }

    return '';
}

export function classes(...args: string[]) {
    // @ts-ignore
    return args.filter((currentClass: string) => currentClass !== '').join(' ');
}