import moment from "moment/moment";
import {CACHE_TIME_ONE_DAY} from "../../../Constants";

export interface ValueLoaderObjectInterface {
    cacheDate: any|undefined;
    data: string[];
    isLoading: boolean;
    isLoaded: boolean;
}

class AbstractValueLoader {
    cache: ValueLoaderObjectInterface|undefined = undefined;
    cacheFreshTime: number;

    constructor(cacheFreshTime: number = CACHE_TIME_ONE_DAY) {
        this.cacheFreshTime = cacheFreshTime;
    }

    isCacheFresh(cacheDate: any) {
        return moment().diff(moment(cacheDate)) < this.cacheFreshTime;
    }

    load(apiCall: any, forceRefresh: boolean = false) {
        if (
            (forceRefresh && undefined !== this.cache)
            || (undefined !== this.cache && !this.isCacheFresh(this.cache.cacheDate))
        ) {
            this.cache = undefined;
        }

        if (undefined === this.cache) {
            this.cache = {
                cacheDate: undefined,
                data: [],
                isLoading: true,
                isLoaded: true,
            };

            const currentObject = this;
            (async function() {
                currentObject.cache = {
                    ...currentObject.cache,
                    isLoading: false,
                    data: (await apiCall()).data ?? [],
                    cacheDate: moment(),
                    isLoaded: true
                }
            })();
        }
    }

    hasLoading() {
        return this.cache !== undefined && (
            !this.cache.isLoaded
            || this.cache.isLoading
        );
    }

    get() {
        return this.cache;
    }
}

export default AbstractValueLoader;