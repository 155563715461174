import {useEffect, useState} from "react";
import Translator from "../../../../Utils/Translator";
import Button from "../../../Common/Button/Button";
import {LocationsOpeningsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsOpeningsLoader";
import {ReactComponent as CircledCross} from '../../../../Icons/CircledCross.svg';
import Label from "../../../Common/Label/Label";
import {
    CTA_CREATE_SCHEDULE, CTA_SAVE_SCHEDULE, CTA_SCHEDULE_DUPLICATE_TO, CTA_SCHEDULE_DUPLICATE_TO_ALL,
    LABEL_DAY_NAME, LABEL_ERROR, LABEL_SCHEDULE_FORM_ERROR, LABEL_SCHEDULE_FORM_SUCCESS, LABEL_SUCCESS,
    LABEL_TIME_HOUR_PLACEHOLDER,
    LABEL_TIME_MINUTE_PLACEHOLDER,
    LABEL_TIME_SEPARATOR
} from "../../../../Constants/Labels";
import LocationOpeningApi from "../../../../Api/Calls/LocationOpeningApi";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import {
    backgroundColor, boldText,
    border,
    borderColor,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize,
    width
} from "../../../../Theme/Theme";
import {useParams} from "react-router-dom";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import {
    sendAddLocationScheduleEvent,
    sendDeleteLocationScheduleEvent, sendUpdateLocationScheduleFailEvent,
    sendUpdateLocationScheduleSuccessEvent, sendUpdateLocationSuccessEvent
} from "../../../../GoogleAnalytics/Events";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";
import {getLabel} from "../../../../Helpers/Label";

export const MONDAY_DEFAULT = 'MONDAY_DEFAULT';
export const TUESDAY_DEFAULT = 'TUESDAY_DEFAULT';
export const WEDNESDAY_DEFAULT = 'WEDNESDAY_DEFAULT';
export const THURSDAY_DEFAULT = 'THURSDAY_DEFAULT';
export const FRIDAY_DEFAULT = 'FRIDAY_DEFAULT';
export const SATURDAY_DEFAULT = 'SATURDAY_DEFAULT';
export const SUNDAY_DEFAULT = 'SUNDAY_DEFAULT';

const WHOLE_DAY_OPENED = 'WHOLE_DAY_OPENED';
const WHOLE_DAY_CLOSED = 'WHOLE_DAY_CLOSED';
const WHOLE_DAY_UNKNOWN = 'WHOLE_DAY_UNKNOWN';
const WHOLE_DAY_SCHEDULE = 'WHOLE_DAY_SCHEDULE';

export const TYPE_TICKET = 'TYPE_TICKET';

export interface ScheduleFormInterface {
    locationId: string;
}

function ScheduleForm({locationId}: ScheduleFormInterface) {
    const {fetch: fetchLocationOpenings, getLocationOpeningsByLocationId, locationsOpenings} = LocationsOpeningsLoader();
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();
    const getUserIdUseCase = GetUserIdUseCase();
    const [duplicationsToApply, setDuplicationsToApply] = useState<any>({});
    const [autoSubmit, setAutoSubmit] = useState(false);

    const [formStatus, setFormStatus] = useState({
        success: false,
        message: undefined
    });
    const [form, setForm] = useState([
        {
            dateOpening: MONDAY_DEFAULT,
            nextDateOpening: TUESDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: TUESDAY_DEFAULT,
            nextDateOpening: WEDNESDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: WEDNESDAY_DEFAULT,
            nextDateOpening: THURSDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: THURSDAY_DEFAULT,
            nextDateOpening: FRIDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: FRIDAY_DEFAULT,
            nextDateOpening: SATURDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: SATURDAY_DEFAULT,
            nextDateOpening: SUNDAY_DEFAULT,
            time: [],
        },
        {
            dateOpening: SUNDAY_DEFAULT,
            nextDateOpening: MONDAY_DEFAULT,
            time: [],
        }
    ]);

    useEffect(() => {
        if (locationId !== undefined) {
            fetchLocations([locationId]);
            fetchLocationOpenings(locationId);
        }
    }, []);

    useEffect(() => {
        const currentLocationOpenings = getLocationOpeningsByLocationId(locationId);
        if (currentLocationOpenings !== undefined) {
            syncFromApi();
        }
    }, [locationsOpenings]);

    useEffect(() => {
        if (autoSubmit) {
            submitSchedule();
            setAutoSubmit(() => false);
        }
    }, [autoSubmit]);

    if (locationId === undefined) {
        return null;
    }

    const location = getLocationById(locationId);
    if (location === undefined) {
        return null;
    }

    const scheduleObject = (id: string|undefined = undefined, openingHour: string = '', openingMinute: string = '', closingHour: string = '', closingMinute: string = '', wholeDayStatus: string = WHOLE_DAY_UNKNOWN, openingType: string = TYPE_TICKET) => {
        return {
            id,
            openingHour,
            openingMinute,
            closingHour,
            closingMinute,
            wholeDayStatus,
            openingType
        };
    };

    const locationOpenings = getLocationOpeningsByLocationId(locationId);
    if (locationOpenings === undefined) {
        return null;
    }

    const syncFromApi = () => {
        const newState = [...form];
        for (const formDay of newState) {
            formDay.time = [];

            for (const time of locationOpenings) {
                if (time.dateOpening === formDay.dateOpening) {
                    const object = scheduleObject(time.id, time.timeOpening.hour, time.timeOpening.minute, time.timeClosing.hour, time.timeClosing.minute, time.wholeDayStatus, time.openingType);
                    // @ts-ignore
                    formDay.time.push(object);
                }
            }
        }

        setForm(() => newState);
    };

    const dayTranslation = (dayName: string) => {
        let dayTranslated = undefined;
        switch(dayName) {
            case MONDAY_DEFAULT:
                dayTranslated = 'monday';
                break;
            case TUESDAY_DEFAULT:
                dayTranslated = 'tuesday';
                break;
            case WEDNESDAY_DEFAULT:
                dayTranslated = 'wednesday';
                break;
            case THURSDAY_DEFAULT:
                dayTranslated = 'thursday';
                break;
            case FRIDAY_DEFAULT:
                dayTranslated = 'friday';
                break;
            case SATURDAY_DEFAULT:
                dayTranslated = 'saturday';
                break;
            case SUNDAY_DEFAULT:
                dayTranslated = 'sunday';
                break;
        }

        if (dayTranslated !== undefined) {
            return LABEL_DAY_NAME(dayTranslated);
        }

        return dayName;
    };

    const createNewSchedule = (index: number) => {
        sendAddLocationScheduleEvent(getUserIdUseCase.execute(), locationId);
        const newState: any = [
            ...form
        ];
        newState[index].time.push(scheduleObject());
        setForm([
            ...newState
        ]);
    };

    const hourSelectField = (value: string, dayIndex: number, timeIndex: number, type: 'openingHour'|'closingHour') => {
        let hours = [];
        for (let i=0; i<24; i++) {
            hours.push(<option value={i < 10 ? `0${i}` : i} key={`${dayIndex}-${timeIndex}-${type}-choice-${i}`}>{i}</option>)
        }

        return <select className={classes(border(), round(), padding('xy','10px'), backgroundColor("primary", true), value.length === 0 ? classes(border(), borderColor('error')) : '')} value={value} onChange={(e: any) => updateTimeHour(dayIndex, timeIndex, e.target.value, type)}>
            <option value={''}>{Translator.translate(LABEL_TIME_HOUR_PLACEHOLDER)}</option>
            {hours}
        </select>;
    };

    const minuteSelectField = (value: string, dayIndex: number, timeIndex: number, type: 'openingMinute'|'closingMinute') => {
        let minutes = [];
        for (let i=0; i<60; i++) {
            minutes.push(<option value={i < 10 ? `0${i}` : i} key={`${dayIndex}-${timeIndex}-${type}-choice-${i}`}>{i}</option>);
            i=i+14;
        }

        return <select className={classes(border(), round(), padding('xy','10px'), backgroundColor("primary", true), value.length === 0 ? classes(border(), borderColor('error')) : '')} value={value} onChange={(e: any) => updateTimeMinute(dayIndex, timeIndex, e.target.value, type)}>
            <option value={''}>{Translator.translate(LABEL_TIME_MINUTE_PLACEHOLDER)}</option>
            {minutes}
        </select>;
    };

    const updateTimeHour = (dayIndex: number, timeIndex: number, value: string, type: 'openingHour'|'closingHour') => {
        const newState = [...form];
        // @ts-ignore
        newState[dayIndex].time[timeIndex][type] = value;
        // @ts-ignore
        newState[dayIndex].time[timeIndex].wholeDayStatus = WHOLE_DAY_SCHEDULE;

        setForm([...newState]);
    }

    const updateTimeMinute = (dayIndex: number, timeIndex: number, value: string, type: 'openingMinute'|'closingMinute') => {
        const newState = [...form];
        // @ts-ignore
        newState[dayIndex].time[timeIndex][type] = value;
        // @ts-ignore
        newState[dayIndex].time[timeIndex][type] = value;
        // @ts-ignore
        newState[dayIndex].time[timeIndex].wholeDayStatus = WHOLE_DAY_SCHEDULE;

        setForm([...newState]);
    }

    const submitSchedule = async () => {
        setFormStatus({
            success: true,
            message: undefined,
        });
        try {
            sendUpdateLocationSuccessEvent(getUserIdUseCase.execute(), locationId)
            await LocationOpeningApi.updateLocationOpenings(locationId, form);
            setFormStatus({
                success: true,
                // @ts-ignore
                message: LABEL_SCHEDULE_FORM_SUCCESS
            });
            fetchLocationOpenings(locationId, true);
        } catch (e: any) {
            sendUpdateLocationScheduleFailEvent(getUserIdUseCase.execute(), locationId)
            console.error(e);
            setFormStatus({
                success: false,
                // @ts-ignore
                message: LABEL_SCHEDULE_FORM_ERROR
            });
        }
    };

    const deleteSchedule = (dayIndex: number, timeIndex: number) => {
        // @ts-ignore
        if (form[dayIndex].time[timeIndex] !== undefined && form[dayIndex].time[timeIndex].id !== undefined) {
            (async function() {
                // @ts-ignore
                sendDeleteLocationScheduleEvent(getUserIdUseCase.execute(), locationId, form[dayIndex].time[timeIndex].id);
                // @ts-ignore
                await LocationOpeningApi.deleteLocationOpening(locationId, form[dayIndex].time[timeIndex].id);
            })();
        }

        // @ts-ignore
        if (form[dayIndex].time[timeIndex] !== undefined) {
            sendDeleteLocationScheduleEvent(getUserIdUseCase.execute(), locationId, 'none');

            const newState = [...form];
            delete(newState[dayIndex].time[timeIndex]);

            setForm(() => newState);
        }
    };

    const addDuplicationsToApply = (currentDay: string, timeIndex: number, value: string) => {
        const newState = {
            ...duplicationsToApply,
        };
        if (value === '') {
            if (undefined !== newState[currentDay] && undefined !== newState[currentDay][timeIndex]) {
                delete newState[currentDay][timeIndex];
                setDuplicationsToApply(() => newState);
            }
            return;
        }

        if (newState[currentDay] === undefined) {
            newState[currentDay] = {};
        }

        newState[currentDay][timeIndex] = value;
        setDuplicationsToApply(() => newState);
    }

    const duplicateButtonSet = (currentDay: string, timeIndex: number) => {
        return <>
            <div>
                <select onChange={(e: any) => setDuplicationsToApply(() => addDuplicationsToApply(currentDay, timeIndex, e.target.value))} className={classes(padding('xy', '10px'), round(), backgroundColor(), border())}>
                    <option value={''}>{getLabel(CTA_SCHEDULE_DUPLICATE_TO)}</option>
                    <option value={'all'}>{getLabel(CTA_SCHEDULE_DUPLICATE_TO_ALL)}</option>
                    {form.filter((dayForm: any) => dayForm.dateOpening !== currentDay).map((dayForm: any) => <option key={`${currentDay}-duplicate-${timeIndex}-${dayForm.dateOpening}`} value={dayForm.dateOpening}>{getLabel(dayTranslation(dayForm.dateOpening))}</option>)}
                </select>
            </div>
            {duplicationsToApply !== undefined && duplicationsToApply[currentDay] !== undefined && undefined !== duplicationsToApply[currentDay][timeIndex] && <button className={classes(padding('xy', '8px'), border(), backgroundColor(), round())} onClick={() => duplicateSchedule(currentDay, timeIndex, duplicationsToApply[currentDay][timeIndex])}>Apply</button>}
        </>;
    };

    const duplicateSchedule = (currentDay: string, timeIndex: number, toDate: string) => {
        const timeToDuplicate: any = form.filter((dayForm: any) => dayForm.dateOpening === currentDay)[0].time[timeIndex];
        const newState = [...form];
        newState.forEach((formDay: any, index: number) => {
            if ((toDate === formDay.dateOpening || (toDate === 'all' && currentDay !== formDay.dateOpening)) && !containsTime(formDay.time, timeToDuplicate)) {
                // @ts-ignore
                newState[index].time.push(scheduleObject(
                    undefined,
                    timeToDuplicate.openingHour,
                    timeToDuplicate.openingMinute,
                    timeToDuplicate.closingHour,
                    timeToDuplicate.closingMinute,
                    timeToDuplicate.wholeDayStatus,
                    timeToDuplicate.openingType
                ));
            }
        });
        setForm(() => newState);
        setAutoSubmit(() => true);
    };

    const containsTime = (times: any, timeToCheck: any) => {
        let containing = false;
        times.forEach((time: any) => {
            if (
                time.openingHour === timeToCheck.openingHour
                && time.openingMinute === timeToCheck.openingMinute
                && time.closingHour === timeToCheck.closingHour
                && time.closingMinute === timeToCheck.closingMinute
                && time.wholeDayStatus === timeToCheck.wholeDayStatus
                && time.openingType === timeToCheck.openingType
            ) {
                containing = true;
            }
        });

        return containing;
    };

    return <div className={classes(width('100%'))}>
        <div className={classes(padding('xy', '10px'), backgroundColor("secondary"), round(), textSize('30px'), flex("center", "left", '10px'), border())}>
            <LabelNewLine boldText={true} label={location.name} className={classes(textSize('30px'))}/>&nbsp; {location.isQueueOpened && !location.queueEmergencyStop ? <LabelNewLine className={classes(round(), border(), padding('xy', '10px'), boldText())} textColor={"success"} label={LABEL_SUCCESS('restaurant_queue_opened')}/> : <LabelNewLine className={classes(round(), border(), padding('xy', '10px'), boldText())} textColor={'error'} label={LABEL_ERROR(!location.queueEmergencyStop ? 'restaurant_queue_closed' : 'restaurant_queue_emergency_closed')}/>}
        </div>
        <div className={classes(padding('xy', '10px'), margin('t', '10px'), round(), backgroundColor("secondary"), border())}>
            {form.map((dayForm: any, dayIndex: number) => <div key={`day-${dayIndex}`}>
                <LabelNewLine className={classes(margin('b', '10px'))} label={dayTranslation(dayForm.dateOpening)}/>
                {dayForm.time.map((time: any, timeIndex: number) => <div key={`day-${dayIndex}-${timeIndex}`} className={classes(margin('b', '10px'))}>
                    <div className={classes(padding('l', '10px'))}>
                        <div className={classes(flex('center', "left", '10px'))}>
                            <div className={classes(flex('center', 'left', '10px'))}>
                                {hourSelectField(time.openingHour, dayIndex, timeIndex, "openingHour")}
                                {minuteSelectField(time.openingMinute, dayIndex, timeIndex, "openingMinute")}
                                {Translator.translate(LABEL_TIME_SEPARATOR)}
                                {hourSelectField(time.closingHour, dayIndex, timeIndex, "closingHour")}
                                {minuteSelectField(time.closingMinute, dayIndex, timeIndex, "closingMinute")}
                            </div>
                            <div>
                                <CircledCross width={`25px`} onClick={() => deleteSchedule(dayIndex, timeIndex)} className={`cursor-pointer`}/>
                            </div>
                            {duplicateButtonSet(dayForm.dateOpening, timeIndex)}
                        </div>
                    </div>
                </div>)}
                <Button className={classes(backgroundColor('primary', true), margin('b', '10px'))} label={CTA_CREATE_SCHEDULE} selected={true} onClick={() => createNewSchedule(dayIndex)}></Button>
            </div>)}
        </div>
        <div className={classes(margin('t', '10px'))}>
            {formStatus.message !== undefined && !formStatus.success && <LabelNewLine className={classes(margin('t', '10px'))} label={formStatus.message} textColor={"error"}/>}
            {formStatus.message !== undefined && formStatus.success && <LabelNewLine className={classes(margin('t', '10px'))} label={formStatus.message} textColor={"success"}/>}
        </div>
        <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
            <Button label={CTA_SAVE_SCHEDULE} onClick={submitSchedule}/>
        </div>
    </div>;
}

export default ScheduleForm;