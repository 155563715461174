import {useBetween} from "use-between";
import {FavoriteLocationsLoader} from "../../Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import {CreateFavoriteLocationUseCase} from "../Favorite/CreateFavoriteLocationUseCase";
import {DeleteFavoriteLocationUseCase} from "../Favorite/DeleteFavoriteLocationUseCase";

function ToggleFavoriteLocation() {
    const {favoriteLocations} = FavoriteLocationsLoader();
    const addFavoriteLocationUseCase = CreateFavoriteLocationUseCase();
    const removeFavoriteLocationUseCase = DeleteFavoriteLocationUseCase();
    const execute = (locationId: string) => {
        if (undefined === favoriteLocations) {
            return;
        }

        if (favoriteLocations.data.includes(locationId)) {
            removeFavoriteLocationUseCase.execute(locationId);
        } else {
            addFavoriteLocationUseCase.execute(locationId);
        }
    };

    return {execute};
}

export const ToggleFavoriteLocationUseCase = () => useBetween(ToggleFavoriteLocation);