import MenuOpener from "../../Libraries/Components/Common/Menu/MenuOpener/MenuOpener";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    flex,
    height,
    padding, position, round,
    textColor, width, zIndex
} from "../../Libraries/Theme/Theme";
import ThemeButton from "../../Libraries/Components/Common/Button/ThemeButton";
import LocaleButton from "../../Libraries/Components/Common/Button/LocaleButton";
import {State} from "../Menu/State";
import {TicketsRunningLoader} from "../../Libraries/Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {useEffect} from "react";
import SpinningIcon from "../../Libraries/Components/Domain/Ticket/RunningIcon/SpinningIcon";
import {getIcon} from "../../Libraries/Helpers/Icon";
import Button from "../../Libraries/Components/Common/Button/Button";
import SearchbarState from "../../Libraries/Components/Domain/Location/Searchbar/State";

function Header() {
    const {isOpened} = State();
    const {ticketsRunning, fetch: fetchTicketsRunning} = TicketsRunningLoader();
    const {toggle, close} = SearchbarState();

    useEffect(() => {
        fetchTicketsRunning();
    }, []);


    return <div className={`${classes(zIndex('300'), position('fixed'), !isOpened ? width('100%') : width('100%-300px', '', 'md'), padding('xy', '10px'), textColor(), borderColor(), border('b'), height('70px'), flex('center', 'between'), backgroundColor('secondary'))}`}>
        <div>
            <MenuOpener/>
        </div>
        <div className={classes(flex("center", "center", '10px'))}>
            {ticketsRunning?.data !== undefined && <div onClick={close} className={classes(flex("center", "center"), width('40px'), height('40px'))}>
                <SpinningIcon href={`/tickets`} ticketsId={ticketsRunning?.data}/>
            </div>}
            <Button onClick={toggle} label={getIcon('Magnify', 'small')}/>
            <LocaleButton/>
            <ThemeButton/>
        </div>
    </div>;
}

export default Header;