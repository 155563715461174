import SendGoogleAnalyticsEventUseCase from "../UseCases/GoogleAnalytics/SendGoogleAnalyticsEventUseCase";

const sendGoogleAnalyticsEventUseCase = SendGoogleAnalyticsEventUseCase();

export const sendLocaleModalEvent = (userId: string|undefined = undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'localeModal', 'click');
export const sendSwitchThemeEvent = (userId: string|undefined = undefined, action: 'auto'|'click', theme: 'light'|'dark') => sendGoogleAnalyticsEventUseCase.execute(userId, 'switchTheme', action, {theme});
export const sendTryManagerInterfaceEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'tryManagerInterface', 'click');
export const sendTryUserInterfaceEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'tryUserInterface', 'click');
export const sendRedirectStorybookEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'redirectStorybook', 'click');
export const sendRedirectManagerInterfaceEvent = (userId: string|undefined, action: 'auto'|'click') => sendGoogleAnalyticsEventUseCase.execute(userId, 'redirectManagerInterface', action);
export const sendRedirectUserInterfaceEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'redirectUserInterface', 'click');
export const sendCreateOwnerAccountEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'createOwnerAccount', 'click');
export const sendRedirectLinkedInEvent = (userId: string|undefined, link: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'redirectLinkedIn', 'click', {link});
export const sendCreateOwnerAccountFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'createOwnerAccountFail', 'auto');
export const sendCreateOwnerAccountSuccessEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'createOwnerAccountSuccess', 'auto');
export const sendLoginSuccessEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'loginSuccess', 'auto');
export const sendLoginFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'loginFail', 'auto');
export const sendLogOutEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'logOut', 'click');
export const sendToggleFavoriteLocationEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'toggleFavoriteLocation', 'click', {locationId});
export const sendRegisterSuccessEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'registerSuccess', 'auto');
export const sendRegisterFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'registerFail', 'auto');
export const sendCancelTicketEvent = (userId: string|undefined, ticketId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'cancelTicket', 'click', {ticketId});
export const sendTakeTicketEvent = (userId: string|undefined, locationId: string, peopleNumber: number) => sendGoogleAnalyticsEventUseCase.execute(userId, 'takeTicket', 'click', {locationId, peopleNumber});
export const sendSwitchLocaleEvent = (userId: string|undefined, action: 'auto'|'click', locale: 'fr'|'en') => sendGoogleAnalyticsEventUseCase.execute(userId, 'switchLocale', action, {locale});
export const sendCreateCommentEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'createComment', 'click', {locationId});
export const sendShowCallNotificationModalEvent = (userId: string|undefined, ticketId: string, waitingCount: number) => sendGoogleAnalyticsEventUseCase.execute(userId, 'showCallNotificationModal', 'auto', {ticketId, waitingCount});
export const sendCloseCallNotificationModalEvent = (userId: string|undefined, ticketId: string, waitingCount: number) => sendGoogleAnalyticsEventUseCase.execute(userId, 'closeCallNotificationModal', 'click', {ticketId, waitingCount});
export const sendConfigurePasswordFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'configurePasswordFail', 'auto');
export const sendConfigurePasswordSuccessEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'configurePasswordSuccess', 'auto');
export const sendUpdateSettingsFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'updateSettingsFail', 'auto');
export const sendUpdateSettingsSuccessEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'updateSettingsSuccess', 'auto');
export const sendCreateLocationSuccessEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'createLocationSuccess', 'auto');
export const sendCreateLocationFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'createLocationFail', 'auto');
export const sendUpdateLocationFailEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'updateLocationFail', 'auto');
export const sendUpdateLocationSuccessEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'updateLocationSuccess', 'auto', {locationId});
export const sendUpdateLocationScheduleSuccessEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'updateLocationScheduleSuccess', 'auto', {locationId});
export const sendUpdateLocationScheduleFailEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'updateLocationScheduleFail', 'auto', {locationId});
export const sendAddLocationScheduleEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'addLocationSchedule', 'click', {locationId});
export const sendDeleteLocationScheduleEvent = (userId: string|undefined, locationId: string, scheduleId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'deleteLocationSchedule', 'click', {locationId, scheduleId});
export const sendSearchFastLocationEvent = (userId: string|undefined, keyword: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'searchFastLocations', 'auto', {keyword});
export const sendChooseFastLocationEvent = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'chooseFastLocations', 'click', {locationId});
export const sendRemoveFastLocationEvent = (userId: string|undefined) => sendGoogleAnalyticsEventUseCase.execute(userId, 'removeFastLocations', 'click');
export const sendChangeTicketStatusEvent = (userId: string|undefined, ticketId: string, status: string) => sendGoogleAnalyticsEventUseCase.execute(userId, 'changeTicketStatus', 'click', {ticketId, status});
