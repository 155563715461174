import {useBetween} from "use-between";
import {FavoriteLocationsLoader} from "../../Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import FavoriteApi from "../../Api/Calls/FavoriteApi";
import SendGoogleAnalyticsEventUseCase from "../GoogleAnalytics/SendGoogleAnalyticsEventUseCase";

function DeleteFavoriteLocation() {
    const {fetch: fetchFavoriteLocations} = FavoriteLocationsLoader();
    const sendGoogleAnalyticsEventUseCase = SendGoogleAnalyticsEventUseCase();

    const execute = (locationId: string) => {
        (async function() {
            try {
                await FavoriteApi.deleteFavoriteLocation(locationId);

                fetchFavoriteLocations(true);
            } catch (e: any) {
                console.error('Error while removing favorite for', locationId);
            }
        })();
    };

    return {execute};
}

export const DeleteFavoriteLocationUseCase = () => useBetween(DeleteFavoriteLocation);