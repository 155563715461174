import {useEffect, useState} from "react";
import AbstractIndexedLoader, {
    IndexedLoaderObjectInterface
} from "./AbstractIndexedLoader";
import {useBetween} from "use-between";
import {CACHE_TIME_THREE_SECONDS} from "../../../Constants";
import {GetDeviceIdUseCase} from "../../../UseCases/System/GetDeviceIdUseCase";
import {IsLoggedInUseCase} from "../../../UseCases/User/IsLoggedInUseCase";
import TicketApi from "../../Calls/TicketApi";

const cache = new AbstractIndexedLoader(CACHE_TIME_THREE_SECONDS);

function Tickets() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const getDeviceIdUseCase = GetDeviceIdUseCase();

    const [tickets, setTickets] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = async (locationId: string) => {
        return TicketApi.fetchTicket(locationId, getDeviceIdUseCase.execute(), isLoggedInUseCase.execute());
    };

    const fetch = (locationIds: string[], forceRefresh: boolean= false) => {
        cache.load(locationIds, apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setTickets(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getTicketById = (ticketId: string) => {
        return tickets[ticketId]?.data;
    };

    return {fetch, tickets, getTicketById};
}

export const TicketsLoader = () => useBetween(Tickets);