import Label from "../../../../Common/Label/Label";
import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";
import Translator from "../../../../../Utils/Translator";
import {LABEL_TICKETS_IN_QUEUE} from "../../../../../Constants/Labels";

const TRANSLATION_PREFIX = 'libraries__components_domain_location_common_label_ticket_in_progress__';

interface TicketsInProgressCounterInterface{
    locationId: string;
}

export function TicketsInProgressCounter({locationId}: TicketsInProgressCounterInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (location === undefined) {
        return null;
    }

    return <Label label={Translator.getTranslation(`${TRANSLATION_PREFIX}tickets_in_progress_counter`, {counter: location.ticketsToValidate})}/>
}

export function TicketsInProgressInQueue({locationId}: TicketsInProgressCounterInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (location === undefined) {
        return null;
    }

    return <Label label={LABEL_TICKETS_IN_QUEUE(<span key={`location-tickets-in-progress-in-queue`} className={`font-bold`}>{location.ticketsToValidate}</span>)}/>
}