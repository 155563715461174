import {useBetween} from "use-between";
import {useContext, useState} from "react";
import {LocaleContext} from "../../../Contexts/LocaleContext";

function LocaleModal() {
    const [opened, setOpened] = useState(false);

    const toggle = () => setOpened(() => !opened);

    const open = () => setOpened(() => true);

    return {opened, toggle, open};
}

const LocaleModalState = () => useBetween(LocaleModal);
export default LocaleModalState;