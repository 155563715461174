import {LogInUseCase} from "../../../../UseCases/User/LogInUseCase";
import InputLoginOrEmail from "../Common/Form/Input/InputLoginOrEmail";
import InputPassword from "../Common/Form/InputPassword/InputPassword";
import ButtonSubmit from "../Common/Form/Button/ButtonSubmit";
import {LABEL_ERROR} from "../../../../Constants/Labels";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import Translator from "../../../../Utils/Translator";
import {backgroundColor, border, classes, flex, margin, padding, round, textSize, width} from "../../../../Theme/Theme";

function LoginForm() {
    const {form, handleChange} = LogInUseCase();
    const logInUseCase = LogInUseCase();

    return <div className={classes(border(),backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'))} label={Translator.getTranslation('client__login_page__label__login_title')}/>
        <form onSubmit={logInUseCase.execute} className={classes(margin('t', '10px'))}>
            <div>
                <InputLoginOrEmail className={classes(width('100%'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange}/>
            </div>
            <div>
                <InputPassword className={classes(margin('t', '10px'), width('100%'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange}/>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
        </form>
    </div>;
}

export default LoginForm;