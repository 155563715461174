import MenuLink from "../MenuLink";
import {State} from "../../../../../../Layout/Menu/State";
import {CTA_HOME} from "../../../../../Constants/Labels";

function MenuLinkHome() {
    const {toggle} = State();

    return <MenuLink
        link={'/'}
        label={CTA_HOME}
        onClick={toggle}
    />
}

export default MenuLinkHome;