import QrCode from "../../../Common/QrCode/QrCode";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";
import {State} from "./State";
import Name from "../Common/Data/Name";
import {FRONT__CLIENT_LOCATION_SCAN_URL} from "../../../../Constants/FrontUrls";
import Modal from "../../../Common/Modal/Modal";

interface QrCodeModalInterface {
    locationId: string;
    alwaysShow?: boolean;
}

function QrCodeModal({locationId, alwaysShow}: QrCodeModalInterface) {
    const {fetch: fetchLocations, locations} = LocationsLoader();
    const {toggle, isOpened} = State();

    if (alwaysShow === undefined) {
        alwaysShow = false;
    }

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = locations[locationId]?.data;
    if (undefined === location) {
        return null;
    }

    return <Modal opened={isOpened || alwaysShow} onClickModalBackground={toggle}>
        <QrCode title={<Name locationId={locationId}/>} link={FRONT__CLIENT_LOCATION_SCAN_URL(locationId)}/>
    </Modal>;
}

export default QrCodeModal;