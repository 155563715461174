import {CommentsLoader} from "../../../../../Api/Loaders/IndexedLoaders/CommentsLoader";
import {useEffect} from "react";
import Label from "../../../../Common/Label/Label";

interface UsernameInterface {
    commentId: string;
}

function Username({commentId}: UsernameInterface) {
    const {fetch: fetchComments, getCommentById} = CommentsLoader();

    useEffect(() => {
        fetchComments([commentId]);
    }, []);

    const comment = getCommentById(commentId);

    if (comment === undefined) {
        return null;
    }

    return comment.user.login;
}

export default Username;