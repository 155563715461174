import {useEffect} from "react";
import SpinningCircle from "../../Ticket/RunningIcon/SpinningCircle";
import {
    LocationsLoader
} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {FavoriteLocationsLoader} from "../../../../Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import {GetTicketsByLocationIdUseCase} from "../../../../UseCases/Ticket/GetTicketsByLocationIdUseCase";
import {MediumFirstImage} from "../Common/Image/FirstImage";
import Label from "../../../Common/Label/Label";
import Translator from "../../../../Utils/Translator";
import {LABEL_COMMENT_COUNTER, LABEL_TICKET_COUNTER} from "../../../../Constants/Labels";
import ToLocation from "../Common/Redirection/ToLocation";
import FavoriteToggler from "../FavoriteToggler/FavoriteToggler";
import Name from "../Common/Data/Name";
import {
    backgroundColor, boldText,
    border,
    borderColor,
    classes,
    flex,
    padding,
    round,
    textSize,
    width
} from "../../../../Theme/Theme";
import LabelNewLine from "../../../Common/Label/LabelNewLine";

interface CardInterface {
    locationId: string;
}

function Card({locationId}: CardInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();
    const {fetch: fetchFavoriteLocations, favoriteLocations} = FavoriteLocationsLoader();
    const getTicketsByLocationIdUseCase = GetTicketsByLocationIdUseCase();

    useEffect(() => {
        fetchLocations([locationId]);
        fetchFavoriteLocations();
    }, []);

    const location = getLocationById(locationId);

    if (location === undefined) {
        return null;
    }
    return <div className={classes(border(), backgroundColor("secondary"), borderColor("secondary"), width('250px', 'max'), width('250px'), round())}>
        <div className={classes(flex("center", "between"), border('b'), padding('xy', '10px'))}>
            <div><FavoriteToggler locationId={locationId}/></div>
            <div className={classes(backgroundColor("secondary"), flex("center", 'left', '10px'))}>
                <SpinningCircle ticketIds={getTicketsByLocationIdUseCase.execute(locationId)}/>
                <Label label={LABEL_TICKET_COUNTER(location.ticketsToValidate)}/>
            </div>
        </div>
        <div className={classes(padding('xy', '10px'), border('b'), flex("center", "center"))} style={{backgroundColor: location.coverImageBackground}}>
            <ToLocation locationId={locationId}><MediumFirstImage locationId={locationId}/></ToLocation>
        </div>
        <div className={classes(padding('x', '10px'))}>
            <ToLocation locationId={locationId}><Label className={classes(boldText(), textSize('20px'))} label={<Name locationId={locationId}/>}/></ToLocation>
            <LabelNewLine italic={true} label={Translator.translate(LABEL_COMMENT_COUNTER(location.commentsCounter))}/>
        </div>
    </div>;
}

export default Card;