import {Navigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendLocationScannedPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function LocationScanned() {
    const {locationId} = useParams();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        if (undefined !== locationId) {
            sendLocationScannedPageView(getUserIdUseCase.execute(), locationId);
        }
    }, []);

    return <Navigate to={`/locations/${locationId}`}/>
}

export default LocationScanned;