export const locales: {
    [locale: string]: {
        label: string,
        value: string,
    }
} = {
    'en': {
        label: 'English - EN',
        value: 'en',
    },
    'fr': {
        label: 'Français - FR',
        value: 'fr',
    },
};