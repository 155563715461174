import Translator from "../../../../Utils/Translator";
import {ReactComponent as MagnifyIcon} from "../../../../Icons/Magnify.svg";
import {SearchLocationsUseCase} from "../../../../UseCases/Location/SearchLocationsUseCase";
import {SharedState as SearchResultsSharedState} from "../SearchResults/State";
import {LABEL_LOCATION_SEARCH_BY_KEYWORD} from "../../../../Constants/Labels";
import {backgroundColor, border, classes, padding, round, width} from "../../../../Theme/Theme";

function Searchbar() {
    const {keywordDebounce} = SearchLocationsUseCase();
    const searchLocationsUseCase = SearchLocationsUseCase();
    const {open, close} = SearchResultsSharedState();

    return <input
        placeholder={Translator.translate(LABEL_LOCATION_SEARCH_BY_KEYWORD)}
        type={`text`}
        className={classes(padding('xy', '10px'), width('100%'), backgroundColor("primary"), round(), border())}
        onChange={searchLocationsUseCase.execute}
        value={keywordDebounce ?? ''}
        onFocus={open}
        onBlur={() => close(keywordDebounce)}
    />;
}

export default Searchbar;