import {getLabel} from "../../../../Helpers/Label";
import {getIcon} from "../../../../Helpers/Icon";
import LabelNewLine from "../../Label/LabelNewLine";
import Link from "../../Link/Link";
import {backgroundColor, classes, flex, padding} from "../../../../Theme/Theme";

interface MenuLinkInterface {
    link: string;
    label: string|{id: string, variables: any};
    onClick?: () => void;
    icon?: string;
    useRefreshMode?: boolean;
}

function MenuLink({link, label, onClick, icon, useRefreshMode}: MenuLinkInterface) {
    if (onClick === undefined) {
        onClick = () => {};
    }

    useRefreshMode = useRefreshMode ?? false;

    const component = <div className={classes(flex('center', 'left', '10px'))}>
        {icon !== undefined && getIcon(icon, 'small')}
        <LabelNewLine label={getLabel(label)}/>
    </div>;

    return <div onClick={onClick}>
        <Link to={link} className={classes(padding("xy", "10px"), flex(), backgroundColor('secondary', true))} useRefreshMode={useRefreshMode}>
            {component}
        </Link>
    </div>;
}

export default MenuLink;