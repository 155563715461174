import {CommentsLoader} from "../../../../../Api/Loaders/IndexedLoaders/CommentsLoader";
import {useEffect} from "react";
import Translator from "../../../../../Utils/Translator";
import {LABEL_DATE_TIME} from "../../../../../Constants/Labels";

interface DateTimeInterface {
    commentId: string;
}

function Datetime({commentId}: DateTimeInterface) {
    const {fetch: fetchComments, getCommentById} = CommentsLoader();

    useEffect(() => {
        fetchComments([commentId]);
    }, []);

    const comment = getCommentById(commentId);

    if (undefined === comment) {
        return null;
    }

    return Translator.translate(LABEL_DATE_TIME(comment.creationDate));
}

export default Datetime;