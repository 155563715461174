import {useState} from "react";
import {useBetween} from "use-between";

function MenuState() {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggle = () => setIsOpened(() => !isOpened);
    const close = () => setIsOpened(() => false);

    return {isOpened, toggle, close};
}

export const State = () => useBetween(MenuState);