import {LocalStorage} from "../LocalStorage";
import {useBetween} from "use-between";
import {useState} from "react";

export interface UserAccountInterface {
    id: string;
    jwt: string;
    refreshTokenPassword: string;
}

export class UserAccountStorage extends LocalStorage {
    constructor() {
        super('user_account');
    }

    save(value: UserAccountInterface): void {super.save(value)};
    get(): UserAccountInterface|null {return super.get()};
}

function UserAccountState() {
    const cachedUser = new UserAccountStorage().get();
    const [user, setUser] = useState<UserAccountInterface|undefined>(cachedUser === null ? undefined : cachedUser);

    const saveUser = (userData: UserAccountInterface) => {
        setUser(() => userData);
        new UserAccountStorage().save(userData);
    };

    const removeUser = () => {
        setUser(() => undefined);
        new UserAccountStorage().remove();
    };

    return {user, saveUser, removeUser};
}

export const UserAccountCache = () => useBetween(UserAccountState);