import {ReactElement} from "react";
import {FRONT__COMMON__LOCATION_URL} from "../../../../../Constants/FrontUrls";
import Link from "../../../../Common/Link/Link";

interface ToLocationInterface {
    locationId: string;
    customClass?: string;
    children: ReactElement|ReactElement[]|string;
}

function ToLocation({locationId, customClass, children}: ToLocationInterface) {
    customClass = customClass ?? '';

    return <Link to={FRONT__COMMON__LOCATION_URL(locationId)} className={customClass}>
        {children}
    </Link>;
}

export default ToLocation;