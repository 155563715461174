import {useState} from "react";
import {LocalStorage} from "../../Caches/LocalStorage";
import {generateUUID} from "../../Utils/Uuid";
import {useBetween} from "use-between";

class DeviceIdCache extends LocalStorage {
    constructor() {
        super('device_id');
    }

    get() {
        let cache = super.get();
        if (cache === null) {
            cache = generateUUID();
            this.save(cache);
        }

        return cache;
    }
}

function GetDeviceId() {
    const [deviceId] = useState<string>(new DeviceIdCache().get());

    const execute = () => {
        return deviceId;
    }

    return {execute};
}

export const GetDeviceIdUseCase = () => useBetween(GetDeviceId);