import moment from "moment/moment";
import {CACHE_TIME_ONE_DAY} from "../../../Constants";

export interface IndexedLoaderObjectInterface {
    [id: string]: IndexedLoaderItemInterface;
}

export interface IndexedLoaderItemInterface {
    id: string,
    cacheDate: any,
    data: any,
    isLoading: boolean,
    isLoaded: boolean,
}

class AbstractIndexedLoader {
    cache: any = {};
    cacheFreshTime: number;

    constructor(cacheFreshTime: number = CACHE_TIME_ONE_DAY) {
        this.cacheFreshTime = cacheFreshTime;
    }
    isCacheFresh(cacheDate: any) {
        return moment().diff(moment(cacheDate)) < this.cacheFreshTime;
    }
    
    load(ids: string[], apiCall: any, forceRefresh: boolean = false) {
        for (const id of ids) {
            if (
                (forceRefresh && undefined !== this.cache[id])
                || (undefined !== this.cache[id] && !this.isCacheFresh(this.cache[id].cacheDate))
            ) {
                delete this.cache[id];
            }

            if (undefined === this.cache[id]) {
                this.cache[id] = {
                    id: id,
                    cacheDate: undefined,
                    data: undefined,
                    isLoading: true,
                    isLoaded: true,
                };

                const currentCacheObject = this;
                (async function() {
                    currentCacheObject.cache[id] = {
                        ...currentCacheObject.cache[id],
                        isLoading: false,
                        data: (await apiCall(id)).data,
                        cacheDate: moment()
                    }
                })();
            }
        }
    }

    hasLoading() {
        const ids = Object.keys(this.cache);
        for (const id of ids) {
            if (!this.cache[id].isLoaded || this.cache[id].isLoading) {
                return true;
            }
        }

        return false;
    }

    get() {
        return this.cache;
    }
}

export default AbstractIndexedLoader;