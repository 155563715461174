import {createContext, useState} from "react";
import TranslationCache from "../Caches/System/TranslationCache";

export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';

const defaultContext = {
    data: new TranslationCache().get(),
    dispatch: (actionType: string, payload?: any): void => {}
};

export interface TranslationContextInterface {
    data: any;
    dispatch: (actionType: string, payload?: any) => void;
}

export const TranslationContext = createContext<TranslationContextInterface>(defaultContext);
export const TranslationContextProvider = TranslationContext.Provider;

export const TranslationContextHook = () => {
    const cache = new TranslationCache();
    const cacheData = cache.get();

    const [translation, setTranslation] = useState(cacheData);

    const dispatchTranslation = (actionType: string, payload?: any): void => {
        switch (actionType) {
            case UPDATE_TRANSLATION:
                if (payload !== undefined) {
                    cache?.save(payload);
                    setTranslation(payload);
                }
                break;
        }
    }

    return [translation, dispatchTranslation];
}
