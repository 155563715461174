import {ApiManager} from "../../Utils/ApiManager";
import {
    CREATE_ADMIN_FORM_VALIDATOR_URL, CREATE_COMMENT_FORM_VALIDATOR_URL,
    LOCATION_COMMENTS_URL,
    PUBLIC_COMMENT_URL,
    PUBLIC_LOCATION_COMMENTS_URL
} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class CommentApi {
    static async fetchLocationComments(locationId: string) {
        try {
            const response = await ApiManager.get(PUBLIC_LOCATION_COMMENTS_URL(locationId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLocationComments', {locationId});
        }
    };

    static async fetchComment(commentId: string) {
        try {
            const response = await ApiManager.get(PUBLIC_COMMENT_URL(commentId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchComment');
        }
    };

    static async createComment(locationId: string, formData: any) {
        try {
            const response = await ApiManager.post(LOCATION_COMMENTS_URL(locationId), formData);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'createComment');
        }
    };
    static async fetchCreateCommentFormValidator() {
        try {
            const response = await ApiManager.get(CREATE_COMMENT_FORM_VALIDATOR_URL);

            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchCreateCommentFormValidator');
        }
    }
}

export default CommentApi;