import ReactGA from "react-ga";
import {FEATURES} from "../../../Constants/Server";
import {APP_NAME} from "../../../Constants/Constants";

let initialized = false;

function InitGoogleAnalyticsUseCase() {
    const execute = (forceInit: boolean = false) => {
        if (
            (forceInit && FEATURES[APP_NAME].googleAnalyticsTrackingId !== undefined)
            || (!initialized && FEATURES[APP_NAME].googleAnalyticsTrackingId !== undefined)
        ) {
            ReactGA.initialize(FEATURES[APP_NAME].googleAnalyticsTrackingId);
            initialized = true;
        }
    }

    return {execute};
}

export default InitGoogleAnalyticsUseCase;