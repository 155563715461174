import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./Pages/Login/Login";
import Homepage from "./Pages/Homepage/Homepage";
import Location from "./Pages/Location/Location";
import Tickets from "./Pages/Tickets/Tickets";
import Specialties from "./Pages/Specialties/Specialties";
import Register from "./Pages/Register/Register";
import {ApiManager} from "./Libraries/Utils/ApiManager";
import {AccountLogged, AccountUnlogged} from "./Libraries/RouterCondition/AccountCondition";
import LocationScanned from "./Pages/LocationScanned/LocationScanned";
import CallNotificationModal from "./Libraries/Components/Domain/Ticket/CallNotificationModal/CallNotificationModal";
import Favorites from "./Pages/Favorites/Favorites";
import {LogOutUseCase} from "./Libraries/UseCases/User/LogOutUseCase";
import {
    AutoCreateTicketCallNotificationsUseCase
} from "./Libraries/UseCases/Ticket/AutoCreateTicketCallNotificationsUseCase";
import {UserAccountCache} from "./Libraries/Caches/User/UserAccountCache";
import AccountSettings from "./Pages/AccountSettings/AccountSettings";

function Router() {
    AutoCreateTicketCallNotificationsUseCase();
    const {saveUser} = UserAccountCache();
    const logOutUseCase = LogOutUseCase();
    ApiManager.logInFunction = saveUser;
    ApiManager.logOutFunction = logOutUseCase.execute;

    return <>
        <CallNotificationModal/>
        <AccountLogged>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Homepage/>} />
                    <Route path="/locations/:locationId" element={<Location/>} />
                    <Route path="/locations/:locationId/scanned" element={<LocationScanned/>} />
                    <Route path="/tickets" element={<Tickets/>} />
                    <Route path="/specialties" element={<Specialties/>} />
                    <Route path="/favorites" element={<Favorites/>} />
                    <Route path="/account/settings" element={<AccountSettings/>} />

                    <Route path="/login" element={<Navigate to={'/'}/>} /> {/*re-rendering of /login page should redirect to homepage*/}
                    <Route path="/register" element={<Navigate to={'/'}/>} /> {/*re-rendering of /register page should redirect to homepage*/}
                </Routes>
            </BrowserRouter>
        </AccountLogged>
        <AccountUnlogged>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Homepage/>} />
                    <Route path="/locations/:locationId" element={<Location/>} />
                    <Route path="/locations/:locationId/scanned" element={<LocationScanned/>} />
                    <Route path="/tickets" element={<Tickets/>} />
                    <Route path="/register" element={<Register/>} />
                    <Route path="/favorites" element={<Favorites/>} />
                    <Route path="/login" element={<Login/>} />
                </Routes>
            </BrowserRouter>
        </AccountUnlogged>
    </>;
}

export default Router;