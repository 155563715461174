import Translator from "../Utils/Translator";
import {convertDate, convertTime} from "../Utils/DateTime";
import {ReactElement} from "react";

const TRANSLATION_PREFIX = Translator.generateTranslationPrefix('libraries', 'constants');

export const CTA_COPIED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'copied');
export const CTA_COPY_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'copy_link');
export const CTA_SUBMIT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'submit');
export const LABEL_EMAIL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'email');
export const LABEL_PASSWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'password');
export const LABEL_TEMPORARY_PASSWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'temporary_password');
export const LABEL_FIRSTNAME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'firstname');
export const LABEL_LASTNAME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'lastname');
export const LABEL_FULLNAME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'fullname');
export const LABEL_LOGIN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'login');
export const LABEL_ERROR = (error: string) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', error);
export const LABEL_SUCCESS = (success: string) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', success);
export const LABEL_REGISTER_SUCCESS_REDIRECTION = (timer: number) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'register_success_redirection', {timer});
export const LABEL_NEW_ADMIN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'new_admin');
export const LABEL_RESET_PASSWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'reset_password');
export const LABEL_TODO_LIST_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'todo_list_title');
export const LABEL_CONFIGURATION_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'configuration_title');
export const LABEL_SYSTEM_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'system_title');
export const LABEL_DATE_TIME = (dateTime: any) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'date_time', {date: convertDate(dateTime), time: convertTime(dateTime)});
export const LABEL_COMMENT_CONTENT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'comment_content');
export const LABEL_COMMENT_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'comment_title');
export const LABEL_COMMENT_COUNTER = (counter: number) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'comment_counter', {counter});
export const LABEL_TICKET_COUNTER = (counter: number) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_counter', {counter});
export const LABEL_COMMENT_FORM_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'comment_form_title');
export const LABEL_LOCATION_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_contact');
export const LABEL_LOCATION_QUEUE_SCHEDULE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_queue_schedule');
export const LABEL_LOCATION_QUEUE_SCHEDULE_DAY_NOT_CONFIGURED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_queue_schedule_day_not_configured');
export const LABEL_LOCATION_ADDRESS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_address');
export const LABEL_LOCATION_CITY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_city');
export const LABEL_LOCATION_ZIP_CODE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_zip_code');
export const LABEL_LOCATION_PHONE_NUMBER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_phone_number');
export const LABEL_LOCATION_CONTACT_EMAIL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_contact_email');
export const LABEL_LOCATION_SHORT_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_short_description');
export const LABEL_LOCATION_LONG_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_long_description');
export const LABEL_LOCATION_ADDITIONAL_ADDRESS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_additional_address');
export const LABEL_LOCATION_COVER_IMAGE_BACKGROUND = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_cover_image_background');
export const LABEL_LOCATION_SHORTCUT_BACKGROUND = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_shortcut_background');
export const LABEL_LOCATION_SHORTCUT_TEXT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_shortcut_text');
export const LABEL_LOCATION_ALLOW_COMMENTS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_allow_comments');
export const LABEL_LOCATION_QUEUE_EMERGENCY_STOP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_queue_emergency_stop');
export const LABEL_LOCATION_SHOW_COMMENTS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_show_comments');
export const LABEL_LOCATION_STYLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_style');
export const LABEL_LOCATION_COMMENT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_comment');
export const LABEL_CREATE_LOCATION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'create_location');
export const LABEL_LOCATION_NAME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_name');
export const LABEL_LOCATION_DESCRIPTION_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'description_title');
export const CTA_DROP_HERE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'drop_here');
export const CTA_DRAG_AND_DROP_HERE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'drag_and_drop_here');
export const LABEL_LOCATION_NAVBAR_DETAILS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_navbar_details');
export const LABEL_LOCATION_NAVBAR_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_navbar_contact');
export const LABEL_LOCATION_NAVBAR_COMMENTS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_navbar_comments');
export const LABEL_LOCATION_SEARCH_BY_KEYWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'search_location_by_keyword');
export const LABEL_ADMIN_SEARCH_BY_KEYWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'search_admin_by_keyword');
export const LABEL_OWNER_SEARCH_BY_KEYWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'search_owner_by_keyword');
export const LABEL_LOCATION_QUEUE_REQUEST_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_queue_request_description');
export const CTA_CONFIRM = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'confirm');
export const CTA_REGISTER_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'register_link');
export const CTA_LOGIN_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'login_link');
export const CTA_LOGIN_ADVANTAGES_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'login_advantages_link');
export const LABEL_TICKET_UNLOGGED_USERNAME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_unlogged_username');
export const CTA_QUEUE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'queue');
export const LABEL_NEW_OWNER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'new_owner');
export const LABEL_LOGIN_OR_EMAIL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'login_or_email');
export const LABEL_LOGIN_SHOWN_PUBLICLY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'login_shown_publicly');
export const LABEL_PASSWORD_CONFIRMATION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'password_confirmation');
export const LABEL_LOCATION_UPDATE_TO_SCHEDULE_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_update_to_schedule_link');
export const LABEL_LOCATION_UPDATE_TO_TICKETS_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_update_to_tickets_link');
export const LABEL_RESET_ALL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'reset_all');
export const LABEL_RESET_SUCCESS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'reset_success');
export const CTA_REVEAL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'reveal');
export const CTA_COPY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'copy');
export const LABEL_LOG_IN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'log_in');
export const LABEL_WELCOME_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'welcome_1');
export const LABEL_WELCOME_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'welcome_2');
export const CTA_LOG_IN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'log_in');
export const CTA_ACCOUNT_SETTINGS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'account_settings');
export const CTA_ACCOUNT_LOG_OUT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'account_logout');
export const CTA_CHOOSE_LOCATION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'choose_location');
export const CTA_SETTINGS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'settings');
export const CTA_TICKETS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'tickets');
export const CTA_SCHEDULE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'schedule');
export const CTA_HOME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'home');
export const CTA_ADMINS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'admins');
export const CTA_FAVORITES = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'favorites');
export const CTA_HELP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'help');
export const CTA_HISTORY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'history');
export const CTA_NOTIFICATIONS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'notifications');
export const CTA_OWNERS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'owners');
export const CTA_SPECIALTY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'specialties');
export const CTA_WHO_ARE_WE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'who_are_we');
export const CTA_RESTAURANTS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'restaurants');
export const CTA_TRANSLATIONS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'translations');
export const LABEL_FILTER_ZONE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'filter_zone');
export const CTA_CREATE_ADMIN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'create_admin');
export const CTA_CREATE_LOCATION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'create_location');
export const CTA_CREATE_OWNER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'create_owner');
export const LABEL_LOCATION_TICKETS_TITLE = (locationName: ReactElement|string) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'location_tickets_title', {locationName});
export const LABEL_TRANSLATION_KEY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'translation_key');
export const LABEL_SELECT_TRANSLATION_KEY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'select_translation_key');
export const LABEL_NO_TRANSLATION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'no_translation');
export const CTA_SAVE_TRANSLATION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'save_translation');
export const CTA_NAVBAR_HOME_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'navbar_home_link');
export const CTA_NAVBAR_FAVORITES_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'navbar_favorites_link');
export const CTA_NAVBAR_HISTORY_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'navbar_history_link');
export const CTA_NAVBAR_SETTINGS_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'navbar_settings_link');
export const LABEL_CONFIGURE_PASSWORD = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'configure_password');
export const LABEL_DAY_NAME = (dayName: string) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', dayName);
export const LABEL_TIME_HOUR_PLACEHOLDER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'time_hour_placeholder');
export const LABEL_TIME_MINUTE_PLACEHOLDER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'time_minute_placeholder');
export const LABEL_TIME_SEPARATOR = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'time_separator');
export const CTA_CREATE_SCHEDULE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'create_schedule');
export const CTA_SAVE_SCHEDULE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'save_schedule');
export const LABEL_SCHEDULE_FORM_SUCCESS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'schedule_form_success');
export const LABEL_SCHEDULE_FORM_ERROR = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'schedule_form_error');
export const LABEL_TICKETS_IN_QUEUE = (counter: ReactElement|string) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'tickets_in_queue', {counter});
export const LABEL_LOADING = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'loading');
export const LABEL_SECTION1_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section1_description');
export const LABEL_SECTION1_PROCESS1_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section1_process1_description');
export const LABEL_SECTION1_PROCESS2_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section1_process2_description');
export const LABEL_SECTION1_PROCESS3_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section1_process3_description');
export const LABEL_SLOGAN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'slogan');
export const LABEL_SECTION1_TRY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section1_try');
export const CTA_SECTION1_BE_OWNER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section1_be_owner');
export const CTA_SECTION1_BE_USER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section1_be_user');
export const LABEL_CHOOSE_LANGUAGE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'choose_language');
export const CTA_SECTION_1_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_1_menu');
export const CTA_SECTION_2_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_2_menu');
export const CTA_SECTION_3_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_3_menu');
export const CTA_SECTION_4_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_4_menu');
export const CTA_SECTION_6_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_6_menu');
export const CTA_SECTION_7_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_7_menu');
export const CTA_SECTION_8_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_8_menu');
export const CTA_SECTION_9_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_9_menu');
export const CTA_SECTION_5_MENU = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_5_menu');
export const LABEL_SECTION_2_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_title');
export const LABEL_SECTION_3_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_title');
export const LABEL_SECTION_4_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_title');
export const LABEL_SECTION_2_DESCRIPTION_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_description_1');
export const LABEL_SECTION_2_DESCRIPTION_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_description_2');
export const LABEL_SECTION_2_DESCRIPTION_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_description_3');
export const LABEL_SECTION_2_BEFORE_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_before_title');
export const LABEL_SECTION_2_AFTER_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_after_title');
export const LABEL_SECTION_2_BEFORE_ITEM_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_before_item_1');
export const LABEL_SECTION_2_BEFORE_ITEM_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_before_item_2');
export const LABEL_SECTION_2_BEFORE_ITEM_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_before_item_3');
export const LABEL_SECTION_2_BEFORE_ITEM_4 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_before_item_4');
export const LABEL_SECTION_2_BEFORE_ITEM_5 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_before_item_5');
export const LABEL_SECTION_2_AFTER_ITEM_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_after_item_1');
export const LABEL_SECTION_2_AFTER_ITEM_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_after_item_2');
export const LABEL_SECTION_2_AFTER_ITEM_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_after_item_3');
export const LABEL_SECTION_2_AFTER_ITEM_4 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_after_item_4');
export const LABEL_SECTION_2_AFTER_ITEM_5 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_2_after_item_5');
export const LABEL_SECTION_3_PROCESS_ITEM_0 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_process_item_0');
export const LABEL_SECTION_3_PROCESS_ITEM_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_process_item_1');
export const LABEL_SECTION_3_PROCESS_ITEM_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_process_item_2');
export const LABEL_SECTION_3_PROCESS_ITEM_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_process_item_3');
export const LABEL_SECTION_3_PROCESS_ITEM_4 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_process_item_4');
export const LABEL_SECTION_4_PROCESS_ITEM_0 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_process_item_0');
export const LABEL_SECTION_4_PROCESS_ITEM_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_process_item_1');
export const LABEL_SECTION_4_PROCESS_ITEM_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_process_item_2');
export const LABEL_SECTION_4_PROCESS_ITEM_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_process_item_3');
export const LABEL_SECTION_4_PROCESS_ITEM_4 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_process_item_4');
export const LABEL_SECTION_3_TRY_QUESTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_try_question');
export const LABEL_SECTION_4_TRY_QUESTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_4_try_question');
export const CTA_SECTION_3_TO_BO_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_3_to_bo_link');
export const CTA_SECTION_4_TO_CLIENT_LINK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_4_to_client_link');
export const CTA_SECTION_3_CREATE_OWNER_ACCOUNT_BUTTON = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'section_3_create_owner_account_button');
export const LABEL_SECTION_3_CREATE_OWNER_SUCCESS_TIMER = (timer: number) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_create_owner_success_timer', {timer});
export const LABEL_SECTION_3_OR = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_or');
export const LABEL_SECTION_5_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_title');
export const LABEL_SECTION_5_HIRE_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_hire_title');
export const LABEL_SECTION_5_BORIS_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_boris_title');
export const LABEL_SECTION_5_BORIS_GRADE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_boris_grade');
export const LABEL_SECTION_5_BORIS_EXP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_boris_exp');
export const LABEL_SECTION_5_BORIS_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_boris_contact');
export const LABEL_SECTION_5_NICOLAS_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_nicolas_title');
export const LABEL_SECTION_5_NICOLAS_GRADE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_nicolas_grade');
export const LABEL_SECTION_5_NICOLAS_EXP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_nicolas_exp');
export const LABEL_SECTION_5_NICOLAS_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_nicolas_contact');
export const LABEL_SECTION_5_DEVOPS_EXP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_devops_exp');
export const LABEL_SECTION_5_DEVOPS_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_devops_title');
export const LABEL_SECTION_5_DEVOPS_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_devops_contact');
export const LABEL_SECTION_5_DESIGNER_EXP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_designer_exp');
export const LABEL_SECTION_5_DESIGNER_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_designer_title');
export const LABEL_SECTION_5_DESIGNER_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_designer_contact');
export const LABEL_SECTION_5_DEVELOPER_EXP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_developer_exp');
export const LABEL_SECTION_5_DEVELOPER_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_developer_title');
export const LABEL_SECTION_5_DEVELOPER_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_developer_contact');
export const LABEL_SECTION_5_KAM_EXP = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_kam_exp');
export const LABEL_SECTION_5_KAM_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_kam_title');
export const LABEL_SECTION_5_KAM_CONTACT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_5_kam_contact');
export const LABEL_NO_TICKETS_IN_QUEUE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'no_tickets_in_queue');
export const CTA_TICKET_USER_DETAIL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_user_detail');
export const CTA_TICKET_RELATED_DETAIL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_related_detail');
export const CTA_TICKET_ACTION_CALL = (count: number) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_action_call', {count});
export const CTA_TICKET_ACTION_CALL_NO_COUNT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_action_call_no_count');
export const CTA_TICKET_ACTION_ACCEPT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_action_accept');
export const CTA_TICKET_ACTION_WAIT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_action_wait');
export const CTA_TICKET_ACTION_DECLINE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_action_decline');
export const CTA_TICKET_ACTION_BACK_IN_QUEUE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_action_back_in_queue');
export const LABEL_TICKET_USER_DETAIL_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_user_detail_title');
export const LABEL_TICKET_RELATED_DETAIL_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_related_detail_title');
export const LABEL_TICKET_USER_DETAIL_NO_ACCOUNT = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_user_detail_no_account_warning');
export const LABEL_TICKET_CODE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_code');
export const LABEL_TICKET_DATE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_date');
export const LABEL_TICKET_TIME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_time');
export const LABEL_CURRENT_TICKETS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'current_tickets');
export const LABEL_TICKETS_HISTORY = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'tickets_history');
export const LABEL_TICKET_STATUS = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'tickets_status');
export const LABEL_TICKET_ACCEPTED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_accepted');
export const LABEL_TICKET_REFUSED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_refused');
export const LABEL_TICKET_DISABLED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_disabled');
export const LABEL_SUB_LOGO_BACK_OFFICE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'sub_logo_back_office');
export const LABEL_SUB_LOGO_ADMIN = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'sub_logo_admin');
export const LABEL_SECTION_3_TRY_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_try_description');
export const LABEL_SECTION_3_TRY_DESCRIPTION2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_3_try_description2');
export const LABEL_SECTION_6_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_6_title');
export const LABEL_SECTION_6_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_6_description');
export const LABEL_SECTION_6_DESCRIPTION2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_6_description2');
export const LABEL_SECTION_6_PROCESS_ITEM_0 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_6_process_item_0');
export const LABEL_SECTION_6_PROCESS_ITEM_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_6_process_item_1');
export const LABEL_SECTION_6_PROCESS_ITEM_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_6_process_item_2');
export const LABEL_SECTION_6_PROCESS_ITEM_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_6_process_item_3');
export const LABEL_SECTION_7_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_7_title');
export const LABEL_SECTION_7_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_7_description');
export const LABEL_SECTION_7_MAIL_DESCRIPTION = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_7_mail_description');
export const LABEL_SECTION_7_MAIL_DESCRIPTION2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_7_mail_description2');
export const LABEL_SECTION_8_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'label_section_8_title');
export const LABEL_SECTION_8_SUB_TITLE_1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_8_sub_title_1');
export const LABEL_SECTION_8_MADE_OF = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_8_made_of');
export const LABEL_SECTION_8_SUB_TITLE_2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_8_sub_title_2');
export const LABEL_SECTION_8_SUB_TITLE_3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_8_sub_title_3');
export const LABEL_SECTION_8_USE_LATER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_8_sub_use_later');
export const LABEL_SECTION_9_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_title');
export const LABEL_SECTION_9_DESCRIPTION1 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description1');
export const LABEL_SECTION_9_DESCRIPTION2 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description2');
export const LABEL_SECTION_9_DESCRIPTION3 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description3');
export const LABEL_SECTION_9_DESCRIPTION4 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description4');
export const LABEL_SECTION_9_DESCRIPTION5 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description5');
export const LABEL_SECTION_9_DESCRIPTION6 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description6');
export const LABEL_SECTION_9_DESCRIPTION7 = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_9_description7');
export const LABEL_SECTION1_TRY_STORYBOOK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_1_try_storybook');
export const CTA_SECTION1_ACCESS_STORYBOOK = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'section_1_access_storybook');
export const LABEL_LOCALE_MODAL_WELCOME_TITLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'locale_modal_welcome_title');
export const LABEL_LOCALE_MODAL_CHOOSE_THEME = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'locale_modal_choose_theme');
export const LABEL_SINGLE_PEOPLE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'single_people');
export const LABEL_PLURAL_PEOPLE = (counter: number) => Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'plural_people', {counter});
export const LABEL_PEOPLE_NUMBER = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'people_number');
export const CTA_TICKETS_STATUS_QUEUE = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_status_queue');
export const CTA_TICKETS_STATUS_WAITING_LIST = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_status_waiting_list');
export const CTA_TICKETS_STATUS_ACCEPTED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_status_accepted');
export const CTA_TICKETS_STATUS_DECLINED = Translator.generateTranslationData(TRANSLATION_PREFIX, 'label', 'ticket_status_declined');
export const CTA_SCHEDULE_DUPLICATE_TO = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'schedule_duplicate_to');
export const CTA_SCHEDULE_DUPLICATE_TO_ALL = Translator.generateTranslationData(TRANSLATION_PREFIX, 'cta', 'schedule_duplicate_to_all');
