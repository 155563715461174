import {TicketsInProgressCounter} from "../Common/Label/TicketsInProgress";

interface TicketCounterTileInterface {
    locationId: string;
}

function TicketsCounterTile({locationId}: TicketCounterTileInterface) {
    return <div className={`rounded-full py-[3px] px-[5px] border`}>
        <TicketsInProgressCounter locationId={locationId}/>
    </div>;
}

export default TicketsCounterTile;