import {getWidth} from "../../../Helpers/Width";
import {getHeight} from "../../../Helpers/Height";
import {getCorners} from "../../../Helpers/Corners";
import {getTextSize} from "../../../Helpers/Text";
import {getSize} from "../../../Helpers/Size";
import {getLabel} from "../../../Helpers/Label";

interface TextareaInterface {
    name: string;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    onChange: (e: any) => void;
    displayBorders?: boolean;
    inputData: any;
    placeholder?: string|{id: string, variables: any};
    width?: 'auto'|'small'|'medium'|'large'|'full';
    height?: 'auto'|'small'|'medium'|'large'|'full';
    textSize?: 'auto'|'small'|'medium'|'large';
    corners?: 'default'|'medium-rounded'|'large-rounded'|'full-rounded';
    isFocused?: boolean;
    margin?: string;
    size?: 'full';
    className?: string;
}

function Textarea({name, onFocus, onBlur, onChange, displayBorders, inputData, placeholder, width, height, textSize, corners, margin, size, className}: TextareaInterface) {
    if (undefined === displayBorders) {
        displayBorders = true;
    }

    let properties: any = {};
    if (onFocus !== undefined) {
        properties.onFocus = onFocus;
    }
    if (onBlur !== undefined) {
        properties.onBlur = onBlur;
    }

    return <textarea
        {...properties}
        onChange={onChange}
        name={name}
        value={inputData.value}
        className={`py-[3px] px-[5px] border resize-none custom-scrollbar ${getCorners(corners)} ${getTextSize(textSize)} ${getHeight(height)} ${getWidth(width)} ${displayBorders && inputData.errors.length > 0 ? 'border-red-600' : ''} ${margin} ${getSize(size)} ${className ?? ''}`}
        placeholder={getLabel(placeholder)}
    />
}

export default Textarea;