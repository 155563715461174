import {CommentsLoader} from "../../../../../Api/Loaders/IndexedLoaders/CommentsLoader";
import {useEffect} from "react";

interface ContentInterface {
    commentId: string;
}

function Content({commentId}: ContentInterface) {
    const {fetch: fetchComments, getCommentById} = CommentsLoader();

    useEffect(() => {
        fetchComments([commentId]);
    }, []);

    const comment = getCommentById(commentId);
    if (undefined === comment) {
        return null;
    }

    return comment.comment;
}

export default Content;