import {useEffect, useState} from "react";
import {useBetween} from "use-between";
import {REGISTER_FORM_VALIDATOR_URL, REGISTER_URL} from "../../../../../Constants/ApiUrls";
import {ApiManager} from "../../../../../Utils/ApiManager";
import Translator from "../../../../../Utils/Translator";
import {FormHook} from "../../../../../Hooks/Form/FormHook";
import {GetUserIdUseCase} from "../../../../../UseCases/User/GetUserIdUseCase";
import {sendRegisterFailEvent, sendRegisterSuccessEvent} from "../../../../../GoogleAnalytics/Events";

const TRANSLATION_PREFIX = 'client__states_components_form_register_register_form_state__';

function RegisterFormState() {
    const {form, handleChange, shouldSubmit, setForm, setConstrains, handleFocus, handleBlur} = FormHook(['login', 'email', 'firstname', 'lastname', 'password', 'cpassword']);
    const [timer, setTimer] = useState<undefined|number>(undefined);
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        if (timer !== undefined && timer > 0) {
            const timeout = setTimeout(() => setTimer(timer-1), 1000);
            return () => clearTimeout(timeout);
        }

        if (timer === 0) {
            window.location.href = '/login';
        }
    }, [timer]);

    const submitForm = (e: any) => {
        e.preventDefault();
        // @ts-ignore
        if (isSamePassword(form.fields.cpassword.value) && shouldSubmit()) {
            setForm({
                ...form,
                isLoading: true,
                error: '',
                success: ''
            });
            (async function() {
                try {
                    await ApiManager.post(REGISTER_URL, {
                        // @ts-ignore
                        login: form.fields.login.value,
                        // @ts-ignore
                        email: form.fields.email.value,
                        // @ts-ignore
                        firstname: form.fields.firstname.value,
                        // @ts-ignore
                        lastname: form.fields.lastname.value,
                        // @ts-ignore
                        password: form.fields.password.value,
                    });

                    setTimer(() => 3);

                    sendRegisterSuccessEvent(getUserIdUseCase.execute());
                    setForm({
                        ...form,
                        success: `register_success`,
                        error: ``
                    })
                } catch (e: any) {
                    sendRegisterFailEvent(getUserIdUseCase.execute());
                    setForm({
                        ...form,
                        isLoading: false,
                        error: 'register_email_or_login_in_use',
                        success: ``
                    });
                    console.error('Error while trying to register', e);
                }
            })();
        } else {
            sendRegisterFailEvent(getUserIdUseCase.execute());
        }
    };

    useEffect(() => {
        (async function() {
            try {
                const response = await ApiManager.get(REGISTER_FORM_VALIDATOR_URL);
                setConstrains(response.data);
            } catch (e: any) {
                console.error('Error while fetching register form constrains', e);
            }
        })();
    }, []);

    const isSamePassword = (cpassword: string) => {
        const passwordConstrainLabelId = `${TRANSLATION_PREFIX}label__same_password`;
        const errors: string [] = [];
        const success: string[] = [];
        // @ts-ignore
        if (form.fields.password.value !== cpassword) {
            errors.push(passwordConstrainLabelId);
        } else {
            success.push(passwordConstrainLabelId);
        }

        setForm(() => ({
            ...form,
            fields: {
                ...form.fields,
                cpassword: {
                    // @ts-ignore
                    ...form.fields.cpassword,
                    errors,
                    success
                }
            }
        }));

        return success.length > 0;
    };

    return {submitForm, handleChange, handleFocus, handleBlur, form, isSamePassword, timer}
}

export const State = () => useBetween(RegisterFormState);