import {useBetween} from "use-between";
import {UserAccountCache, UserAccountInterface} from "../../Caches/User/UserAccountCache";
import {parseJwt} from "../../Utils/Jwt";

export class GetUserIdUseCaseClass {
    static execute(user?: UserAccountInterface) {
        if (user === undefined || user.jwt === undefined) {
            return undefined;
        }

        return parseJwt(user.jwt).userId;
    }
}

function GetUserId() {
    const {user} = UserAccountCache();

    const execute = () => {
        return GetUserIdUseCaseClass.execute(user);
    };

    return {execute};
}

export const GetUserIdUseCase = () => useBetween(GetUserId);