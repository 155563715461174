import {useBetween} from "use-between";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";

function GetTicketsByLocationId() {
    const {tickets} = TicketsLoader();

    const execute = (locationId: string) => {
        const ticketsToReturn = [];

        for (const ticketId of Object.keys(tickets)) {
            if (tickets[ticketId] === undefined || tickets[ticketId].data === undefined || !tickets[ticketId].isLoaded || tickets[ticketId].data.locationId !== locationId) {
                continue;
            }

            ticketsToReturn.push(ticketId);
        }

        return ticketsToReturn;
    };

    return {execute};
}

export const GetTicketsByLocationIdUseCase = () => useBetween(GetTicketsByLocationId);