import {useBetween} from "use-between";
import {UserAccountCache, UserAccountInterface} from "../../Caches/User/UserAccountCache";

export class IsLoggedInUseCaseClass {
    static execute (user: UserAccountInterface|undefined) {
        return user !== undefined && user.jwt !== undefined;
    }
}

function IsLoggedIn() {
    const {user} = UserAccountCache();

    const execute = () => {
        return IsLoggedInUseCaseClass.execute(user);
    }

    return {execute};
}

export const IsLoggedInUseCase = () => useBetween(IsLoggedIn);