import {useBetween} from "use-between";
import {UserAccountCache} from "../../Caches/User/UserAccountCache";
import {FormHook} from "../../Hooks/Form/FormHook";
import {ApiManager} from "../../Utils/ApiManager";
import {useEffect} from "react";
import {LOGIN_FORM_VALIDATOR_URL} from "../../Constants/ApiUrls";
import UserApi from "../../Api/Calls/UserApi";
import {GetUserIdUseCase} from "./GetUserIdUseCase";
import {sendLoginFailEvent, sendLoginSuccessEvent} from "../../GoogleAnalytics/Events";


function LogIn() {
    const {form, handleChange, shouldSubmit, setForm, setConstrains} = FormHook(['loginOrEmail', 'password']);
    const {saveUser} = UserAccountCache();
    const getUserIdUseCase = GetUserIdUseCase();

    const execute = (e: any) => {
        e.preventDefault();
        if (shouldSubmit()) {
            setForm({
                ...form,
                isLoading: true,
                error: '',
                success: ''
            });
            (async function() {
                const response = await UserApi.logIn(form);
                if (response.success) {
                    sendLoginSuccessEvent(response.data.id);
                    saveUser(response.data);
                    setForm({
                        ...form,
                        isLoading: false,
                        error: '',
                        success: ''
                    });
                } else {
                    sendLoginFailEvent(getUserIdUseCase.execute());
                    setForm({
                        ...form,
                        isLoading: false,
                        error: response.data,
                        success: ''
                    });
                }
            })();
        } else {
            sendLoginFailEvent(getUserIdUseCase.execute());
        }
    };

    useEffect(() => {
        (async function() {
            try {
                const response = await ApiManager.get(LOGIN_FORM_VALIDATOR_URL);
                setConstrains(response.data);
            } catch (e: any) {
                console.error('Error while fetching login form constrains', e);
            }
        })();
    }, []);

    return {form, handleChange, execute};
}

export const LogInUseCase = () => useBetween(LogIn);