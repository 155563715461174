import {useBetween} from "use-between";
import {FormHook} from "../../Hooks/Form/FormHook";
import {LocationsCommentsLoader} from "../../Api/Loaders/IndexedLoaders/LocationsCommentsLoader";
import CommentApi from "../../Api/Calls/CommentApi";
import {useEffect} from "react";
import commentApi from "../../Api/Calls/CommentApi";
import AbstractApi from "../../Api/Calls/AbstractApi";

function CreateLocationComment() {
    const {fetch: fetchLocationsComments} = LocationsCommentsLoader();
    const {form, handleChange, handleFocus, handleBlur, setForm, setConstrains} = FormHook(['title', 'comment']);

    useEffect(() => {
        (async function () {
            const response = await CommentApi.fetchCreateCommentFormValidator();
            if (AbstractApi.isSuccess(response)) {
                setConstrains(response.data);
            }
        })();
    }, []);

    const execute = (e: any, locationId: string) => {
        e.preventDefault();
        // @ts-ignore
        if (form.fields.comment.value === '' || form.fields.title.value === '') {
            return;
        }

        (async function() {
            try {
                await CommentApi.createComment(locationId, {
                    // @ts-ignore
                    title: form.fields.title.value,
                    // @ts-ignore
                    comment: form.fields.comment.value,
                    globalScore: 0,
                });
                setForm(() => ({
                    ...form,
                    fields: {
                        ...form.fields,
                        title: {
                            // @ts-ignore
                            ...form.fields.title,
                            value: ''
                        },
                        comment: {
                            // @ts-ignore
                            ...form.fields.comment,
                            value: ''
                        }
                    }
                }));
                fetchLocationsComments(locationId, true);
            } catch (e: any) {
                console.error('Error while adding favorite for', locationId);
            }
        })();
    };

    return {form, handleChange, handleFocus, handleBlur, setForm, execute};
}

export const CreateLocationCommentUseCase = () => useBetween(CreateLocationComment);