import {LocalStorage} from "../../LocalStorage";
import TicketEntity from "../../../Entities/TicketEntity";
import {useBetween} from "use-between";
import {useState} from "react";

export interface TicketCallNotificationsStorageInterface {
    [ticketId: string]: {
        ticket: TicketEntity;
        closed: boolean;
    }
}

export class TicketCallNotificationsStorage extends LocalStorage {
    constructor() {
        super('ticket_call_notifications');
    }

    save(value: TicketCallNotificationsStorageInterface): void {super.save(value)};
    get(): TicketCallNotificationsStorageInterface|null {return super.get()};
}

function TicketCallNotificationState() {
    const cachedData = new TicketCallNotificationsStorage().get();
    const [ticketCallNotifications, setTicketCallNotifications] = useState<TicketCallNotificationsStorageInterface|undefined>(cachedData === null || cachedData === undefined ? undefined : cachedData);

    const save = (value: TicketCallNotificationsStorageInterface) => {
        setTicketCallNotifications(() => value);
        new TicketCallNotificationsStorage().save(value);
    }

    return {ticketCallNotifications, save};
}

export const TicketCallNotificationCache = () => useBetween(TicketCallNotificationState);