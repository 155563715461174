import {State} from "./State";
import InputEmail from "../../Common/Form/Input/InputEmail";
import InputLogin from "../../Common/Form/Input/InputLogin";
import {
    LABEL_ERROR,
    LABEL_LOGIN_SHOWN_PUBLICLY,
    LABEL_REGISTER_SUCCESS_REDIRECTION,
    LABEL_SUCCESS
} from "../../../../../Constants/Labels";
import InputFirstname from "../../Common/Form/Input/InputFirstname";
import InputLastname from "../../Common/Form/Input/InputLastname";
import InputPasswordConfirmation from "../../Common/Form/InputPassword/InputPasswordConfirmation";
import Translator from "../../../../../Utils/Translator";
import InputPassword from "../../Common/Form/InputPassword/InputPassword";
import ButtonSubmit from "../../Common/Form/Button/ButtonSubmit";
import ToLogin from "../../Redirection/ToLogin";
import Label from "../../../../Common/Label/Label";
import LabelNewLine from "../../../../Common/Label/LabelNewLine";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize, width
} from "../../../../../Theme/Theme";

function RegisterForm() {
    const {submitForm, handleChange, handleFocus, handleBlur, form, isSamePassword, timer} = State();

    return <div className={classes(width('300px', "max"), width('300px'), border(),backgroundColor("secondary"), padding('xy', '10px'), round())}>
        <LabelNewLine className={classes(textSize('20px'), boldText())} label={Translator.getTranslation('client__register_page__label__register_title')}/>
        <form onSubmit={submitForm} className={classes(margin('t', '10px'))}>
            <div>
                <InputEmail className={classes(width('100%'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
            </div>
            <div>
                <InputLogin className={classes(width('100%'), margin('t', '10px'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
                <div className={`text-[10px]`}>{Translator.translate(LABEL_LOGIN_SHOWN_PUBLICLY)}</div>
            </div>
            <div>
                <InputFirstname className={classes(width('100%'), margin('t', '10px'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
            </div>
            <div>
                <InputLastname className={classes(width('100%'), margin('t', '10px'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
            </div>
            <div>
                <InputPassword className={classes(width('100%'), margin('t', '10px'), border(), backgroundColor('primary'))} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
            </div>
            <div>
                <InputPasswordConfirmation className={classes(width('100%'), margin('t', '10px'), border(), backgroundColor('primary'))} isSamePassword={isSamePassword} form={form} handleChange={handleChange} handleBlur={handleBlur} handleFocus={handleFocus}/>
            </div>
            <div className={classes(margin('t', '10px'))}>
                {form.error !== '' && <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.error) === 'object' ? form.error : LABEL_ERROR(form.error)} textColor={"error"}/>}
                {form.success !== '' && <>
                    <LabelNewLine className={classes(margin('t', '10px'))} label={typeof(form.success) === 'object' ? form.error : LABEL_SUCCESS(form.success)} textColor={"success"}/>
                    <LabelNewLine  className={classes(margin('t', '10px'))} textColor={"success"} label={timer !== undefined ? LABEL_REGISTER_SUCCESS_REDIRECTION(timer) : ''}/>
                </>}
            </div>
            <div className={classes(flex("center", 'right'), margin('t', '10px'))}>
                <ButtonSubmit/>
            </div>
            <div className={`text-right text-blue-500 underline text-[12px] mt-[10px]`}>
                <ToLogin/>
            </div>
        </form>
    </div>;
}

export default RegisterForm;