import {GetUserDataUseCase} from "../User/GetUserDataUseCase";

function FormatGoogleAnalyticsParamsUseCase() {
    const execute = (userId: undefined|string, params?: any) => {
        if (params === undefined) {
            params = {};
        }

        params.urlParams = window.location.search;
        if (params.urlParams === '') {
            delete params.urlParams;
        }

        params.userId = userId ?? 'none';

        return params;
    };

    return {execute};
}

export default FormatGoogleAnalyticsParamsUseCase;