import {classes, display, padding} from "../../../Theme/Theme";
import Button from "./Button";
import {getIcon} from "../../../Helpers/Icon";
import Label from "../Label/Label";
import {locales} from "../../../Constants/Constants";
import {useContext} from "react";
import {LocaleContext} from "../../../Contexts/LocaleContext";
import LocaleModalState from "../Modal/LocaleModalState";
import {State} from "../../../../Layout/Menu/State";
import {sendLocaleModalEvent} from "../../../GoogleAnalytics/Events";
import {GetUserIdUseCase} from "../../../UseCases/User/GetUserIdUseCase";

interface props {
    alwaysShow?: boolean;
}

function LocaleButton({alwaysShow}: props) {
    const {close: closeMenu} = State();
    const getUserIdUseCase = GetUserIdUseCase();

    if (alwaysShow === undefined) {
        alwaysShow = false;
    }

    const {toggle} = LocaleModalState();
    const localeContext = useContext(LocaleContext);

    const showButtonClasses = alwaysShow ? '' : classes(display('none'), display('block', 'sm'));

    const handleOpenLocaleModal = () => {
        sendLocaleModalEvent(getUserIdUseCase.execute());
        toggle();
        closeMenu();
    }

    return <div className={showButtonClasses}>
        <Button onClick={handleOpenLocaleModal} label={<>
            {getIcon('GlobeEurope', 'small')}
            <Label className={classes(padding('l', '5px'))} label={locales[localeContext.locale].label}/>
        </>}/>
    </div>;
}

export default LocaleButton;