import Translator from '../Utils/Translator';

const TRANSLATION_PREFIX = 'libraries__utils_form_utils__';

export interface FormValidationResponseInterface {
  errors: string[];
  success: string[];
}

const validateInput = (value: string|boolean|number|null, criterion: any): FormValidationResponseInterface => {
  let success: string[] = [];
  let errors: string[] = [];

  // @ts-ignore
  if (criterion.blank !== undefined && criterion.blank === true && value.length === 0) {
    return {success, errors};
  }

  for (const criterionName of Object.keys(criterion)) {
    let isSuccess = false;
    switch (criterionName) {
      case 'type':
        switch(criterion[criterionName]) {
          case 'alphanumeric':
            isSuccess = true;
            break;
          case 'email':
            // @ts-ignore
            isSuccess = /\S+@\S+\.\S+/.test(value);
            break;
          case 'flag':
            // @ts-ignore
            isSuccess = [true, false].includes(value);
            break;
        }
        break;
      case 'minLength':
        // @ts-ignore
        isSuccess = (criterion['blank'] !== undefined && criterion['blank'] === true && value.length === 0) || value.length >= criterion[criterionName];
        break;
      case 'maxLength':
        // @ts-ignore
        isSuccess = value.length <= criterion[criterionName];
        break;
      case 'required':
        isSuccess = criterion[criterionName] === true && value !== undefined;
        break;
      case 'blank':
        // @ts-ignore
        isSuccess = criterion[criterionName] === true || (criterion[criterionName] === false && value !== undefined && (value.length > 0 || value === true || value === false));
        break;
      case 'atLeastOneSpecialChar':
        isSuccess = criterion[criterionName] === true && atLeastOneSpecialChar(value);
        break;
      case 'atLeastOneLowerCase':
        isSuccess = criterion[criterionName] === true && atLeastOneLowerCase(value);
        break;
      case 'atLeastOneUpperCase':
        isSuccess = criterion[criterionName] === true && atLeastOneUpperCase(value);
        break;
      case 'atLeastOneNumber':
        isSuccess = criterion[criterionName] === true && atLeastOneNumber(value);
        break;
    }

    if (isSuccess) {
      success.push(criterionName);
    } else {
      errors.push(criterionName);
    }
  }

  return {success, errors};
};

export const translateCriterion = (name: string, value: string|number|boolean) => {
  if ([`${TRANSLATION_PREFIX}label__same_password`].includes(name)) {
    return Translator.getTranslation(name);
  }

  let label = Translator.getTranslation(`${TRANSLATION_PREFIX}label__${name}`);
  switch(name) {
    case 'type':
      label = Translator.getTranslation(`${TRANSLATION_PREFIX}label__${name}_${value}`);
      break;
    case 'minLength':
    case 'maxLength':
      label = label.replace('{value}', value);
      break;
  }

  return label;
};

const atLeastOneSpecialChar = (value: string|boolean|number|null): boolean => {
  const specialChars = ',;:!?./§/&~"#\'{([-|`^@à)]=}*_';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneLowerCase = (value: string|boolean|number|null): boolean => {
  const specialChars = 'abcdefghijklmnopqrstuvwxyz';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i])) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneUpperCase = (value: string|boolean|number|null): boolean => {
  const specialChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i])) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

const atLeastOneNumber = (value: string|boolean|number|null): boolean => {
  const specialChars = '0123456789';
  let containsCharsCounter = 0;
  for (let i = 0; i < specialChars.length; i++) {
    // @ts-ignore
    if (value.includes(specialChars[i]) === true) {
      containsCharsCounter++;
    }
  }

  return containsCharsCounter > 0;
}

export {validateInput};