import Label from "./Label";
import {Link} from "react-router-dom";
import {getBlockColor} from "../../../Helpers/Color";
import {getCorners} from "../../../Helpers/Corners";
import {getSize} from "../../../Helpers/Size";
import {getBoldText, getTextCenter, getTextItalic, getTextUnderline} from "../../../Helpers/Text";
import {getSelectable} from "../../../Helpers/Cursor";

interface TileLabelInterface {
    label: string|number;
    link?: string;
    onClick?: () => void;
    selected?: boolean;
    style?: 'gray-primary'|'blue-primary';
    textSize?: 'small'|'medium'|'large';
    size?: 'small'|'medium'|'large';
    underline?: boolean;
    italic?: boolean;
    selectable?: boolean;
}

function TileLabel({label, link, onClick, selected, style, size, italic, underline}: TileLabelInterface) {
    if (selected === undefined) {
        selected = false;
    }

    const component = <div onClick={onClick} className={`py-[3px] px-[5px] border ${getTextCenter('xy')} ${getSize(size)} ${getCorners('full-rounded')} ${getBlockColor(style, true, selected)} ${getBoldText(selected ?? false )} ${getTextUnderline(underline)} ${getTextItalic(italic)}`}>
        <Label label={label}/>
    </div>;

    if (link !== undefined) {
        return <Link to={link}>
            {component}
        </Link>;
    }

    return component;
}

export default TileLabel;