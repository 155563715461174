import Translator from "../../../../Utils/Translator";
import {ReactComponent as CircledWarning} from "../../../../Icons/CircledWarning.svg";
import {
    GetFirstTicketCallNotificationToShowUseCase
} from "../../../../UseCases/Ticket/GetFirstTicketCallNotificationToShowUseCase";
import {classes, margin, padding, textColor} from "../../../../Theme/Theme";
import Modal from "../../../Common/Modal/Modal";
import {LABEL_FULLNAME, LABEL_LOGIN, LABEL_TICKET_CODE} from "../../../../Constants/Labels";
import Label from "../../../Common/Label/Label";
import {useEffect} from "react";
import CloseTicketCallNotificationUseCase from "../../../../UseCases/Ticket/CloseTicketCallNotificationUseCase";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";
import {
    sendCloseCallNotificationModalEvent,
    sendShowCallNotificationModalEvent
} from "../../../../GoogleAnalytics/Events";

const TRANSLATION_PREFIX = 'client__components_ticket_ticket_call_notification_modal__';

function CallNotificationModal() {
    const getFirstTicketCallNotificationToShowUseCase = GetFirstTicketCallNotificationToShowUseCase();
    const closeTicketCallNotification = CloseTicketCallNotificationUseCase();
    const getUserIdUseCase = GetUserIdUseCase();

    const notification = getFirstTicketCallNotificationToShowUseCase.execute();

    useEffect(() => {
        if (notification !== undefined) {
            sendShowCallNotificationModalEvent(getUserIdUseCase.execute(), notification.id, notification.waitingCount);
        }
    }, []);

    const handleCloseTicketCallNotification = () => {
        if (notification !== undefined) {
            sendCloseCallNotificationModalEvent(getUserIdUseCase.execute(), notification.id, notification.waitingCount);
            closeTicketCallNotification.execute();
        }
    }

    if (notification === undefined) {
        return null;
    }

    // @ts-ignore
    return <Modal opened={notification} onClickCloseModalButton={handleCloseTicketCallNotification}>
        <div className={classes(textColor(), padding('xy', '10px'))}>
            <div className={`text-[25px] flex gap-[10px] mb-[10px]`}>
                {/*@ts-ignore*/}
                <CircledWarning className={`w-[25px]`}/>{notification.locationName}
            </div>
            <div>
                {/*@ts-ignore*/}
                {Translator.getTranslation(`${TRANSLATION_PREFIX}label__notification_call`, {counter: <span key={`ticket-notification-${notification.locationId}`} className={`font-bold`}>{notification.waitingCount}</span>})}
            </div>
            <div className={classes(margin('t', '10px'))}>
                {notification.user !== null ? <>
                    <div><Label label={LABEL_LOGIN}/>: {notification.user.login}</div>
                    <div><Label label={LABEL_FULLNAME}/>: {notification.user.firstname} {notification.user.lastname}</div>
                </> : <div>
                    <Label label={LABEL_FULLNAME}/>: {notification.userName}
                </div>}
                <div><Label label={LABEL_TICKET_CODE}/>: {notification.code}</div>
            </div>
        </div>
    </Modal>;
}

export default CallNotificationModal;