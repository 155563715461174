import SendGoogleAnalyticsPageViewUseCase from "../UseCases/GoogleAnalytics/SendGoogleAnalyticsPageViewUseCase";

const sendGoogleAnalyticsPageViewUseCase = SendGoogleAnalyticsPageViewUseCase();
export const sendFavoritesPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Favorites');
export const sendLocationPageView = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Location', {locationId});
export const sendLocationScannedPageView = (userId: string|undefined, locationId: string) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'LocationScanned', {locationId});
export const sendRegisterPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Register');
export const sendHomepagePageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Homepage');
export const sendLoginPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Login');
export const sendAccountSettingsPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'AccountSettings');
export const sendConfigurePasswordPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'ConfigurePassword');
export const sendLocationsPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Locations');
export const sendCreateLocationPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'CreateLocation');
export const sendUpdateLocationPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'UpdateLocation');
export const sendUpdateLocationSchedulePageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'UpdateLocationSchedule');
export const sendTicketsPageView = (userId: string|undefined) => sendGoogleAnalyticsPageViewUseCase.execute(userId, 'Tickets');



