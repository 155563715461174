import {ReactComponent as CircledCross} from "../../../Icons/CircledCross.svg";
import {ReactComponent as CircledCheck} from "../../../Icons/CircledCheck.svg";
import {translateCriterion} from "../../../Utils/FormUtils";
import Translator from "../../../Utils/Translator";
import {getSize} from "../../../Helpers/Size";
import {getCorners} from "../../../Helpers/Corners";
import {getTextSize} from "../../../Helpers/Text";
import {getLabel} from "../../../Helpers/Label";
import {
    backgroundColor,
    border,
    borderColor,
    classes,
    margin,
    overflow,
    padding,
    round,
    textSize
} from "../../../Theme/Theme";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;

interface InputInterface {
    inputData: any;
    onChange: (e: any) => void;
    placeholder?: string|{id: string, variables: undefined};
    name: string;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    isFocused?: boolean;
    displayBorders?: boolean;
    size?: 'small'|'medium'|'large'|'full';
    corners?: 'default'|'medium-rounded'|'large-rounded'|'full-rounded';
    className?: string;
}

function Input({inputData, onChange, placeholder, name, onFocus, onBlur, displayBorders, isFocused, size, corners, className}: InputInterface) {
    if (undefined === displayBorders) {
        displayBorders = true;
    }

    if (onFocus === undefined) {
        onFocus = () => {};
    }

    if (onBlur === undefined) {
        onBlur = () => {};
    }

    if (isFocused === undefined) {
        isFocused = false;
    }

    let dynamicBorderClass = '';
    if (inputData.errors.length > 0) {
        dynamicBorderClass = classes(border(), borderColor('error'));
    }

    return <>
        <input
            placeholder={getLabel(placeholder)}
            type={`text`}
            name={name}
            value={inputData.value}
            onChange={onChange}
            className={classes(dynamicBorderClass, padding('xy', '10px'), textSize('15px'), round(), className ?? '')}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete={`off`}
        />
        {(inputData.focus || isFocused) && (inputData.errors.length > 0 || inputData.success.length > 0) && <div className={classes(border(), margin('t', '5px'), backgroundColor("secondary"), overflow('hidden'), textSize('15px'), round(), dynamicBorderClass)}>
            {inputData.errors.length > 0 && inputData.errors.map((error: string, index: number) => <div key={`error-${index}`} className={`flex gap-[10px] items-center p-[5px] text-red-600`}>
                <div>
                    <CircledCross width={`20px`}/>
                </div>
                <div>
                    {translateCriterion(error, inputData.constrains[error])}
                </div>
            </div>)}
            {inputData.success.length > 0 && inputData.success.map((success: string, index: number) => <div key={`success-${index}`} className={`flex gap-[10px] items-center p-[5px] text-green-600`}>
                <div>
                    <CircledCheck width={`20px`}/>
                </div>
                <div>
                    {translateCriterion(success, inputData.constrains[success])}
                </div>
            </div>)}
        </div>}
    </>;
}

export default Input;