import {UserAccountInterface, UserAccountStorage} from "../Caches/User/UserAccountCache";
import {TOKEN_REFRESH_URL} from "../Constants/ApiUrls";

export const REQUEST_METHOD_GET = 'GET';
export const REQUEST_METHOD_POST = 'POST';
export const REQUEST_METHOD_PUT = 'PUT';

export const REQUEST_METHOD_DELETE = 'DELETE';

export const REQUEST_TYPE_JSON = 'json';

export const REQUEST_TYPE_IMAGE = 'image';
type REQUEST_METHODS = 'GET' | 'POST' | 'DELETE' | 'PUT';

let isRefreshing = false;

interface RequestOptions {
    method: REQUEST_METHODS,
        headers: {
        authorization?: string;
        'Content-Type'?: string;
    },
    body?: string;
}

export class ApiManager {
    static logInFunction: undefined|((user: UserAccountInterface) => void) = undefined;
    static logOutFunction: undefined|(() => void) = undefined;

    static async get(url: string) {
        return ApiManager.fireRequest(REQUEST_METHOD_GET, url);
    }

    static async post(url: string, payload: any) {
        return ApiManager.fireRequest(REQUEST_METHOD_POST, url, payload);
    }

    static async postImage(url: string, payload: any) {
        return ApiManager.fireRequest(REQUEST_METHOD_POST, url, payload, REQUEST_TYPE_IMAGE);
    }

    static async put(url: string, payload: any) {
        return ApiManager.fireRequest(REQUEST_METHOD_PUT, url, payload);
    }

    static async delete(url: string, payload?: any) {
        return ApiManager.fireRequest(REQUEST_METHOD_DELETE, url, payload);
    }

    static async fireRequest(method: REQUEST_METHODS, url: string, payload: any = undefined, type = REQUEST_TYPE_JSON): Promise<any> {
        const requestOptions: RequestOptions = {
            method,
            headers: {}
        };

        let userCache = new UserAccountStorage().get();
        if (userCache !== null) {
            requestOptions.headers.authorization = userCache.jwt;
        }

        if (payload !== undefined && type === REQUEST_TYPE_JSON) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(payload);
        }

        if (payload !== undefined && type === REQUEST_TYPE_IMAGE) {
            requestOptions.body = payload;
        }

        let response = await fetch(url, requestOptions);
        if (!response.ok && response.status === 401 && requestOptions.headers.authorization !== undefined) {
            if (!isRefreshing) {
                isRefreshing = true;
            } else {
                await delay(100);
                return ApiManager.fireRequest(method, url, payload, type);
            }

            const refreshTokenResponse = await ApiManager.refreshToken();

            if (refreshTokenResponse.ok) {
                if (ApiManager.logInFunction !== undefined) {
                    ApiManager.logInFunction(refreshTokenResponse.data);
                    isRefreshing = false;
                }

                userCache = new UserAccountStorage().get();
                if (userCache?.jwt) {
                    requestOptions.headers.authorization = userCache.jwt;
                }
                response = await fetch(url, requestOptions);
            } else {
                if (ApiManager.logOutFunction !== undefined) {
                    ApiManager.logOutFunction();
                    throw refreshTokenResponse;
                }
            }
        }

        if (!response.ok) {
            throw await response.json();
        }

        return {data: await response.json(), status: response.status, ok: response.ok};
    }

    static async refreshToken() {
        const userCache = new UserAccountStorage().get();
        const requestOptions: any = {
            method: 'POST',
            headers: {
                Authorization: userCache?.jwt,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({refreshTokenPassword: userCache?.refreshTokenPassword})
        };

        let response = await fetch(TOKEN_REFRESH_URL, requestOptions);

        return {data: await response.json(), status: response.status, ok: response.ok};
    }

    static returnSuccessResponse(response: any) {
        return {
            success: true,
            data: response
        };
    }

    static returnErrorResponse(response: any) {
        return {
            success: false,
            data: response,
        }
    }
}

const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
