import {useEffect} from "react";
import {LocationsLoader} from "../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import Label from "../../../Common/Label/Label";
import Name from "../Common/Data/Name";
import ToLocation from "../Common/Redirection/ToLocation";
import {backgroundColor, boldText, classes, flex, height, round, width} from "../../../../Theme/Theme";

interface FavoriteCircleInterface {
    locationId: string;
}

function FavoriteCircle({locationId}: FavoriteCircleInterface) {
    const {fetch: fetchLocations, locations} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = locations[locationId]?.data;
    if (location === undefined) return null;

    return <ToLocation locationId={location.id}>
        <div style={{backgroundColor: location.shortcutBackground, color: location.shortcutText}} className={classes(width('100px'), height('100px'), flex("center", "center"), round("full"), boldText(), backgroundColor("secondary"))}>
            <Label label={<Name locationId={locationId}/>}/>
        </div>
    </ToLocation>;
}

export default FavoriteCircle;