class AbstractApi {
    static returnSuccessResponse(response: any) {
        return {
            success: true,
            data: response.data,
        }
    }

    static returnErrorResponse(response: any, subject: string, parameters: any = {}) {
        console.error(`Error while executing ${subject} with parameters`, parameters);

        return {
            success: false,
            data: response,
        }
    }

    static isSuccess(response: { success: boolean, data: any }) {
        return response.success;
    }
}

export default AbstractApi;