import Textarea from "../../../../../Common/Form/Textarea";
import {LABEL_COMMENT_CONTENT} from "../../../../../../Constants/Labels";

interface TextareaContentInterface {
    form: any;
    handleChange: any;
    handleBlur: any;
    handleFocus: any;
    className?: string;
}

function TextareaContent({form, handleChange, handleBlur, handleFocus, className}: TextareaContentInterface) {
    return <Textarea inputData={form.fields.comment}
        name={'comment'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        height={'small'}
        placeholder={LABEL_COMMENT_CONTENT}
        size={"full"}
        className={className ?? ''}
    />;
}

export default TextareaContent;