import ComponentDevice from "../../../Common/ComponentDevice/ComponentDevice";
import TileLabel from "../../../Common/Label/TileLabel";
import Translator from "../../../../Utils/Translator";
import {State} from "./State";
import {
    LABEL_LOCATION_NAVBAR_COMMENTS,
    LABEL_LOCATION_NAVBAR_DETAILS
} from "../../../../Constants/Labels";
import {backgroundColor, border, classes, padding, round} from "../../../../Theme/Theme";
import Label from "../../../Common/Label/Label";

function Navbar() {
    const {setSection, section} = State();

    const showItem = (name: string, translationData: any) => {
        return <div className={classes(border(), padding('xy', '10px'), round(), backgroundColor("primary", true, section === name))} onClick={() => setSection(() => name)}>
            <Label label={translationData}/>
        </div>;
    };

    return <div className={`flex gap-[10px]`}>
        {showItem('details', LABEL_LOCATION_NAVBAR_DETAILS)}
        {showItem('comments', LABEL_LOCATION_NAVBAR_COMMENTS)}
    </div>;
}

export default Navbar;