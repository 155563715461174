import Translator from "../../../../../../Utils/Translator";
import Input from "../../../../../Common/Form/Input";
import {LABEL_LOGIN_OR_EMAIL} from "../../../../../../Constants/Labels";

interface InputEmailInterface {
    form: any;
    handleChange: any;
    className?: string;
}

function InputEmail({form, handleChange, className}: InputEmailInterface) {
    return <Input
        inputData={form.fields.loginOrEmail}
        name={'loginOrEmail'}
        onChange={handleChange}
        placeholder={Translator.translate(LABEL_LOGIN_OR_EMAIL)}
        className={className ?? ''}
    />;
}

export default InputEmail;