import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";

interface ContactEmailInterface {
    locationId: string;
}

function ContactEmail({locationId}: ContactEmailInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (location === undefined) {
        return null;
    }

    return location.contactEmail;
}

export default ContactEmail;