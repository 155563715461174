import Button from "../../Button/Button";
import Translator from "../../../../Utils/Translator";
import {IsLoggedInUseCase} from "../../../../UseCases/User/IsLoggedInUseCase";
import {GetUserDataUseCase} from "../../../../UseCases/User/GetUserDataUseCase";
import {LogOutUseCase} from "../../../../UseCases/User/LogOutUseCase";
import {getIcon} from "../../../../Helpers/Icon";
import Label from "../../Label/Label";
import {CTA_ACCOUNT_LOG_OUT, CTA_ACCOUNT_SETTINGS} from "../../../../Constants/Labels";
import {
    backgroundColor,
    boldText,
    border,
    borderColor,
    borderSize,
    classes,
    flex,
    padding, round, textColor,
    textSize, width
} from "../../../../Theme/Theme";

function MenuLogged() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const getUserDataUseCase = GetUserDataUseCase();
    const logOutUseCase = LogOutUseCase();

    if (!isLoggedInUseCase.execute()) {
        return null;
    }

    const userData = getUserDataUseCase.execute();

    if (userData === undefined) {
        return null;
    }

    return <div className={classes(flex("center", "left", '10px'), padding('y', '20px'), padding('x', '10px'), backgroundColor('ternary'), textColor("ternary"))}>
        <div>
            <div className={classes(border('xy'), borderSize('2px'), borderColor('ternary'), round("full"), padding('xy', '10px'))}>
                {getIcon('User', "small")}
            </div>
        </div>
        <div>
            <Label label={userData.login} className={classes(textSize('20px'), boldText())}/>
            <div className={`flex gap-[10px]`}>
                <Button size={"full"} label={CTA_ACCOUNT_SETTINGS} onClick={() => document.location.href = '/account/settings'}/>
                <Button size={"full"} label={CTA_ACCOUNT_LOG_OUT} onClick={logOutUseCase.execute}/>
            </div>
        </div>
    </div>;
}

export default MenuLogged;