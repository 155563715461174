import {ReactSVG} from "react-svg";
import {getIconSize} from "./Size";
import {getSpin} from "./Spin";
import {classes, spin, textColor} from "../Theme/Theme";

export const iconList = [
    'ArrowPath',
    'ArrowRight',
    'Bars3',
    'CircledWarning',
    'CircledCheck',
    'Clock',
    'CopiedClipboard',
    'CopyClipboard',
    'Heart',
    'Home',
    'Magnify',
    'Message',
    'QrCode',
    'RaiseHand',
    'Shop',
    'ThinLeftArrow',
    'ThinRightArrow',
    'Ticket',
    'User',
];

export function getIcon(iconName: string, size: 'extra-small'|'small'|'medium'|'large', spinValue?: 'fast'|'', color: 'primary'|'ternary' = 'primary') {
    const iconPath = require(`../Icons/${iconName}.svg`);

    return <ReactSVG src={iconPath} className={`${getIconSize(size)} ${classes(spin(spinValue))}`}/>;
}