import {useBetween} from "use-between";
import {FavoriteLocationsLoader} from "../../Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import FavoriteApi from "../../Api/Calls/FavoriteApi";

function CreateFavoriteLocation() {
    const {fetch: fetchFavoriteLocations} = FavoriteLocationsLoader();

    const execute = (locationId: string) => {
        (async function() {
            await FavoriteApi.createFavoriteLocation(locationId);
            fetchFavoriteLocations(true);
        })();
    };

    return {execute};
}

export const CreateFavoriteLocationUseCase = () => useBetween(CreateFavoriteLocation);