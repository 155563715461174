import {useBetween} from "use-between";
import {useState} from "react";

function State() {
    const [isOpened, setIsOpened] = useState(false);

    const toggle = () => setIsOpened(() => !isOpened);
    const close = () => setIsOpened(() => false);

    return {isOpened, toggle, close};
}

const SearchbarState = () => useBetween(State);
export default SearchbarState;