import {classes, display} from "../../../Theme/Theme";
import Button from "./Button";
import {getIcon} from "../../../Helpers/Icon";
import {ThemeCache} from "../../../Caches/System/ThemeCache";
import useQuery from "../../../Hooks/Query/UseQuery";
import {sendSwitchThemeEvent} from "../../../GoogleAnalytics/Events";
import {GetUserIdUseCase} from "../../../UseCases/User/GetUserIdUseCase";

interface props {
    alwaysShow?: boolean;
    onSwitched?: () => void;
}

function ThemeButton({alwaysShow, onSwitched}: props) {
    const getUserIdUseCase = GetUserIdUseCase();

    if (alwaysShow === undefined) {
        alwaysShow = false;
    }

    const themeCache = ThemeCache();
    const theme = useQuery().get('theme');
    if (theme !== null && ['light', 'dark'].includes(theme)) {
        // @ts-ignore
        sendSwitchThemeEvent(getUserIdUseCase.execute(), 'auto', theme);
        // @ts-ignore
        themeCache.save(theme);
    }

    const handleManualSetTheme = (theme: 'light'|'dark') => {
        sendSwitchThemeEvent(getUserIdUseCase.execute(), 'click', theme);
        themeCache.save(theme);
        if (onSwitched !== undefined) onSwitched();
    };

    const showButtonClasses = alwaysShow ? '' : classes(display('none'), display('block', 'sm'));

    return <div className={showButtonClasses}>
        <div className={`flex justify-center gap-[10px]`}>
            {themeCache.theme === 'light' && <Button onClick={() => handleManualSetTheme('dark')} label={getIcon('Sun', 'small')}/>}
            {themeCache.theme === 'dark' && <Button onClick={() => handleManualSetTheme('light')} label={getIcon('Moon', 'small')}/>}
        </div>
    </div>;
}

interface ButtonProps {
    onSwitched?: () => void;
}
export function ThemeDarkButton({onSwitched}: ButtonProps) {
    const getUserIdUseCase = GetUserIdUseCase();

    const handleManualSetTheme = (theme: 'light'|'dark') => {
        sendSwitchThemeEvent(getUserIdUseCase.execute(), 'click', theme);
        themeCache.save(theme);
        if (onSwitched !== undefined) onSwitched();
    };

    const themeCache = ThemeCache();

    return <Button onClick={() => handleManualSetTheme('dark')} label={getIcon('Moon', 'small')} selected={themeCache.theme === 'dark'}/>;
}

export function ThemeLightButton({onSwitched}: ButtonProps) {
    const getUserIdUseCase = GetUserIdUseCase();

    const handleManualSetTheme = (theme: 'light'|'dark') => {
        sendSwitchThemeEvent(getUserIdUseCase.execute(), 'click', theme);
        themeCache.save(theme);
        if (onSwitched !== undefined) onSwitched();
    };

    const themeCache = ThemeCache();

    return <Button onClick={() => handleManualSetTheme('light')} label={getIcon('Sun', 'small')} selected={themeCache.theme === 'light'}/>;
}

export default ThemeButton;