import React, {ReactElement} from "react";
import {
    backgroundColor,
    borderColor,
    classes,
    flex,
    height, margin, padding, position,
    textColor, textSize,
    width, zIndex
} from "../../Libraries/Theme/Theme";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import LocaleModal from "../../Libraries/Components/Common/Modal/LocaleModal";
import {State} from "../Menu/State";
import SearchbarState from "../../Libraries/Components/Domain/Location/Searchbar/State";
import Searchbar from "../../Libraries/Components/Domain/Location/Searchbar/Searchbar";
import SearchResults from "../../Libraries/Components/Domain/Location/SearchResults/SearchResults";

interface PropsInterface {
    children: ReactElement|string;
    centeredContent?: boolean;
    className?: string;
}

function PageTemplate({children, centeredContent, className}: PropsInterface) {
    const {isOpened} = State();
    const {isOpened: searchbarIsOpened} = SearchbarState();
    if (centeredContent === undefined) {
        centeredContent = false;
    }

    return <div className={`${classes(height('100vh', "min"), backgroundColor(), flex(), width('100%'), textColor(), textSize("15px"))}`}>
        <LocaleModal/>
        <Menu/>
        <div className={classes(width('100%'), !isOpened ? '' : margin('l', '300px', 'md'))}>
            <div className={`${classes(borderColor(), height('70px'))}`}>
                <Header/>
            </div>
            {searchbarIsOpened && <div className={classes(position("fixed"), zIndex('300'), width('100%'), !isOpened ? '' : 'md:w-[calc(100%-300px)]', backgroundColor("ternary"), padding('xy', '10px'))}>
                <Searchbar/>
                <SearchResults/>
            </div>}
            <div className={classes(height('100vh-90px', 'min'), padding('xy', '10px'), width('1280px', 'max'), width('100%'), flex(centeredContent ? 'center' : 'top', "center"), margin('xy', 'auto'), className ?? '')}>
                {children}
            </div>
        </div>
    </div>;
}

export default PageTemplate;