import {ReactElement} from "react";
import {getLabel} from "../../../Helpers/Label";
import {getSize} from "../../../Helpers/Size";
import {getBoldText, getTextItalic, getTextSize, getTextUnderline} from "../../../Helpers/Text";
import {getCorners} from "../../../Helpers/Corners";
import {getBlockColor, getHoverTextColor, getTextColor} from "../../../Helpers/Color";
import {classes, textAlign, textSize, underline} from "../../../Theme/Theme";

interface LabelNewLineInterface {
    label: string|number|ReactElement|{id: string, variables: any};
    textSize?: 'small'|'medium'|'large'|'extra-large'|'huge'; // deprecated
    size?: 'small'|'medium'|'large'|'full';
    boldText?: boolean;
    onClick?: (e: any) => void;
    border?: string;
    padding?: string;
    margin?: string;
    corners?: 'default'|'medium-rounded'|'large-rounded'|'full-rounded';
    style?: 'default'|'gray-primary'|'blue-primary';
    textColor?: 'success'|'error'|''|'white';
    textColorHover?: 'white'|'';
    italic?: boolean;
    textAlignValue?: 'center';
    textSizeValue?: '15px'|'20px'|'30px'|'50px';
    underlineValue?: boolean;

    className?: string;
}

function LabelNewLine({label, size, onClick, boldText, border, padding, corners, style, textColor, textColorHover, italic, margin, textAlignValue, textSizeValue, className}: LabelNewLineInterface) {
    return <div className={`overflow-hidden text-ellipsis ${getTextColor(textColor)} ${getHoverTextColor(textColorHover)} ${getBlockColor(style)} ${padding ?? ''} ${margin ?? ''} ${border ?? ''} ${getSize(size)} ${getBoldText(boldText)} ${getTextItalic(italic)} ${classes(textAlign(textAlignValue), textSize(textSizeValue))} ${className}`} onClick={onClick}>{getLabel(label)}</div>;
}

export default LabelNewLine;