import {useBetween} from "use-between";
import {useState} from "react";

function State() {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const open = () => {
        setIsOpened(() => true);
    }

    const close = (keyword?: string) => {
        if (keyword !== undefined && keyword.length > 0) {
            return;
        }
        setIsOpened(() => false);
    };

    return {isOpened, open, close};
}

export const SharedState = () => useBetween(State);