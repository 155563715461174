import QRCode from "react-qr-code";
import Copy from "../Copy/Copy";
import Translator from "../../../Utils/Translator";
import {ReactElement} from "react";
import {CTA_COPIED, CTA_COPY} from "../../../Constants/Labels";
import {border, classes, flex, padding, textSize} from "../../../Theme/Theme";
import Label from "../Label/Label";

const TRANSLATION_PREFIX = 'libraries__components_qrcode__';

interface QrCodeInterface {
    title: string|ReactElement;
    link: string;
}
function QrCode({link, title}: QrCodeInterface) {
    return <div>
        <div className={classes(padding('xy', '10px'), border('b'))}>
            <Label label={title} className={classes(textSize('20px'))}/>
        </div>
        <div className={classes(flex("center", "center"), padding('y', '10px'), border('b'))}>
            <QRCode value={link} />
        </div>
        <div className={classes(padding('xy', '10px'))}>
            <Copy size={"full"} stringToCopy={link} copyLabel={CTA_COPY} copiedLabel={CTA_COPIED}/>
        </div>
    </div>;
}

export default QrCode;