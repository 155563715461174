import {useBetween} from "use-between";
import {TicketCallNotificationCache} from "../../Caches/Domain/Ticket/TicketCallNotificationsCache";

function GetFirstTicketCallNotificationToShow() {
    const {ticketCallNotifications} = TicketCallNotificationCache();

    const execute = () => {
        if (undefined === ticketCallNotifications) {
            return undefined;
        }

        const ticketIds = Object.keys(ticketCallNotifications);
        for (const ticketId of ticketIds) {
            if (!ticketCallNotifications[ticketId].closed && ticketCallNotifications[ticketId].ticket.waitingCount > 0) {
                return ticketCallNotifications[ticketId].ticket;
            }
        }

        return undefined;
    };

    return {execute};
}

export const GetFirstTicketCallNotificationToShowUseCase = () => useBetween(GetFirstTicketCallNotificationToShow);