import {useEffect, useState} from "react";
import AbstractValueLoader, {
    ValueLoaderObjectInterface,
} from "./AbstractValueLoader";
import {useBetween} from "use-between";
import {IsLoggedInUseCase} from "../../../UseCases/User/IsLoggedInUseCase";
import FavoriteApi from "../../Calls/FavoriteApi";
import {APP_NAME} from "../../../../Constants/Constants";

const cache = new AbstractValueLoader();

function FavoriteLocations() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const [favoriteLocations, setFavoriteLocations] = useState<ValueLoaderObjectInterface|undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = async () => {
        return FavoriteApi.fetchFavoriteLocations();
    };

    const fetch = (forceRefresh: boolean = false) => {
        // @ts-ignore
        if (APP_NAME === 'storybook' || !isLoggedInUseCase.execute()) {
            return;
        }

        cache.load(apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                // @ts-ignore
                const cachedData = {...cache.get()};
                // @ts-ignore
                setFavoriteLocations(() => ({
                    ...cachedData,
                    // @ts-ignore
                    data: cachedData.data === undefined ? [] : cachedData.data.filter((specialtyId: string, index: number) => cachedData.data.indexOf(specialtyId) === index)
                }));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    return {fetch, favoriteLocations};
}

export const FavoriteLocationsLoader = () => useBetween(FavoriteLocations);