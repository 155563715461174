import {useEffect, useState} from "react";
import {useBetween} from "use-between";
import AbstractIndexedLoader, {
    IndexedLoaderObjectInterface
} from "./AbstractIndexedLoader";
import CommentApi from "../../Calls/CommentApi";

const cache = new AbstractIndexedLoader();

function LocationsComments() {
    const [locationsComments, setLocationsComments] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = async (locationId: string) => {
        return CommentApi.fetchLocationComments(locationId);
    };

    const fetch = (locationId: string, forceRefresh: boolean = false) => {
        cache.load([locationId], apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setLocationsComments(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    return {fetch, locationsComments};
}

export const LocationsCommentsLoader = () => useBetween(LocationsComments);