import {useEffect, useState} from "react";
import TicketEntity from "../../../../Entities/TicketEntity";
import Translator from "../../../../Utils/Translator";
import {getBackgroundColor} from "../../../../Utils/Ticket";
import {Link} from "react-router-dom";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import Button from "../../../Common/Button/Button";
import {TicketsLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {GetColorUseCase} from "../../../../UseCases/Ticket/GetColorUseCase";
import {TicketsRunningLoader} from "../../../../Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {DeleteTicketUseCase} from "../../../../UseCases/Ticket/DeleteTicketUseCase";
import {TicketsBefore} from "../Common/Label/TicketsBefore";
import {
    boldText,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize,
    width
} from "../../../../Theme/Theme";
import Label from "../../../Common/Label/Label";
import {LABEL_PEOPLE_NUMBER, LABEL_TICKET_CODE} from "../../../../Constants/Labels";
import {GetUserIdUseCase} from "../../../../UseCases/User/GetUserIdUseCase";
import {sendCancelTicketEvent} from "../../../../GoogleAnalytics/Events";

const TRANSLATION_PREFIX = 'libraries__components_domain_ticket_card__';

interface TicketCardInterface {
    ticketId: string;
    showLocationName?: boolean;
}

function Card({ticketId, showLocationName}: TicketCardInterface) {
    showLocationName = showLocationName ?? true;

    const {tickets, fetch: fetchTickets} = TicketsLoader();
    const getColorUseCase = GetColorUseCase();
    const cancelTicketUseCase = DeleteTicketUseCase();
    const {fetch: fetchTicketsRunning, ticketsRunning} = TicketsRunningLoader();

    const [cancelRequest, setCancelRequest] = useState<boolean>(false);
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        fetchTicketsRunning();
    }, []);

    useEffect(() => {
        fetchTickets([ticketId]);
    }, [ticketsRunning]);

    const ticket = tickets[ticketId]?.data;

    if (ticket === undefined) {
        return null;
    }

    const getTicketDescription = (ticket: TicketEntity) => {
        if (ticket.ticketType !== null && ticket.ticketType !== undefined && ticket.ticketType.prefix !== undefined) {
            return ticket.ticketType.description;
        }

        return '';
    };

    const handleConfirmedCancelation = () => {
        sendCancelTicketEvent(getUserIdUseCase.execute(), ticket.id);
        cancelTicketUseCase.execute(ticket.locationId, ticketId);
        setCancelRequest(false);
    }

    return <div className={classes(margin('t', '10px'), border(), round(), getBackgroundColor(ticket, 'secondary'), width('300px', '', 'sm'), width('100%'))}>
        <div className={classes(padding('xy', '10px'), border('b'), flex('center', "between", '10px'))}>
            {showLocationName ?
                <Link to={`/locations/${ticket.locationId}`}><LabelNewLine className={classes(boldText(), textSize('20px'))} label={ticket.locationName}/></Link>
                : <div/>
            }
            <div className={`italic flex items-center gap-[5px]`}>
                <TicketsBefore ticketId={ticketId}/>
                <div className={`border-${getColorUseCase.execute(ticket.id)} border-[2px] border-dashed spinning-circle w-[15px] h-[15px] rounded-full`}/>
            </div>
        </div>
        {cancelRequest ? <div className={`p-[10px]`}>
            <div>
                {Translator.getTranslation(`${TRANSLATION_PREFIX}label__cancel_description`)}
            </div>
            <div className={`flex justify-between items-center mt-[10px] gap-[5px]`}>
                <div className={`w-[50%] justify-center flex`}><Button size={'full'} style={'gray-secondary'} onClick={handleConfirmedCancelation} label={Translator.getTranslation(`${TRANSLATION_PREFIX}cta__cancel_confirm`)}/></div>
                <div className={`w-[50%] justify-center flex`}><Button size={'full'} style={'gray-secondary'} onClick={() => setCancelRequest(false)} label={Translator.getTranslation(`${TRANSLATION_PREFIX}cta__cancel_revert`)}/></div>
            </div>
        </div> : <div className={`flex justify-between items-center p-[10px]`}>
            <div>
                <LabelNewLine italic={true} label={getTicketDescription(ticket)}/>
                <div>{ticket.userName} {ticket.waitingCount > 0 && `(x${ticket.waitingCount})`}</div>
                <div><Label label={LABEL_PEOPLE_NUMBER}/>: {ticket.peopleNumber}</div>
                <div><Label label={LABEL_TICKET_CODE}/>: {ticket.code}</div>
            </div>
            <div>
                {!cancelRequest && <Button style={"gray-secondary"} onClick={() => setCancelRequest(true)} label={Translator.getTranslation(`${TRANSLATION_PREFIX}cta__cancel`)}/>}
            </div>
        </div>}
    </div>;
}

export default Card;