import Navbar from "../../Libraries/Components/Domain/Location/Navbar/Navbar";
import Header from "../../Libraries/Components/Domain/Location/Header/Header";
import TicketWidget from "../../Libraries/Components/Domain/Location/TickeWidget/TicketWidget";
import Details from "../../Libraries/Components/Domain/Location/Details/Details";
import Contact from "../../Libraries/Components/Domain/Location/Contact/Contact";
import Comments from "../../Libraries/Components/Domain/Location/Comments/Comments";
import {State} from "../../Libraries/Components/Domain/Location/Navbar/State";
import {useParams} from "react-router-dom";
import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {classes, flex, width} from "../../Libraries/Theme/Theme";
import {useEffect} from "react";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendLocationPageView} from "../../Libraries/GoogleAnalytics/PageViews";

function Location() {
    const {locationId} = useParams();
    const {section} = State();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        if (locationId !== undefined) {
            sendLocationPageView(getUserIdUseCase.execute(), locationId);
        }
    }, []);

    if (locationId === undefined) {
        return null;
    }

    return <PageTemplate>
        <div className={classes(width('100%'), flex("top", 'left', '10px'))}>
            <div className={`mb-[10px] sm:min-w-[300px] sm:w-[300px] w-full`}>
                <Header locationId={locationId}/>
                <TicketWidget locationId={locationId}/>
                <Contact locationId={locationId}/>
            </div>
            <div className={`sm:w-[calc(100%-310px)] w-full`}>
                <div className={`flex whitespace-nowrap overflow-x-auto gap-[10px] mb-[10px] custom-scrollbar`}>
                    <Navbar/>
                </div>
                {(section === 'details' || section === 'contact') && <Details locationId={locationId}/>}
                {(section === 'comments') && <div className={`flex w-full gap-[10px]`}>
                    <Comments locationId={locationId}/>
                </div>}
            </div>
        </div>
    </PageTemplate>;
}

export default Location;