export function getWidth(width?: 'auto'|'small'|'medium'|'large'|'full') {
    if (width === undefined) {
        width = 'auto';
    }

    if (width === 'small') {
        return 'w-[100px]';
    }

    if (width === 'medium') {
        return 'w-[200px]';
    }

    if (width === "large") {
        return 'w-[300px]';
    }

    if (width === 'full') {
        return 'w-full';
    }

    return '';
}