import {useBetween} from "use-between";
import {useEffect, useState} from "react";
import AbstractIndexedLoader, {IndexedLoaderObjectInterface} from "./AbstractIndexedLoader";
import LocationOpeningApi from "../../Calls/LocationOpeningApi";

const cache = new AbstractIndexedLoader();

function LocationsOpenings() {
    const [locationsOpenings, setLocationsOpenings] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);

    const apiCall = (locationId: string) => {
        return LocationOpeningApi.fetchLocationOpenings(locationId);
    };

    const fetch = (locationId: string, forceRefresh: boolean = false) => {
        cache.load([locationId], apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined);
        }
    };

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setLocationsOpenings(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getLocationOpeningsByLocationId = (locationId: string) => {
        return locationsOpenings[locationId]?.data;
    }

    return {fetch, locationsOpenings, isLoading, getLocationOpeningsByLocationId};
}
export const LocationsOpeningsLoader = () => useBetween(LocationsOpenings);