import {useBetween} from "use-between";
import {GetDeviceIdUseCase} from "../System/GetDeviceIdUseCase";
import {TicketsRunningLoader} from "../../Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {LocationsLoader} from "../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {IsLoggedInUseCase} from "../User/IsLoggedInUseCase";
import TicketApi from "../../Api/Calls/TicketApi";

function DeleteTicket() {
    const getDeviceId = GetDeviceIdUseCase();
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {setRefreshData, setShouldRefresh} = TicketsRunningLoader();
    const {fetch: fetchLocations} = LocationsLoader();

    const execute = (locationId: string, ticketId: string) => {
        (async function() {
            await TicketApi.deleteTicket(locationId, ticketId, getDeviceId.execute(), isLoggedInUseCase.execute());
            setRefreshData(() => ({
                timer: 0,
            }));
            setShouldRefresh(() => true);
            fetchLocations([locationId], true);
        })();
    };

    return {execute};
}

export const DeleteTicketUseCase = () => useBetween(DeleteTicket);