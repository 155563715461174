import {useBetween} from "use-between";
import {FavoriteLocationsLoader} from "../../Api/Loaders/ValueLoaders/FavoriteLocationsLoader";

function IsFavoriteLocation() {
    const {favoriteLocations} = FavoriteLocationsLoader();

    const execute = (locationId: string) => {
        return favoriteLocations?.data.includes(locationId);
    }

    return {execute};
}

export const IsFavoriteLocationUseCase = () => useBetween(IsFavoriteLocation);