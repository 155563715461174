export function getBlockColor(style?: 'default'|'gray-primary'|'gray-secondary'|'blue-primary'|'', selectable?: boolean, selected?: boolean) {
    if (selectable === undefined) {
        selectable = false;
    }

    if (undefined === style || style === 'default') {
        return `${selectable ? 'hover:bg-slate-400 hover:text-white' : ''} ${selected ? `bg-slate-400 text-white` : ''}`;
    }

    if (style === 'gray-primary') {
        return `bg-slate-200 ${selectable ? 'hover:bg-slate-500 hover:text-white' : ''} ${selected ? `bg-slate-500 text-white` : ''}`;
    }

    if (style === 'gray-secondary') {
        return `bg-slate-500 ${selectable ? 'hover:bg-slate-800 hover:text-white' : ''} ${selected ? `bg-slate-800 text-white` : ''}`;
    }

    if (style === 'blue-primary') {
        return `bg-sky-200 ${selectable ? 'hover:bg-sky-500 hover:text-white' : ''} ${selected ? `bg-sky-500 text-white` : ''}`;
    }

    return '';
}

export function getTextColor(color?: 'success'|'error'|''|'white') {
    if (color === undefined) {
        color = '';
    }

    if (color === 'success') {
        return 'text-green-500';
    }

    if (color === 'error') {
        return 'text-red-500';
    }

    if (color === 'white') {
        return 'text-white';
    }

    return '';
}

export function getHoverTextColor(color?: ''|'white') {
    if (color === undefined) {
        color = '';
    }
    if (color === 'white') {
        return 'hover:text-white';
    }

    return '';
}