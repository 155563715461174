import {CommentsLoader} from "../../../../../Api/Loaders/IndexedLoaders/CommentsLoader";
import {useEffect} from "react";

interface TitleInterface {
    commentId: string;
}

function Title({commentId}: TitleInterface) {
    const {fetch: fetchComments, getCommentById} = CommentsLoader();

    useEffect(() => {
        fetchComments([commentId]);
    }, []);

    const comment = getCommentById(commentId);

    if (comment === undefined) {
        return null;
    }

    return comment.title;
}

export default Title;