import {ReactElement} from "react";
import {getBoldText, getTextCenter, getTextSize} from "../../../Helpers/Text";
import {getSize} from "../../../Helpers/Size";
import {getCorners} from "../../../Helpers/Corners";
import {getLabel} from "../../../Helpers/Label";
import {getBlockColor} from "../../../Helpers/Color";
import {backgroundColor, border, borderColor, classes, flex, margin, textAlign, textColor} from "../../../Theme/Theme";

interface ButtonInterface {
    onClick?: (e: any) => void;
    type?: 'button'|'reset'|'submit';
    label?: string|{id: string, variables: any}|ReactElement;
    selected?: boolean;
    boldText?: boolean;
    style?: 'gray-primary'|'gray-secondary'|'blue-primary'; // deprecated
    color?: 'primary'|'ternary'|'secondary';
    size?: 'auto'|'small'|'medium'|'large'|'full';
    textSize?: 'small'|'medium'|'large';
    corners?: 'default'|'medium-rounded'|'large-rounded'|'full-rounded';
    marginValue?: '10';
    marginDirectionValue?: 't';
    className?: string;
}

function Button({label, size, type, onClick, corners, selected, textSize, color, marginDirectionValue, marginValue, className}: ButtonInterface) {
    return <button type={type} onClick={onClick} className={`px-[5px] py-[3px] ${getCorners(corners)} ${getSize(size)} ${getTextSize(textSize)} ${classes(borderColor(color), border(), backgroundColor(color, true, selected), flex('center', 'center'), textColor(color))} ${className}`}>
        {getLabel(label)}
    </button>;
}

export default Button;