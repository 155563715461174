import PageTemplate from "../../Layout/PageTemplate/PageTemplate";
import {useEffect} from "react";
import Translator from "../../Libraries/Utils/Translator";
import FavoriteCircle from "../../Libraries/Components/Domain/Location/Favorite/FavoriteCircle";
import {FavoriteLocationsLoader} from "../../Libraries/Api/Loaders/ValueLoaders/FavoriteLocationsLoader";
import {IsLoggedInUseCase} from "../../Libraries/UseCases/User/IsLoggedInUseCase";
import {classes, flex, width} from "../../Libraries/Theme/Theme";
import {GetUserIdUseCase} from "../../Libraries/UseCases/User/GetUserIdUseCase";
import {sendFavoritesPageView} from "../../Libraries/GoogleAnalytics/PageViews";

const TRANSLATION_PREFIX = 'client__favorites_page__';

function Favorites() {
    const isLoggedInUseCase = IsLoggedInUseCase();
    const {fetch: fetchFavoriteLocations, favoriteLocations} = FavoriteLocationsLoader();
    const getUserIdUseCase = GetUserIdUseCase();

    useEffect(() => {
        sendFavoritesPageView(getUserIdUseCase.execute());
        fetchFavoriteLocations();
    }, []);

    if (!isLoggedInUseCase.execute()) {
        return <PageTemplate centeredContent={true}>
            <div className={`flex justify-center w-full italic`}>
                {Translator.getTranslation(`${TRANSLATION_PREFIX}label__not_logged_in`)}
            </div>
        </PageTemplate>;
    }

    return <PageTemplate>
        <div className={classes(flex("center", "center", '10px'), width('100%'))}>
            {favoriteLocations !== undefined && favoriteLocations.data.length > 0 && favoriteLocations.data.map((locationId: string, index: number) => <div key={`shortcut-${index}`} className={classes(flex("center", "center"))}>
                <FavoriteCircle locationId={locationId}/>
            </div>)}
        </div>
    </PageTemplate>;
}

export default Favorites;