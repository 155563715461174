import {ApiManager} from "../../Utils/ApiManager";
import {
    LOCATION_TICKET_URL,
    LOCATION_TICKETS_RUNNING_URL,
    LOCATION_TICKETS_URL, PUBLIC_LOCATION_TICKET_URL,
    PUBLIC_LOCATION_TICKETS_RUNNING_URL,
    PUBLIC_LOCATION_TICKETS_URL,
    PUBLIC_TICKET_URL, PUBLIC_TICKETS_OLD_URL,
    PUBLIC_TICKETS_RUNNING_URL,
    TICKET_URL, TICKETS_OLD_URL,
    TICKETS_RUNNING_URL
} from "../../Constants/ApiUrls";
import AbstractApi from "./AbstractApi";

class TicketApi {
    static async fetchLocationTicketsRunning(locationId: string, deviceId: string, isLoggedIn: boolean) {
        try {
            const response = await ApiManager.get(isLoggedIn ? LOCATION_TICKETS_RUNNING_URL(locationId, deviceId) : PUBLIC_LOCATION_TICKETS_RUNNING_URL(locationId, deviceId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLocationTicketsRunning', {locationId, deviceId, isLoggedIn});
        }
    };

    static async callTicket(locationId: string, ticketId: string, currentCount: number) {
        try {
            const response = await ApiManager.put(LOCATION_TICKET_URL(locationId, ticketId), {
                waitingCount: currentCount+1
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'callTicket', {locationId, ticketId, currentCount});
        }
    }

    static async fetchLocationTickets(locationId: string, status: string) {
        try {
            const response = await ApiManager.get(`${LOCATION_TICKETS_URL(locationId)}?status=${status}`);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchLocationTickets', {locationId, status});
        }
    }

    static async updateTicketStatus(locationId: string, ticketId: string, status: string) {
        try {
            const response = await ApiManager.put(LOCATION_TICKET_URL(locationId, ticketId), {
                status
            });
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'updateTicketStatus', {locationId, ticketId, status});
        }
    }

    static async fetchTicketsRunning(deviceId: string, isLoggedIn: boolean) {
        try {
            const response = await ApiManager.get(isLoggedIn ? TICKETS_RUNNING_URL(deviceId) : PUBLIC_TICKETS_RUNNING_URL(deviceId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchTicketsRunning', {deviceId, isLoggedIn});
        }
    }

    static async fetchOldTickets(deviceId: string, isLoggedIn: boolean) {
        try {
            const response = await ApiManager.get(isLoggedIn ? TICKETS_OLD_URL(deviceId) : PUBLIC_TICKETS_OLD_URL(deviceId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchOldTickets', {deviceId, isLoggedIn});
        }
    }

    static async fetchTicket(ticketId: string, deviceId: string, isLoggedIn: boolean) {
        try {
            const response = await ApiManager.get(isLoggedIn ? TICKET_URL(ticketId, deviceId) : PUBLIC_TICKET_URL(ticketId, deviceId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchTicket', {ticketId, deviceId, isLoggedIn});
        }
    };

    static async createTicket(locationId: string, formData: any, peopleNumber: number, deviceId: string, isLoggedIn: boolean) {
        try {
            let params = {
                peopleNumber
            };
            if (!isLoggedIn) {
                params = {
                    ...params,
                    // @ts-ignore
                    name: formData.fields.name.value,
                };
            }

            const response = await ApiManager.post(isLoggedIn ? LOCATION_TICKETS_URL(locationId, deviceId) : PUBLIC_LOCATION_TICKETS_URL(locationId, deviceId), params);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'createTicket', {locationId, formData, deviceId, isLoggedIn});
        }
    }

    static async deleteTicket(locationId: string, ticketId: string, deviceId: string, isLoggedIn: boolean) {
        try {
            const response = await ApiManager.delete(isLoggedIn ? LOCATION_TICKET_URL(locationId, ticketId, deviceId) : PUBLIC_LOCATION_TICKET_URL(locationId, ticketId, deviceId));
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'deleteTicket', {locationId, ticketId, deviceId, isLoggedIn});
        }
    };
}

export default TicketApi