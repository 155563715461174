import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";
import Address from "./Address";
import ZipCode from "./ZipCode";
import City from "./City";

interface ZipCodeCityInterface {
    locationId: string;
}

const ZipCodeCityOneLine = ({locationId}: ZipCodeCityInterface) => {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location || location.zipCode.length === 0 || location.city.length === 0) {
        return null;
    }

    return <><ZipCode locationId={locationId}/> <City locationId={locationId}/></>;
}

export default ZipCodeCityOneLine;