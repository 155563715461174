import {useBetween} from "use-between";
import {UserAccountCache} from "../../Caches/User/UserAccountCache";
import {GetUserIdUseCase} from "./GetUserIdUseCase";
import {sendLogOutEvent} from "../../GoogleAnalytics/Events";

function LogOut() {
    const {removeUser} = UserAccountCache();
    const getUserIdUseCase = GetUserIdUseCase();

    const execute = () => {
        sendLogOutEvent(getUserIdUseCase.execute());
        removeUser();
        window.location.href = '/';
    };

    return {execute};
}

export const LogOutUseCase = () => useBetween(LogOut);