import TicketEntity from "../Entities/TicketEntity";
import {backgroundColor} from "../Theme/Theme";

export const getStyleColor = (tickets: TicketEntity[], locationId?: string, stylePrefix: string = '') => {
    let ticketsInProgressCounter: undefined|number = undefined;

    if (locationId !== undefined) {
        const ticketsInProgress = getTicketsInProgress(tickets, locationId);
        ticketsInProgress.forEach((ticket: TicketEntity) => {
            if (undefined === ticketsInProgressCounter || ticketsInProgressCounter > ticket.ticketsCountBefore) {
                ticketsInProgressCounter = ticket.ticketsCountBefore;
            }
        });
    } else {
        tickets.forEach((ticket: TicketEntity) => {
            if (undefined === ticketsInProgressCounter || ticketsInProgressCounter > ticket.ticketsCountBefore) {
                ticketsInProgressCounter = ticket.ticketsCountBefore;
            }
        });
    }

    let runningTicketColor = stylePrefix !== '' ? stylePrefix+'-': '';

    // @ts-ignore
    if (ticketsInProgressCounter <= 3) {
        return `${runningTicketColor}red-500`;
    }

    // @ts-ignore
    if (ticketsInProgressCounter <= 5) {
        return `${runningTicketColor}amber-500`;
    }

    // @ts-ignore
    if (ticketsInProgressCounter <= 10) {
        return `${runningTicketColor}lime-500`;
    }
};

export const getBackgroundColor = (ticket: TicketEntity, defaultColor: 'secondary') => {
    switch (ticket.waitingCount) {
        case 0:
            return backgroundColor(defaultColor);
        case 1:
            return 'bg-lime-100 text-black';
        case 2:
            return 'bg-amber-100 text-black';
        default:
            return 'bg-red-100 text-black';
    }
};

export const hasTicketsInProgress = (tickets: TicketEntity[], locationId?: string) => {
    if (undefined === locationId) {
        return tickets.length > 0;
    }

    return tickets.filter((ticket: TicketEntity) => locationId === ticket.locationId).length > 0;
};

export const getTicketsInProgress = (tickets: TicketEntity[], locationId: string): TicketEntity[] => {
    const runningTickets = tickets.filter((ticket: TicketEntity) => locationId === ticket.locationId);
    if (runningTickets.length > 0) {
        return runningTickets;
    }

    return [];
};

export const getTicketDescription = (ticket: TicketEntity) => {
    if (ticket.ticketType !== null && ticket.ticketType !== undefined && ticket.ticketType.prefix !== undefined) {
        return ticket.ticketType.description
    }

    return '';
};