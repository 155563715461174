import AbstractApi from "./AbstractApi";
import {ApiManager} from "../../Utils/ApiManager";
import {PUBLIC_LOCATIONS_SPECIALTIES_URL} from "../../Constants/ApiUrls";

class SpecialtiesApi {
    static async fetchSpecialties() {
        try {
            const response = await ApiManager.get(PUBLIC_LOCATIONS_SPECIALTIES_URL);
            return AbstractApi.returnSuccessResponse(response);
        } catch (e: any) {
            return AbstractApi.returnErrorResponse(e, 'fetchSpecialties');
        }
    }
}

export default SpecialtiesApi;