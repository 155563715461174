import {useEffect, useState} from "react";
import AbstractIndexedLoader, {
    IndexedLoaderObjectInterface
} from "./AbstractIndexedLoader";
import {useBetween} from "use-between";
import {IsLoggedInUseCase} from "../../../UseCases/User/IsLoggedInUseCase";
import LocationApi from "../../Calls/LocationApi";
import {APP_NAME} from "../../../../Constants/Constants";

const cache = new AbstractIndexedLoader();

export function Locations() {
    const [locations, setLocations] = useState<IndexedLoaderObjectInterface>({});
    const [isLoading, setIsLoading] = useState<boolean|undefined>(false);
    const isLoggedInUseCase = IsLoggedInUseCase();

    const apiCall = async (locationId: string) => {
        // @ts-ignore
        return LocationApi.fetchLocation(locationId, APP_NAME === 'storybook' ? false : isLoggedInUseCase.execute());
    };

    function fetch(locationIds: string[], forceRefresh: boolean= false) {
        cache.load(locationIds, apiCall, forceRefresh);
        if (!isLoading) {
            setIsLoading(() => undefined)
        }
    }

    useEffect(() => {
        if (isLoading === undefined) {
            setIsLoading(() => true);
            if (cache.hasLoading()) {
                setTimeout(() => {
                    setIsLoading(() => undefined);
                }, 10);
            } else {
                setLocations(() => ({...cache.get()}));
                setIsLoading(false);
            }
        }
    }, [isLoading]);

    const getLocationById = (locationId: string) => {
        return locations[locationId]?.data;
    }

    return {fetch, locations, getLocationById};
}

export const LocationsLoader = () => useBetween(Locations);