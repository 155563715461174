import MenuLink from "../MenuLink";
import Translator from "../../../../../Utils/Translator";
import {State} from "../../../../../../Layout/Menu/State";
import {CTA_HISTORY, CTA_TICKETS} from "../../../../../Constants/Labels";

function MenuLinkTickets() {
    const {toggle} = State();

    return <MenuLink
        link={'/tickets'}
        label={CTA_TICKETS}
        onClick={toggle}
    />
}

export default MenuLinkTickets;