import ReactGA from 'react-ga';
import InitGoogleAnalyticsUseCase from "./InitGoogleAnalyticsUseCase";
import FormatToStringGoogleAnalyticsParamsUseCase from "./FormatToStringGoogleAnalyticsParamsUseCase";
import {FEATURES} from "../../../Constants/Server";
import {APP_NAME} from "../../../Constants/Constants";
import IsGoogleAnalyticsPageViewEnabledUseCase from "./IsGoogleAnalyticsPageViewEnabledUseCase";

function SendGoogleAnalyticsPageViewUseCase() {
    const isGoogleAnalyticsPageViewEnabledUseCase = IsGoogleAnalyticsPageViewEnabledUseCase();
    const initGoogleAnalyticsUseCase = InitGoogleAnalyticsUseCase();
    const formatToStringGoogleAnalyticsParamsUseCase = FormatToStringGoogleAnalyticsParamsUseCase();

    const execute = (userId: string|undefined, pageName: string, params?: any) => {
        if (!isGoogleAnalyticsPageViewEnabledUseCase.execute()) {
            return;
        }
        initGoogleAnalyticsUseCase.execute();

        const GAData = formatToStringGoogleAnalyticsParamsUseCase.execute(userId, params);
        if (FEATURES[APP_NAME].debugGoogleAnalyticsPageViews) {
            console.warn('SendGoogleAnalyticsPageViewUseCase', GAData);
        }

        try {
            ReactGA.pageview(pageName, undefined, GAData);
        } catch (e: any) {
            console.error('Error while trying to fire Google Analytics page view:', pageName, GAData, e);
        }
    }

    return {execute};
}


export default SendGoogleAnalyticsPageViewUseCase;