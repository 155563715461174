import ReactGA from 'react-ga';
import React from 'react';
import './App.css';
import Router from "./Router";
import {LocaleContextHook, LocaleContextProvider} from "./Libraries/Contexts/LocaleContext";
import {TranslationContextHook, TranslationContextProvider} from "./Libraries/Contexts/TranslationContext";
import Translator from "./Libraries/Utils/Translator";
import {ThemeCache} from "./Libraries/Caches/System/ThemeCache";
import {syncTheme} from "./Libraries/Theme/Theme";
import {getLabel} from "./Libraries/Helpers/Label";
import {LABEL_SLOGAN} from "./Libraries/Constants/Labels";

function App() {
  const html = document.getElementsByTagName('html');
  html[0].classList.add('custom-scrollbar');

  const [locale, dispatchLocale] = LocaleContextHook();
  const [translation, dispatchTranslation] = TranslationContextHook();
  const {theme} = ThemeCache();

  syncTheme(theme);
  Translator.loadTranslation(dispatchTranslation);
  document.title = `Queube - ${getLabel(LABEL_SLOGAN)}`;

  return <>
    <div className={`border-lime-500 `}/>
    <div className={`border-amber-500`}/>
    <div className={`border-red-500`}/>
    <div className={`bg-lime-100`}/>
    <div className={`bg-amber-100`}/>
    <div className={`bg-red-100`}/>
      {/*@ts-ignore*/}
      <LocaleContextProvider value={{locale, dispatchLocale}}>
        {/*@ts-ignore*/}
        <TranslationContextProvider value={{translation, dispatchTranslation}}>
            <Router/>
        </TranslationContextProvider>
      </LocaleContextProvider>
  </>;
}

export default App;
