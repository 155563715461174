import {Link} from "react-router-dom";
import Label from "../../../Common/Label/Label";
import {ReactElement} from "react";
import Translator from "../../../../Utils/Translator";
import {CTA_LOGIN_LINK} from "../../../../Constants/Labels";
import {FRONT__COMMON__LOGIN_URL} from "../../../../Constants/FrontUrls";

interface ToRegisterInterface {
    children?: ReactElement;
}

function ToRegister({children} : ToRegisterInterface) {
    return <Link to={FRONT__COMMON__LOGIN_URL}>
        {children ?? <Label label={Translator.translate(CTA_LOGIN_LINK)}/>}
    </Link>;
}

export default ToRegister;