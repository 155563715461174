import {useBetween} from "use-between";
import {TicketsLoader} from "../../Api/Loaders/IndexedLoaders/TicketsLoader";

type FORMAT_TYPE = 'array' | 'json';
const FORMAT_JSON = 'json';
const FORMAT_ARRAY = 'array';

function GetTicketsByIds() {
    const {tickets} = TicketsLoader();

    const execute = (ticketIds: string[], format: FORMAT_TYPE = FORMAT_ARRAY) => {
        let ticketsToReturn: any = undefined;
        switch(format) {
            case FORMAT_JSON:
                ticketsToReturn = {};
                break;
            case FORMAT_ARRAY:
                ticketsToReturn = [];
                break;
        }

        for (const ticketId of ticketIds) {
            if (tickets[ticketId] === undefined || tickets[ticketId].data === undefined || !tickets[ticketId].data.isLoaded) {
                continue;
            }

            switch(format) {
                case FORMAT_JSON:
                    ticketsToReturn[ticketId] = tickets[ticketId].data;
                    break;
                case FORMAT_ARRAY:
                    ticketsToReturn.push(tickets[ticketId].data);
                    break;
            }
        }

        return ticketsToReturn;
    };

    return {execute};
}

export const GetTicketsByIdsUseCase = () => useBetween(GetTicketsByIds);