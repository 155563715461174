import {useBetween} from "use-between";
import {GetTicketsByIdsUseCase} from "./GetTicketsByIdsUseCase";
import TicketEntity from "../../Entities/TicketEntity";

function GetColor() {
    const getTicketsByIdsUseCase = GetTicketsByIdsUseCase();

    const execute = (ticketIds: string[]) => {
        const tickets = getTicketsByIdsUseCase.execute(ticketIds);
        const counters = tickets.map((ticket: TicketEntity) => ticket.ticketsCountBefore);

        const counter = Math.max(...counters);
        if (counter <= 3) {
            return `red-500`;
        }

        if (counter <= 5) {
            return `amber-500`;
        }

        return `lime-500`;

    }

    return {execute};
}

export const GetColorUseCase = () => useBetween(GetColor);