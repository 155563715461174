import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";

interface AdditionalAddressInterface {
    locationId: string;
}

function AdditionalAddress({locationId}: AdditionalAddressInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location) {
        return null;
    }

    return location.additionalAddress;
}

export default AdditionalAddress;