import {LocationsLoader} from "../../../../../Api/Loaders/IndexedLoaders/LocationsLoader";
import {useEffect} from "react";

interface ZipCodeInterface {
    locationId: string;
}

function ZipCode({locationId}: ZipCodeInterface) {
    const {fetch: fetchLocations, getLocationById} = LocationsLoader();

    useEffect(() => {
        fetchLocations([locationId]);
    }, []);

    const location = getLocationById(locationId);

    if (undefined === location) {
        return null;
    }

    return location.zipCode;
}

export default ZipCode;