import {FEATURES} from "../../../Constants/Server";
import {APP_NAME} from "../../../Constants/Constants";

function IsGoogleAnalyticsEventEnabledUseCase() {
    const execute = () => {
        return FEATURES[APP_NAME].sendGoogleAnalyticsEvents
            && FEATURES[APP_NAME].googleAnalyticsTrackingId !== undefined;
    }

    return {execute};
}

export default IsGoogleAnalyticsEventEnabledUseCase;