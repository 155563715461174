import {useState} from "react";
import {useBetween} from "use-between";

function QrCodeModalState() {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggle = () => setIsOpened(() => !isOpened);

    return {isOpened, toggle};
}

export const State = () => useBetween(QrCodeModalState);