import {ReactElement} from "react";
import {getLabel} from "../../../Helpers/Label";
import {getBoldText, getTextItalic, getTextSize, getTextUnderline} from "../../../Helpers/Text";
import {Link} from "react-router-dom";
import {getHoverTextColor, getTextColor} from "../../../Helpers/Color";

interface LabelInterface {
    label?: string|number|ReactElement|{id: string, variables: any};
    textSize?: 'small'|'medium'|'large'|'extra-large';
    boldText?: boolean;
    onClick?: () => void;
    link?: string;
    textColor?: 'success'|'error'|''|'white';
    textColorHover?: 'white'|'';
    underline?: boolean;
    italic?: boolean;
    htmlFor?: string;
    padding?: string;
    className?: string;
}

function Label({label, textSize, boldText, onClick, link, textColor, textColorHover, italic, underline, htmlFor, padding, className}: LabelInterface) {
    let component = <span onClick={onClick} className={`${getTextColor(textColor)} ${getHoverTextColor(textColorHover)} ${getTextSize(textSize)} ${getBoldText(boldText)} ${getTextItalic(italic)} ${getTextUnderline(underline)} ${padding ?? ''} ${className}`}>{getLabel(label)}</span>;

    if (htmlFor !== undefined) {
        component = <label htmlFor={htmlFor}>{component}</label>
    }

    if (link !== undefined) {
        component = <Link to={link}>{component}</Link>
    }

    return component;
}

export default Label;