import {useBetween} from "use-between";
import {TicketCallNotificationCache} from "../../Caches/Domain/Ticket/TicketCallNotificationsCache";
import {GetFirstTicketCallNotificationToShowUseCase} from "./GetFirstTicketCallNotificationToShowUseCase";
import SendGoogleAnalyticsEventUseCase from "../GoogleAnalytics/SendGoogleAnalyticsEventUseCase";
import {GetUserDataUseCase} from "../User/GetUserDataUseCase";

function CloseTicketCallNotificationUseCase() {
    const {ticketCallNotifications, save} = TicketCallNotificationCache();
    const getFirstTicketCallNotificationToShowUseCase = GetFirstTicketCallNotificationToShowUseCase();

    const execute = () => {
        const ticket = getFirstTicketCallNotificationToShowUseCase.execute();
        const newState = {...ticketCallNotifications};

        if (undefined !== ticket && undefined !== newState[ticket.id]) {
            newState[ticket.id].closed = true;
            save(newState);
        }
    };

    return {execute};
}

export default CloseTicketCallNotificationUseCase;