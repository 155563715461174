import {useEffect, useState} from "react";
import TicketEntity from "../../../../Entities/TicketEntity";
import Translator from "../../../../Utils/Translator";
import {getBackgroundColor} from "../../../../Utils/Ticket";
import {Link} from "react-router-dom";
import LabelNewLine from "../../../Common/Label/LabelNewLine";
import Button from "../../../Common/Button/Button";
import {TicketsLoader} from "../../../../Api/Loaders/IndexedLoaders/TicketsLoader";
import {GetColorUseCase} from "../../../../UseCases/Ticket/GetColorUseCase";
import {TicketsRunningLoader} from "../../../../Api/Loaders/ValueLoaders/TicketsRunningLoader";
import {DeleteTicketUseCase} from "../../../../UseCases/Ticket/DeleteTicketUseCase";
import {TicketsBefore} from "../Common/Label/TicketsBefore";
import {
    backgroundColor,
    boldText,
    border,
    classes,
    flex,
    margin,
    padding,
    round,
    textSize,
    width
} from "../../../../Theme/Theme";
import Label from "../../../Common/Label/Label";
import {
    LABEL_PEOPLE_NUMBER,
    LABEL_TICKET_ACCEPTED, LABEL_TICKET_CODE,
    LABEL_TICKET_DISABLED,
    LABEL_TICKET_REFUSED,
    LABEL_TICKET_STATUS
} from "../../../../Constants/Labels";


interface TicketCardInterface {
    ticketId: string;
    showLocationName?: boolean;
}

function OldCard({ticketId, showLocationName}: TicketCardInterface) {
    showLocationName = showLocationName ?? true;

    const {tickets, fetch: fetchTickets} = TicketsLoader();
    const ticket = tickets[ticketId]?.data;

    useEffect(() => {
        fetchTickets([ticketId]);
    }, []);

    if (ticket === undefined) {
        return null;
    }

    const getTicketDescription = (ticket: TicketEntity) => {
        if (ticket.ticketType !== null && ticket.ticketType !== undefined && ticket.ticketType.prefix !== undefined) {
            return ticket.ticketType.description;
        }

        return '';
    };

    const getStatus = () => {
        if (ticket.accepted === 1) {
            return LABEL_TICKET_ACCEPTED;
        }
        if (ticket.refused === 1) {
            return LABEL_TICKET_REFUSED;
        }
        if (ticket.isDisabled === 1) {
            return LABEL_TICKET_DISABLED;
        }

        return '';
    }
    
    return <div className={classes(margin('t', '10px'), border(), round(), backgroundColor("secondary"), width('100%'), width('300px', '', 'sm'))}>
        <div className={classes(padding('xy', '10px'), border('b'), flex('top', "between", '10px'))}>
            {showLocationName ?
                <Link to={`/locations/${ticket.locationId}`}><LabelNewLine className={classes(boldText(), textSize('20px'))} label={ticket.locationName}/></Link>
                : <div/>
            }
        </div>
        <div className={`flex justify-between items-center p-[10px]`}>
            <div>
                <LabelNewLine italic={true} label={getTicketDescription(ticket)}/>
                <div>{ticket.userName} {ticket.waitingCount > 0 && `(x${ticket.waitingCount})`}</div>
                <div><Label label={LABEL_PEOPLE_NUMBER}/>: {ticket.peopleNumber}</div>
                <div><Label label={LABEL_TICKET_CODE}/>: {ticket.code}</div>
                <div><Label label={LABEL_TICKET_STATUS}/>: <Label label={getStatus()}/></div>
            </div>
        </div>
    </div>;
}

export default OldCard;